import {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../selectors/modals/game';
import ModalsLib from '../../lib/modals';

export default () => {
    const {game} = useSelector(Selector);

    if(!game)
        return <></>;

    return (
        <div className="modal game animate__animated animate__fadeInUp animate__faster">
            <iframe src={game.links.play} />
            <div className="close" onClick={e => ModalsLib.close()}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18 18L6 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    )
}