import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.app.isMobile,
    props.app.sidebar,
    props.user.user,
    (isMobile, sidebar, user) => {
        return {
            isMobile,
            sidebar,
            user
        }
    }
)