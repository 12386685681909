import {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../selectors/layout';
import {bindActionCreators} from 'redux';
import {setMobile, initialize} from '../reducers/app/actions';
import {getUser, getTelegramUser} from '../reducers/user/actions';
import {loadGames} from '../reducers/games/actions';
import Components from './components';
import Pages from './pages';
import {Routes, Route} from 'react-router-dom';
import WS from '../lib/websocket';
import Modals from './modals';

class Layout extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <Components.Loader.Full />
                <Modals />

                {this.props.loaded && (
                    <>
                        <Components.Header />
                        <Components.MobileNav />
                        <Components.Sidebar />

                        <div className="container">
                            <Routes>
                                <Route exact path='/' element={<Pages.Home />} />
                                <Route exact path='/game' element={<Pages.Game />} />
                                <Route exact path='/game/:game_slug' element={<Pages.Game />} />
                                <Route exact path='/wheel' element={<Pages.Wheel />} />
                                <Route exact path='/vip' element={<Pages.VIP />} />
                                <Route exact path='/testyourluck' element={<Pages.TestYourLuck />} />
                                <Route exact path='/invite' element={<Pages.Invite />} />
                                <Route exact path='/freecash' element={<Pages.FreeCash />} />
                                <Route path='*' element={<Pages.NotFound />} />
                            </Routes>
                            <Components.Connection />
                        </div> 
                    </>
                )}
            </>
        )
    }

    resizeHandler() {
        const isTablet = window.document.body.offsetWidth < 1024,
              isMobile = window.document.body.offsetWidth <= 800;

        let state = 'desktop'; // Laptop / Desktop
        if(isTablet) state = 'tablet';
        if(isMobile) state = 'mobile';

        this.props.setMobile(state);
    }

    componentDidUpdate(prevProps) {
        if(!this.props.loaded) {
            if(!this.props.user) {
                if(typeof window.Telegram.WebApp.initDataUnsafe.user !== "undefined") {
                    window.Telegram.WebApp.ready();
                    window.Telegram.WebApp.expand();
                    this.props.getTelegramUser(window.Telegram.WebApp.initData);
                } else {

                    this.props.getTelegramUser('user=%7B%22id%22%3A1168949833%2C%22first_name%22%3A%22Se%C3%B1or%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22zusunit%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2Fv0G7-P56rWTmOHEG8y3PtttuEIhcceTjmKcHWAhJK80.svg%22%7D&chat_instance=1729094009561009176&chat_type=sender&auth_date=1732040277&signature=HaPvnnAzRgRACbzmhi0qSV1ewfG2qSlkhxbLFCU9z-tSbQPGwIcZ7FpftOUE-kkY9g7joG_D8eXYqF8N0gA4Cg&hash=2e3ec97393fa630e9e78f09cf04376e74f8cb54c26504e940a1098f7960b9c7a');
                    // this.props.getUser();
                }
            } else if(!this.props.games) {
                this.props.loadGames();
            }
        }
    }


    componentDidMount() {
        WS.connect();
        window.addEventListener('resize', this.resizeHandler.bind(this));
        this.resizeHandler();

        if(!this.props.initialized)
            this.props.initialize();
    }
}

export default connect(Selector, dispatch => bindActionCreators({
    getUser,
    getTelegramUser,
    loadGames,
    setMobile,
    initialize
}, dispatch)) (Layout);