import Home from './home';
import Game from './game';
import NotFound from './notFound';
import Wheel from './wheel';
import VIP from './vip';
import TestYourLuck from './testyourluck';
import Invite from './invite';
import FreeCash from './freecash';

export default {
    Home,
    Game,
    NotFound,
    Wheel,
    VIP,
    TestYourLuck,
    Invite,
    FreeCash
}