import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.wallet.mode,
    (mode) => {
        return {
            mode
        }
    }
)