import app from './app';
import user from './user';
import games from './games';
import modals from './modals';
import vip from './vip';
import wallet from './wallet';

export default {
    app,
    user,
    games,
    modals,
    vip,
    wallet
}