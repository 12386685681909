export default () => (
<svg width="248" height="171" viewBox="0 0 248 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M123.959 149.55L200.379 94.03L171.189 4.18994H76.7291L47.5391 94.03L123.959 149.55Z" fill="#257ECC"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M83.9199 14.03L124 137.38L164.08 14.03H83.9199Z" fill="#5696D2"/>
    </g>
    <path d="M173.919 0.429932H73.9991L43.1191 95.46L123.959 154.19L204.799 95.46L173.919 0.429932ZM59.1091 90.27L83.8792 14.03H164.039L188.809 90.27L123.959 137.39L59.1091 90.27Z" fill="url(#paint0_radial_999_6465)"/>
    <path d="M173.919 0.429932H73.9994L66.8594 22.4099C71.2994 22.6199 75.8994 23.1699 80.6194 24.0699L83.8794 14.03H164.039L188.809 90.27L179.409 97.0999C181.529 101.21 183.399 105.46 185.009 109.84L204.799 95.46L173.919 0.429932Z" fill="url(#paint1_radial_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M173.919 0.429932H73.9994L66.8594 22.4099C71.2994 22.6199 75.8994 23.1699 80.6194 24.0699L83.8794 14.03H164.039L188.809 90.27L179.409 97.0999C181.529 101.21 183.399 105.46 185.009 109.84L204.799 95.46L173.919 0.429932Z" fill="url(#paint2_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.439 121.88C167.959 116.59 167.179 111.55 166.109 106.76L123.959 137.39L59.1089 90.27L76.8989 35.53C71.8489 35.65 66.8689 36.22 62.0589 37.2L43.1289 95.46L123.969 154.19L168.449 121.87L168.439 121.88Z" fill="url(#paint3_radial_999_6465)"/>
    <path d="M59.1094 90.27L65.5194 88.83L87.8394 20.12H160.079L182.409 88.83L188.809 90.27L164.039 14.03H83.8794L59.1094 90.27Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.5195 88.8299L78.0096 56.7799C85.4096 37.7999 103.69 29.5699 124.05 29.5699C144.51 29.5699 162.85 37.9099 170.18 57.0099L182.4 88.8299L160.08 20.1199H87.8395L65.5195 88.8299Z" fill="#5098D6"/>
    </g>
    <path d="M59.1094 90.2701L65.5194 88.8301L123.959 131.29L182.409 88.8301L188.809 90.2701L123.959 137.38L59.1094 90.2701Z" fill="url(#paint4_linear_999_6465)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.5195 88.8299L88.4395 30.0199C89.5495 27.1799 92.2795 25.3099 95.3295 25.3099H152.93C155.99 25.3099 158.73 27.1999 159.83 30.0499L182.41 88.8299L160.09 20.1199H87.8495L65.5295 88.8299H65.5195Z" fill="#5098D6"/>
    </g>
    <path d="M83.8789 14.03L87.8389 20.12H160.079L164.039 14.03H83.8789Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M54.1992 61.3799L46.4591 90.3699C45.8591 92.8699 47.0392 96.1798 49.0992 97.7198L123.959 154.19L43.1191 95.4599L54.1891 61.3799H54.1992Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.5195 88.8301L121.28 126.38C122.76 127.38 125.18 127.38 126.65 126.38L182.41 88.8301L123.97 131.29L65.5295 88.8301H65.5195Z" fill="#5098D6"/>
    </g>
    <path d="M123.959 154.2L195.939 98.5999C200.979 94.8699 202.059 94.0599 199.799 86.9099L184.779 33.8599L204.799 95.4799L123.959 154.21V154.2Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M88.2383 111.43L123.958 137.38L172.198 102.34L123.958 139.59L88.2383 111.43Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M121.25 0.429932H173.92L184.78 33.8499L171.91 2.27997L121.25 0.429932Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M142.209 0.429932C141.769 4.75993 143.659 9.60997 147.309 14.03H164.039L168.179 26.7599C173.419 28.0399 178.439 28.09 182.539 26.96L173.919 0.429932H142.209Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M83.8991 108.27C82.6291 111.52 84.9291 116.38 89.5291 119.72C94.1291 123.06 99.4491 123.74 102.159 121.53L83.9091 108.27H83.8991Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M102.268 130.42C101.498 131.6 99.3684 131.58 97.5084 130.37C95.6484 129.16 94.7684 127.22 95.5384 126.03C96.3084 124.84 98.4384 124.87 100.298 126.08C102.158 127.29 103.038 129.23 102.268 130.42Z" fill="#FEF3BD"/>
    </g>
    <path d="M66.129 1.08997L63.069 26.3799C55.829 26.3399 49.779 26.3099 38.909 26.3199C0.408997 26.3699 0.208984 0.959961 0.208984 0.959961L66.129 1.07996V1.08997Z" fill="#1C79C9"/>
    <path d="M66.129 1.09003L59.629 20.85C52.389 20.81 51.199 20.78 40.329 20.79C1.82898 20.84 0.208984 0.969971 0.208984 0.969971L66.129 1.09003Z" fill="url(#paint5_radial_999_6465)"/>
    <path d="M56.3686 31.13L53.3586 56.43C46.1186 56.39 54.9186 56.43 44.0486 56.43C5.54858 56.48 5.30859 31.01 5.30859 31.01L56.3686 31.13Z" fill="#1C79C9"/>
    <path d="M56.3686 31.13L49.9386 50.93C42.6986 50.89 56.3686 50.86 45.4986 50.87C6.9986 50.92 5.30859 31 5.30859 31L56.3686 31.12V31.13Z" fill="url(#paint6_radial_999_6465)"/>
    <path d="M46.62 61.1598L19.5 61.0498C19.5 61.0498 23.76 80.0998 43.6 86.4698L46.62 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M46.62 61.1598L19.5 61.0498C19.5 61.0498 20.34 74.5998 40.18 80.9698L46.62 61.1598Z" fill="url(#paint7_radial_999_6465)"/>
    <path d="M66.1292 1.08984L69.3691 7.36981L63.0692 26.3898L59.6191 20.8498L66.1292 1.08984Z" fill="#3386CF"/>
    <path d="M56.3694 31.1299L59.6194 37.3399L53.3594 56.4299L49.9395 50.9299L56.3694 31.1299Z" fill="#3386CF"/>
    <path d="M46.5993 61.1599L49.8593 67.3699L40.4393 96.0699L34.5293 97.8799L46.5993 61.1599Z" fill="#3386CF"/>
    <path d="M181.809 1.08997L184.869 26.3799C192.109 26.3399 198.159 26.3099 209.029 26.3199C247.529 26.3699 247.729 0.959961 247.729 0.959961L181.809 1.07996V1.08997Z" fill="#1C79C9"/>
    <path d="M181.809 1.09003L188.309 20.85C195.549 20.81 196.739 20.78 207.609 20.79C246.109 20.84 247.729 0.969971 247.729 0.969971L181.809 1.09003Z" fill="url(#paint8_radial_999_6465)"/>
    <path d="M191.568 31.13L194.578 56.43C201.818 56.39 193.018 56.43 203.888 56.43C242.388 56.48 242.628 31.01 242.628 31.01L191.568 31.13Z" fill="#1C79C9"/>
    <path d="M191.568 31.13L197.998 50.93C205.238 50.89 191.568 50.86 202.438 50.87C240.938 50.92 242.628 31 242.628 31L191.568 31.12V31.13Z" fill="url(#paint9_radial_999_6465)"/>
    <path d="M201.318 61.1598L228.438 61.0498C228.438 61.0498 224.178 80.0998 204.338 86.4698L201.318 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M201.318 61.1598L228.438 61.0498C228.438 61.0498 227.598 74.5998 207.758 80.9698L201.318 61.1598Z" fill="url(#paint10_radial_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M181.809 1.09003L188.309 20.85C195.549 20.81 196.739 20.78 207.609 20.79C246.109 20.84 247.729 0.969971 247.729 0.969971L181.809 1.09003Z" fill="url(#paint11_radial_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M191.568 31.13L197.998 50.93C205.238 50.89 191.568 50.86 202.438 50.87C240.938 50.92 242.628 31 242.628 31L191.568 31.12V31.13Z" fill="url(#paint12_radial_999_6465)"/>
    <path d="M181.808 1.08984L178.568 7.36981L184.868 26.3898L188.318 20.8498L181.808 1.08984Z" fill="#3386CF"/>
    <path d="M191.568 31.1299L188.318 37.3399L194.578 56.4299L197.998 50.9299L191.568 31.1299Z" fill="#3386CF"/>
    <path d="M201.34 61.1599L198.08 67.3699L207.54 96.1899L213.39 97.8799L201.34 61.1599Z" fill="#3386CF"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M66.129 1.09003L0.208984 0.969971C0.208984 0.969971 1.82898 20.84 40.329 20.79C51.199 20.78 52.389 20.81 59.629 20.85L66.129 1.09003Z" fill="url(#paint13_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M49.9385 50.93L53.7285 39.25C48.5685 40.94 43.6685 43.16 39.1685 45.91C37.3085 47.05 35.1185 48.46 32.7285 50.03C36.4585 50.57 40.6885 50.87 45.4885 50.87C56.3585 50.86 42.6885 50.89 49.9285 50.93H49.9385Z" fill="url(#paint14_radial_999_6465)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M56.0195 55.78L72.8995 7.36993C74.6395 2.44993 75.9095 1.15991 81.1295 0.96991L123.97 0.419922H74.0096L56.0295 55.77L56.0195 55.78Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M139.058 7.98979C135.568 5.44979 131.358 5.28981 129.658 7.62981C128.438 9.30981 128.848 11.8098 130.458 14.0298H142.978C142.778 11.9798 141.368 9.67979 139.048 7.98979H139.058Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M196.738 19.3499C192.348 18.9099 189.288 17.3199 187.288 13.3899L181.818 1.09985L188.318 20.8599C195.558 20.8199 196.748 20.7899 207.618 20.7999C213.748 20.7999 218.948 20.3099 223.348 19.4599C213.808 20.6099 204.008 20.0699 196.738 19.3499Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M202.45 50.8799C211.76 50.8899 218.92 49.7298 224.42 47.9698C216.51 50.1698 209.86 50.0099 204.81 49.7099C201.33 49.4999 198.5 46.9399 197.03 43.7799L191.58 31.1299L198.01 50.9299C205.25 50.8899 191.58 50.8599 202.45 50.8699V50.8799Z" fill="#4C95D5"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M207.77 80.96C212.07 79.58 215.47 77.86 218.17 76.02C214.98 77.24 208.98 78.1099 208.98 78.1099L201.34 61.1599V61.1799L207.77 80.96Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.3192 20.8C51.1892 20.79 52.3792 20.82 59.6192 20.86L64.7692 5.20001L60.9392 13.75C59.0992 18.25 58.0092 19.74 53.5192 19.78C48.6692 19.82 39.5392 19.72 34.8992 19.54C14.6892 18.75 2.77922 10.01 0.199219 0.98999C0.199219 0.98999 1.81921 20.86 40.3192 20.81V20.8Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M50.7986 45.0898C49.5786 47.6498 48.2587 49.7998 45.4187 49.6998C42.4887 49.5998 34.2387 49.5398 29.5287 48.4798C18.5987 46.0098 12.0687 41.9398 7.63867 36.9498C11.3587 42.8098 20.9387 50.9098 45.4987 50.8798C56.3687 50.8698 42.6987 50.8998 49.9387 50.9398L55.2587 34.5498L50.7887 45.0998L50.7986 45.0898Z" fill="#4C95D5"/>
    </g>
    <path d="M40.1683 80.9598L45.7883 63.6898C44.7383 65.8898 42.6483 70.9898 40.5183 75.7098C39.0783 78.9198 37.6883 78.5198 35.4583 77.5898C24.3783 72.9798 21.8283 67.4898 19.4883 61.0498C19.4883 61.0498 20.3283 74.5998 40.1683 80.9698V80.9598Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M181.809 1.09003L242.089 2.12C244.509 2.16 245.639 3.97997 244.589 6.15997C243.499 8.43997 241.729 11.16 238.839 13.51C247.159 7.85001 247.719 0.969971 247.719 0.969971L181.799 1.09003H181.809Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M191.568 31.13L236.038 32.18C241.238 32.29 240.388 34.5 239.038 37.2C238.208 38.87 237.058 40.69 235.548 42.21C242.128 36.86 242.628 31.02 242.628 31.02L191.568 31.14V31.13Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.7285 0.969971L66.1285 1.09003L64.4985 6.03998C64.4985 3.50998 63.2985 2.22 60.3785 2.19L17.7285 0.969971Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.7285 31.1299H56.3685L54.5885 36.6099L54.7785 34.7899C54.8785 33.0699 54.2685 32.1699 51.1385 32.0199L17.7285 31.1299Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M136.09 48.3699L142.15 37.8699V32.8499L130.03 37.8699L123.96 27.3699L117.9 37.8699L105.77 32.8499V37.8699L111.84 48.3699L105.77 53.8499V58.8799H117.9L123.96 69.3799L130.03 58.8799H142.15V53.8499L136.09 48.3699Z" fill="#5098D6"/>
    </g>
    <path d="M136.09 43.3498L142.15 32.8498V28.9198L130.03 32.8498L123.96 22.3398L117.9 32.8498L105.77 28.9198V32.8498L111.84 43.3498L105.77 49.9198V53.8498H117.9L123.96 64.3498L130.03 53.8498H142.15V49.9198L136.09 43.3498Z" fill="#3386CF"/>
    <path d="M136.09 39.4199L142.15 28.9199H130.03L123.96 18.4199L117.9 28.9199H105.77L111.84 39.4199L105.77 49.9199H117.9L123.96 60.4299L130.03 49.9199H142.15L136.09 39.4199Z" fill="url(#paint15_radial_999_6465)"/>
    <path d="M142.159 49.9199H130.029V53.8499H142.159V49.9199Z" fill="#1C79C9"/>
    <path d="M117.9 53.8499L123.96 64.3499V60.4299L117.9 49.9199H105.77V53.8499H117.9Z" fill="#1C79C9"/>
    <path style={{mixBlendMode: 'screen'}} d="M123.96 60.4298L129.49 50.8498C123.79 46.5898 117.65 43.0698 111.31 40.3398L105.77 49.9298H117.9L123.96 60.4298Z" fill="url(#paint16_radial_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M130.029 28.9199L123.969 18.4199L117.909 28.9199H108.209C119.759 33.5799 131.019 40.1 141.169 48.21L136.099 39.4199L142.159 28.9199H130.029Z" fill="url(#paint17_radial_999_6465)"/>
    <path d="M105.77 28.9199L111.84 39.4199L110.7 41.3799L105.77 32.8499V28.9199Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M123.959 18.4199L129.809 29.95L142.149 28.9199H130.029L123.959 18.4199Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M105.77 28.9199L114.06 39.4199L105.77 49.9199L111.84 39.4199L105.77 28.9199Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M123.959 60.43L129.249 47.49L142.149 49.92H130.029L123.959 60.43Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M105.77 49.92L119.04 48.78L123.96 60.43L117.9 49.92H105.77Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M131.208 34.9999C130.598 36.9299 127.708 37.7399 124.758 36.8099C121.798 35.8799 119.898 33.5599 120.508 31.6199C121.118 29.6899 124.008 28.8799 126.958 29.8099C129.918 30.7399 131.818 33.0599 131.208 34.9999Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M131.629 44.1899C131.329 45.1599 129.879 45.5599 128.399 45.0999C126.919 44.6299 125.969 43.4699 126.279 42.5099C126.579 41.5399 128.029 41.1399 129.509 41.5999C130.989 42.0699 131.939 43.2299 131.629 44.1899Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M188.318 20.85C193.558 20.82 195.628 20.8 200.498 20.79C207.318 16.51 211.868 10.16 212.508 2.76001C212.558 2.18001 212.578 1.61003 212.578 1.03003L181.818 1.09003L188.318 20.85Z" fill="#FEF3BD"/>
    </g>
    <path d="M220.939 73.8798C228.019 67.6998 228.439 61.0498 228.439 61.0498L201.319 61.1598L213.389 97.8798L127.029 160.63V170.61L236.779 109.51L220.939 73.8798Z" fill="url(#paint18_radial_999_6465)"/>
    <path d="M34.5287 97.8798L46.6086 61.1598L19.4886 61.0498C19.4886 61.0498 19.9087 67.6998 26.9787 73.8698L11.1387 109.51L120.889 170.61V160.63L34.5287 97.8798Z" fill="url(#paint19_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M34.5287 97.8798L46.6086 61.1598L19.4886 61.0498C19.4886 61.0498 19.9087 67.6998 26.9787 73.8698L11.1387 109.51L120.889 170.61V160.63L34.5287 97.8798Z" fill="url(#paint20_radial_999_6465)"/>
    <path d="M40.4393 96.0698L120.889 157.26V160.62L34.5293 97.8798L40.4393 96.0698Z" fill="url(#paint21_linear_999_6465)"/>
    <path d="M207.539 96.1899L127.029 157.26V160.62L213.389 97.8799L207.539 96.1899Z" fill="url(#paint22_linear_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M220.94 73.8798C228.02 67.6998 228.44 61.0498 228.44 61.0498L201.32 61.1598L213.39 97.8798L187.68 116.56C187.95 117.59 188.21 118.62 188.45 119.66C189.84 125.16 191.03 130.07 192.04 134.41L236.78 109.5L220.94 73.8698V73.8798Z" fill="url(#paint23_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.839 139.12C169.079 136.03 169.159 133 169.139 130.03L127.029 160.62V170.6L166.979 148.36C167.939 145.53 168.589 142.46 168.839 139.12Z" fill="url(#paint24_radial_999_6465)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M26.9884 73.8701L11.1484 109.51L68.1685 141.26C68.1685 141.26 29.2285 118.01 16.6985 110.07C14.4785 108.66 13.7085 107.45 14.7585 105.04C18.4885 96.47 26.8984 76.16 28.4884 73.1C23.4284 69.19 21.3484 65.51 19.4984 61.04C19.4984 61.04 19.9184 67.69 26.9884 73.86V73.8701Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M24.7092 61.0601L46.6092 61.1501L38.1192 87.1401L44.8392 63.8901C44.7792 62.5701 44.4192 62.1101 42.6092 61.9901L24.6992 61.0601H24.7092Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M223.79 71.0001C228.16 65.8001 228.45 61.04 228.45 61.04L201.37 61.1501H201.34L223.73 62.1C227.04 62.3 226.44 63.5501 226.05 65.1501C225.61 67.0001 224.87 69.1801 223.79 70.9901V71.0001Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M183.77 139.02L225.55 113.76C233.01 109.27 233.68 108.48 230.47 100.3L223.45 79.52L236.78 109.51L183.77 139.02Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M127.029 160.62C127.029 160.62 127.299 162.02 127.479 163.1C128.299 167.88 129.579 167.64 132.369 166.31L148.089 158.88L127.029 170.6V160.62Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.6602 148.76L115.95 166.12C119.45 168.04 119.88 167.05 120.58 162.98C120.66 162.52 120.9 160.9 120.9 160.9V170.61L81.6602 148.76Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M123.959 113.14L76.2793 88.8499V93.8599L123.959 128.5L171.639 93.8599V88.8499L123.959 113.14Z" fill="#5098D6"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M123.958 86.71L83.2285 67.47V72.48L123.958 102.07L164.689 72.48V67.47L123.958 86.71Z" fill="#5098D6"/>
    </g>
    <path d="M123.959 108.12L76.2793 82.8301V88.85L123.959 123.49L171.639 88.85V82.8301L123.959 108.12Z" fill="#3386CF"/>
    <path d="M123.958 81.7L83.2285 61.45V67.4699L123.958 97.0599L164.689 67.4699V61.45L123.958 81.7Z" fill="#3386CF"/>
    <path d="M123.959 102.11L80.2993 70.47L76.2793 82.83L123.959 117.47L171.639 82.83L167.619 70.47L123.959 102.11Z" fill="url(#paint25_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M159.899 91.36C158.159 87.52 156.169 83.84 153.979 80.36L123.969 102.11L80.3091 70.47L76.2891 82.83L123.969 117.47L159.909 91.36H159.899Z" fill="url(#paint26_radial_999_6465)"/>
    <path d="M123.958 75.6801L87.2485 49.0801L83.2285 61.4501L123.958 91.04L164.689 61.4501L160.669 49.0801L123.958 75.6801Z" fill="url(#paint27_radial_999_6465)"/>
    <path style={{mixBlendMode: 'screen'}} d="M148.828 72.98C146.268 69.66 143.508 66.5501 140.578 63.6401L123.958 75.6801L87.2485 49.0801L83.2285 61.4501L123.958 91.04L148.819 72.98H148.828Z" fill="url(#paint28_radial_999_6465)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M149.459 57.2001C152.569 60.0001 155.549 62.9401 158.399 66.0201L164.689 61.4501L160.669 49.0801L149.459 57.2101V57.2001Z" fill="url(#paint29_radial_999_6465)"/>
    <path d="M123.958 91.0399V97.0599L83.2285 67.4699V61.45L123.958 91.0399Z" fill="#1C79C9"/>
    <path d="M76.2793 82.8301L123.959 117.47V123.49L76.2793 88.85V82.8301Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M97.6797 56.6299L123.96 78.71L156.02 52.45L123.96 75.6799L97.6797 56.6299Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M97.6797 83.0599L123.96 105.14L156.02 78.8799L123.96 102.11L97.6797 83.0599Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M87.3188 49.0801L86.0287 57.2401C85.8587 58.9501 86.1987 61.0301 87.5287 62.1201L123.949 91.05L83.2188 61.4601L87.3087 49.0901L87.3188 49.0801Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M142.389 77.6499L160.309 62.1699C161.979 60.7399 162.599 59.1699 162.389 56.9899L161.409 51.3499L164.689 61.4999L142.389 77.6499Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M80.2993 70.47L79.0893 78.63C78.9193 80.34 79.2593 82.42 80.5893 83.51L117.009 112.44L76.2793 82.85L80.2993 70.48V70.47Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M149.459 98.95L167.259 83.4799C168.929 82.0499 169.549 80.4799 169.339 78.2999L168.359 72.6599L171.639 82.8099L149.459 98.96V98.95Z" fill="#4C95D5"/>
    </g>
    <path style={{mixBlendMode: 'soft-light'}} d="M5.30859 31.01C5.30859 31.01 5.58861 34.23 8.41861 38.08C12.6186 35.7 17.3086 33.27 22.2986 31.05L5.30859 31.01Z" fill="url(#paint30_radial_999_6465)"/>
    <path d="M150.109 119.5C150.109 119.5 153.629 112.94 159.099 109.17C162.369 106.91 168.679 105.14 168.679 105.14C168.679 105.14 162.799 112.53 158.459 115.99C154.119 119.45 150.119 119.49 150.119 119.49L150.109 119.5Z" fill="url(#paint31_linear_999_6465)"/>
    <path d="M164.88 107.61C160.05 107.89 156.18 111.75 154.08 114.47C151.98 117.18 153.17 118.09 155.83 116.91C158.48 115.73 166.92 107.5 164.88 107.62V107.61Z" fill="#3A8AD0"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M164.879 107.61C161.609 107.58 157.499 110.67 156.219 112.05C154.929 113.43 157.499 115.55 159.249 114.43C160.999 113.31 166.369 107.63 164.879 107.61Z" fill="url(#paint32_linear_999_6465)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35993) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35993) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.529 -2.99007) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5989 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_6465" x1="123.959" y1="-579" x2="123.959" y2="-579" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35998) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.36002) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.3 3.35986) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35998) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint9_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.298 3.36002) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint10_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.298 3.35986) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.529 -2.99003) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.528 -2.98999) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.529 -2.99002) scale(149.15 149.15)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5985 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.3 3.35991) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5995 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.529 -2.9901) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint18_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35982) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint19_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35982) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint20_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5987 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <linearGradient id="paint21_linear_999_6465" x1="34.5293" y1="128.34" x2="120.889" y2="128.34" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <linearGradient id="paint22_linear_999_6465" x1="213.389" y1="128.4" x2="127.029" y2="128.4" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint23_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.53 -2.99018) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint24_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5993 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint25_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.35996) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint26_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5991 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint27_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(167.299 3.36011) scale(196.23 196.23)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint28_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.5985 154.6) scale(129.5 129.5)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint29_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.529 -2.98993) scale(149.14 149.14)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint30_radial_999_6465" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(171.528 -2.99003) scale(149.15 149.15)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <linearGradient id="paint31_linear_999_6465" x1="152.669" y1="116.19" x2="165.459" y2="108.83" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D8E8F6"/>
            <stop offset="0.99" stop-color="#72ACDE"/>
        </linearGradient>
        <linearGradient id="paint32_linear_999_6465" x1="161.162" y1="114.134" x2="160.206" y2="108.118" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#559AD7"/>
            <stop offset="1" stop-color="#2C82CD"/>
        </linearGradient>
    </defs>
</svg>
)