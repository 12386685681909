export default {
    modal: ({modals}) => modals.modal,
    data: {
        wallet: ({modals}) => modals.data.wallet,
        addcash: ({modals}) => modals.data.addcash,
        cashout: ({modals}) => modals.data.cashout,
        login_ready: ({modals}) => modals.data.login_ready,
        login_remove: ({modals}) => modals.data.login_remove,
        login_reset: ({modals}) => modals.data.login_reset,
        login_reset_done: ({modals}) => modals.data.login_reset_done,
        cashout_failed: ({modals}) => modals.data.cashout_failed,
        deposit_failed: ({modals}) => modals.data.deposit_failed,
        deposit_success: ({modals}) => modals.data.deposit_success,
        game: ({modals}) => modals.data.game,
        download: ({modals}) => modals.data.download
    }
}