import Bitcoin from './bitcoin';
import CashApp from './cashapp';
import Ethereum from './ethereum';
import Litecoin from './litecoin';
import MasterCard from './mastercard';
import Ton from './ton';
import USDC from './usdc';
import USDT from './usdt';
import Visa from './visa';

export default {
    Bitcoin,
    CashApp,
    Ethereum,
    Litecoin,
    MasterCard,
    Ton,
    USDC,
    USDT,
    Visa
}