import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals/login_reset';
import {resetPassword, updateBalance} from '../../reducers/games/actions';
import {toast} from 'react-toastify';
import BlueButton from '../components/blue-button';
import Numeric from '../components/numeric';
import ModalsLib from '../../lib/modals';

export default () => {
    const {game, login, buttons} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Reset password</div>
                    <div className="descriptions">Recover your password with a single button!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
            <div className="game">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                    <div className="details">
                        <div className="top">
                            <div className="name">{game.name}</div>
                        </div>
                        <div className="bottom">
                            <div className="detail">
                                <div className="title">Login</div>
                                <div className="value">{login.login}</div>
                            </div>

                            <div className="balance">
                                <div className="detail">
                                    <div className="title">Balance</div>
                                    <div className="value">
                                        <Numeric value={login.balance} />
                                    </div>
                                </div>

                                <svg className={`${login.balance_details.need_update ? 'updating' : 'noupdating'}`} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={e => {
                                    if(buttons.update)
                                        return;

                                    if(login.balance_details.need_update) {
                                        toast.dismiss();
                                        toast.info(`Your balance is already being updated...`);
                                        return;
                                    }

                                    dispatch(updateBalance(login.game));
                                }}>
                                    <path d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 2.59199 13.7 2.59199 13.7M2.59199 13.7H6.35866M2.59199 13.7V17.8666M1.66699 9.99996C1.66699 5.39996 5.36699 1.66663 10.0003 1.66663C15.5587 1.66663 18.3337 6.29996 18.3337 6.29996M18.3337 6.29996V2.13329M18.3337 6.29996H14.6337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Messages */}
                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>
                        We do not recommend changing your account password, but you can always restore it!
                    </span>
                </div>
            </div>

            <div className="modal-footer">
                <BlueButton active={buttons.reset} onClick={e => {
                    if(buttons.reset)
                        return;

                    dispatch(resetPassword(login.game));
                }}>
                    Reset
                </BlueButton>
                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}