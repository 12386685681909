import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.wallet.methods.cashapp_deposit,
    (cashapp_deposit) => {
        return {
            props: cashapp_deposit[cashapp_deposit.stage]
        }
    }
)