import types from './types';
import globalTypes from '../types';
import API from '../../lib/api';
import {toast} from 'react-toastify';
import ModalsLib from '../../lib/modals';

export const loadGames = () => async dispatch => {
    const response = await API.call({
        path: '/games/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: {
                games: response.games,
                sessions: response.sessions
            }
        });
}

export const getDownloadCode = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'requestDownloadCode'
    });

    const response = await API.call({
        path: '/games/downloadCode',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'requestDownloadCode'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    } else {
        dispatch({
            type: globalTypes.modals.update,
            payload: {
                slug: 'download',
                data: {
                    code: null
                }
            }
        })
    }
}

export const loadGame = slug => async dispatch => {
    const response = await API.call({
        path: '/games/load',
        body: {
            slug
        }
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    if(!response.game.notfound)
        dispatch({
            type: globalTypes.user.game,
            payload: slug
        });

    dispatch({
        type: types.game,
        payload: {
            game: response.game,
            history: response.history
        }
    });
}

export const clearGame = () => async dispatch => {
    dispatch({
        type: types.game,
        payload: {
            game: null,
            history: {
                count: 0,
                list: []
            }
        }
    });
}

export const requestLogin = game => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'requestLogin'
    });

    const response = await API.call({
        path: '/games/requestLogin',
        body: {
            game
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'requestLogin'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    dispatch({
        type: globalTypes.user.logins.create,
        payload: response.login
    });
}

export const removeLogin = game => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'removeLogin'
    });

    const response = await API.call({
        path: '/games/removeLogin',
        body: {
            game
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'removeLogin'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    ModalsLib.close();

    setTimeout(() => {
        toast.success(`Your login has been successfully deleted!`);

        dispatch({
            type: globalTypes.user.logins.remove,
            payload: game
        });
    }, 500);
}

export const updateBalance = game => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'updateBalance'
    });

    const response = await API.call({
        path: '/games/updateBalance',
        body: {
            game
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'updateBalance'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    dispatch({
        type: globalTypes.user.logins.update,
        payload: response.login
    });
}

export const resetPassword = game => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'resetPassword'
    });

    const response = await API.call({
        path: '/games/resetPassword',
        body: {
            game
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'resetPassword'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }

    dispatch({
        type: globalTypes.user.logins.update,
        payload: response.login
    });

    ModalsLib.close();
    toast.dismiss();
    toast.info(response.message);
}

export const addCash = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'addCash'
    });

    const response = await API.call({
        path: '/games/addcash',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'addCash'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }
    
    ModalsLib.close();
    toast.info(response.message);

    dispatch({
        type: globalTypes.user.balance,
        payload: response.balance
    });

    dispatch({
        type: types.history.add,
        payload: response.transaction
    });
}

export const cashOut = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'cashOut'
    });

    const response = await API.call({
        path: '/games/cashout',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'cashOut'
    });

    if(!response.success) {
        toast.dismiss();
        toast.error(response.error);
        return;
    }
    
    ModalsLib.close();
    toast.info(response.message);

    dispatch({
        type: types.history.add,
        payload: response.transaction
    });
}