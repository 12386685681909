export default () => (
    <svg width="162" height="154" viewBox="0 0 162 154" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80.8606 148.81L157.091 93.4303L127.971 3.82031H33.7506L4.64062 93.4303L80.8606 148.81Z" fill="#7D7B8C"/>
        <path d="M130.701 0.0703125H31.0305L0.230469 94.8603L80.8605 153.44L161.49 94.8603L130.701 0.0703125ZM16.1805 89.6803L40.8905 13.6403H120.85L145.56 89.6803L80.8805 136.68L16.1905 89.6803H16.1805Z" fill="url(#paint0_radial_999_5822)"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
            <path d="M40.8906 13.6401L80.8606 136.68L120.841 13.6401H40.8906Z" fill="#817F90"/>
        </g>
        <path d="M130.7 0.0703125H31.0302L23.9102 22.0003C28.3402 22.2003 32.9302 22.7603 37.6302 23.6603L40.8902 13.6403H120.85L145.56 89.6803L136.18 96.5003C138.29 100.6 140.16 104.84 141.76 109.21L161.5 94.8703L130.7 0.0703125Z" fill="url(#paint1_radial_999_5822)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M130.7 0.0703125H31.0302L23.9102 22.0003C28.3402 22.2003 32.9302 22.7603 37.6302 23.6603L40.8902 13.6403H120.85L145.56 89.6803L136.18 96.5003C138.29 100.6 140.16 104.84 141.76 109.21L161.5 94.8703L130.7 0.0703125Z" fill="url(#paint2_radial_999_5822)"/>
        <path style={{mixBlendMode: 'screen'}} d="M125.231 121.21C124.761 115.93 123.971 110.9 122.911 106.13L80.8607 136.68L16.1707 89.6801L33.9107 35.0801C28.8707 35.2001 23.9107 35.7701 19.1007 36.7501L0.220703 94.8601L80.8507 153.44L125.221 121.21H125.231Z" fill="url(#paint3_radial_999_5822)"/>
        <path d="M16.1816 89.6801L22.5717 88.2501L44.8417 19.7201H116.892L139.162 88.2501L145.552 89.6801L120.842 13.6401H40.8817L16.1816 89.6801Z" fill="#858393"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
            <path d="M22.5706 88.2502L35.0306 56.2802C42.4106 37.3502 60.6406 29.1402 80.9606 29.1402C101.361 29.1402 119.661 37.4602 126.971 56.5102L139.161 88.2502L116.891 19.7202H44.8306L22.5605 88.2502H22.5706Z" fill="#908E9D"/>
        </g>
        <path d="M16.1816 89.6805L22.5717 88.2505L80.8616 130.6L139.162 88.2505L145.552 89.6805L80.8616 136.68L16.1816 89.6805Z" fill="url(#paint4_linear_999_5822)"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M22.5708 88.2502L45.4308 29.5902C46.5308 26.7602 49.2608 24.8902 52.3008 24.8902H109.751C112.801 24.8902 115.541 26.7702 116.631 29.6202L139.151 88.2502L116.881 19.7202H44.8208L22.5508 88.2502H22.5708Z" fill="#908E9D"/>
        </g>
        <path d="M40.8809 13.6401L44.8409 19.7201H116.891L120.841 13.6401H40.8809Z" fill="#777687"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M11.2803 60.8701L3.56024 89.7901C2.96024 92.2801 4.14026 95.5901 6.20026 97.1301L80.8702 153.45L0.240234 94.8701L11.2902 60.8801L11.2803 60.8701Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M22.5703 88.2505L78.1803 125.71C79.6503 126.7 82.0703 126.7 83.5403 125.71L139.15 88.2505L80.8603 130.6L22.5703 88.2505Z" fill="#908E9D"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M80.8613 153.45L152.661 97.9902C157.681 94.2702 158.771 93.4602 156.511 86.3302L141.531 33.4102L161.501 94.8702L80.8713 153.45H80.8613Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M13.0898 55.2803L29.9298 6.99031C31.6698 2.09031 32.9398 0.800313 38.1298 0.610313L80.8598 0.0703125H31.0298L13.0898 55.2803Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M45.2305 110.79L80.8605 136.68L128.98 101.72L80.8605 138.88L45.2305 110.79Z" fill="#DEDEE2"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M78.1602 0.0703125H130.7L141.53 33.4103L128.69 1.92031L78.1602 0.0703125Z" fill="#DEDEE2"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M99.0706 0.0703125C98.6306 4.39031 100.521 9.22031 104.151 13.6303H120.841L124.971 26.3303C130.201 27.6103 135.201 27.6503 139.301 26.5303L130.701 0.0703125H99.0706Z" fill="#FFF2BB"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M95.0615 7.62051C91.5815 5.09051 87.3815 4.93051 85.6815 7.26051C84.4715 8.93051 84.8715 11.4305 86.4815 13.6405H98.9714C98.7814 11.6005 97.3715 9.30051 95.0515 7.62051H95.0615Z" fill="#FFF2BB"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M40.9003 107.64C39.6403 110.89 41.9203 115.73 46.5103 119.06C51.1003 122.39 56.4102 123.07 59.1002 120.87L40.8903 107.64H40.9003Z" fill="#FFF2BB"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M81.1714 112.73L32.1914 87.6104V91.8604L81.1714 127.45L130.151 91.8604V87.6104L81.1714 112.73Z" fill="#908E9D"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M81.1707 85.9602L39.2207 65.9502V69.5902L81.1707 100.07L123.121 69.5902V65.9502L81.1707 85.9602Z" fill="#908E9D"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M46.4512 43.7202V47.9702L81.1711 73.2002L115.891 47.9702V43.7202H46.4512Z" fill="#908E9D"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M59.2311 129.73C58.4611 130.91 56.3311 130.89 54.4811 129.68C52.6211 128.47 51.7411 126.53 52.5111 125.35C53.2811 124.17 55.4111 124.19 57.2611 125.4C59.1211 126.61 60.0011 128.55 59.2311 129.73Z" fill="#FFF2BB"/>
        </g>
        <path d="M81.1714 107.88L32.1914 80.9302V87.6102L81.1714 123.2L130.151 87.6102V80.9302L81.1714 107.88Z" fill="#858393"/>
        <path d="M81.1707 81.1103L39.2207 59.2803V65.9503L81.1707 96.4303L123.121 65.9503V59.2803L81.1707 81.1103Z" fill="#858393"/>
        <path d="M46.4512 37.0405V43.7205L81.1711 68.9505L115.891 43.7205V37.0405H46.4512Z" fill="#858393"/>
        <path d="M81.1714 101.2L36.1914 68.6001L32.1914 80.9301L81.1714 116.52L130.151 80.9301L126.151 68.6001L81.1714 101.2Z" fill="url(#paint5_radial_999_5822)"/>
        <path d="M81.1707 74.4302L43.2307 46.9302L39.2207 59.2802L81.1707 89.7502L123.121 59.2802L119.111 46.9302L81.1707 74.4302Z" fill="url(#paint6_radial_999_5822)"/>
        <path d="M50.9012 23.3203L46.4512 37.0403L81.1711 62.2703L115.891 37.0403L111.431 23.3203H50.9012Z" fill="url(#paint7_radial_999_5822)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M49.8711 26.5201C65.7411 31.3101 82.1911 39.5801 96.9911 50.7901L115.901 37.0501L111.441 23.3301H50.9111L49.8711 26.5201Z" fill="url(#paint8_radial_999_5822)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M104.91 57.2204C108.07 59.9604 111.12 62.8404 114.05 65.8604L123.11 59.2804L119.1 46.9404L104.91 57.2304V57.2204Z" fill="url(#paint9_radial_999_5822)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M120.352 72.8001C123.012 75.9401 125.522 79.2001 127.882 82.5801L130.162 80.9301L126.152 68.6001L120.352 72.8001Z" fill="url(#paint10_radial_999_5822)"/>
        <path style={{mixBlendMode: 'screen'}} d="M91.9612 54.4305C78.6612 43.6905 62.8012 37.4905 46.9312 35.5605L46.4512 37.0405L81.1711 62.2705L91.9612 54.4305Z" fill="url(#paint11_radial_999_5822)"/>
        <path style={{mixBlendMode: 'screen'}} d="M107.401 70.7004C104.931 67.2904 102.251 64.1304 99.4107 61.2104L81.1707 74.4304L43.2307 46.9404L39.2207 59.2804L81.1707 89.7604L107.401 70.7004Z" fill="url(#paint12_radial_999_5822)"/>
        <path style={{mixBlendMode: 'screen'}} d="M117.922 89.8201C116.312 85.8301 114.482 82.0701 112.452 78.5301L81.1716 101.2L36.1917 68.6001L32.1816 80.9301L81.1616 116.52L117.922 89.8201Z" fill="url(#paint13_radial_999_5822)"/>
        <path d="M81.1711 62.2705V68.9505L46.4512 43.7205V37.0405L81.1711 62.2705Z" fill="#777687"/>
        <path d="M39.2207 59.2803L81.1707 89.7503V96.4303L39.2207 65.9503V59.2803Z" fill="#777687"/>
        <path d="M32.1914 80.9302L81.1714 116.52V123.2L32.1914 87.6102V80.9302Z" fill="#777687"/>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M51.4805 79.6802L80.8605 104.14L107.99 81.7602L81.1705 101.2L51.4805 79.6802Z" fill="#DEDEE2"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M51.4805 52.9102L80.8605 76.7602L103.44 58.2902L81.1705 74.4302L51.4805 52.9102Z" fill="#DEDEE2"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M81.9199 23.3203L107.42 24.3603C109.46 24.4403 110.24 25.2503 111.32 27.3503L115.9 37.0503L111.44 23.3303H81.9199V23.3203Z" fill="#DEDEE2"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M43.2307 46.9302L41.4907 56.7302C41.3507 57.7602 41.5807 58.9302 42.3907 59.5802L75.6807 85.7602L39.2207 59.2702L43.2307 46.9302Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M36.1914 68.6001L34.5014 77.6901C34.1714 79.1601 34.5514 80.4401 35.4314 81.1701L66.1914 105.63L32.1914 80.9301L36.2014 68.6001H36.1914Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M108.941 69.5704L119.421 60.0304C120.541 58.8904 120.721 57.0604 120.541 55.4704L119.111 46.9404L123.121 59.2804L108.951 69.5804L108.941 69.5704Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M106.811 97.9004L126.071 81.6504C127.271 80.5704 127.831 79.4504 127.511 77.0404L126.151 68.6104L130.161 80.9404L106.811 97.9004Z" fill="#9492A0"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M50.9014 23.3203L49.4414 31.3403C48.7814 34.7303 50.3314 36.9803 52.5614 38.9603L77.0414 59.2703L46.4414 37.0403L50.9014 23.3203Z" fill="#9492A0"/>
        </g>
        <path d="M106.67 118.95C106.67 118.95 110.19 112.39 115.66 108.62C118.93 106.36 125.24 104.59 125.24 104.59C125.24 104.59 119.36 111.98 115.02 115.44C110.68 118.9 106.68 118.94 106.68 118.94L106.67 118.95Z" fill="url(#paint14_linear_999_5822)"/>
        <path d="M121.431 107.061C116.601 107.341 112.731 111.201 110.631 113.921C108.531 116.631 109.721 117.541 112.381 116.361C115.031 115.181 123.471 106.951 121.431 107.071V107.061Z" fill="#898797"/>
        <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M121.429 107.06C118.159 107.03 114.049 110.12 112.769 111.5C111.479 112.88 114.049 115 115.799 113.88C117.549 112.76 122.919 107.08 121.429 107.06Z" fill="url(#paint15_linear_999_5822)"/>
        <defs>
            <radialGradient id="paint0_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.42 -11.3597) scale(198.35 198.35)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.51" stop-color="#9695A3"/>
                <stop offset="0.92" stop-color="#C5C4CB"/>
            </radialGradient>
            <radialGradient id="paint1_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.42 -11.3597) scale(198.35 198.35)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.51" stop-color="#9695A3"/>
                <stop offset="0.92" stop-color="#C5C4CB"/>
            </radialGradient>
            <radialGradient id="paint2_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(130.98 -6.21968) scale(155.68 155.68)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.28" stop-color="#BDBDC5"/>
                <stop offset="0.63" stop-color="#AFAEB8"/>
                <stop offset="1" stop-color="#9897A4"/>
            </radialGradient>
            <radialGradient id="paint3_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.9107 148.86) scale(129.16 129.16)">
                <stop offset="0.32" stop-color="#AEADB7"/>
                <stop offset="0.76" stop-color="#7B7A8B"/>
            </radialGradient>
            <linearGradient id="paint4_linear_999_5822" x1="80.8616" y1="0.000504051" x2="80.8616" y2="0.000504051" gradientUnits="userSpaceOnUse">
                <stop offset="0.63" stop-color="#B0AFB9"/>
                <stop offset="1" stop-color="#D9D9DD"/>
            </linearGradient>
            <radialGradient id="paint5_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.421 -11.3599) scale(198.35 198.35)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.51" stop-color="#9695A3"/>
                <stop offset="0.92" stop-color="#C5C4CB"/>
            </radialGradient>
            <radialGradient id="paint6_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.421 -11.3598) scale(198.35 198.35)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.51" stop-color="#9695A3"/>
                <stop offset="0.92" stop-color="#C5C4CB"/>
            </radialGradient>
            <radialGradient id="paint7_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(127.421 -11.3597) scale(198.35 198.35)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.51" stop-color="#9695A3"/>
                <stop offset="0.92" stop-color="#C5C4CB"/>
            </radialGradient>
            <radialGradient id="paint8_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(130.981 -6.21992) scale(155.68 155.68)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.28" stop-color="#BDBDC5"/>
                <stop offset="0.63" stop-color="#AFAEB8"/>
                <stop offset="1" stop-color="#9897A4"/>
            </radialGradient>
            <radialGradient id="paint9_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(130.98 -6.21957) scale(155.68 155.68)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.28" stop-color="#BDBDC5"/>
                <stop offset="0.63" stop-color="#AFAEB8"/>
                <stop offset="1" stop-color="#9897A4"/>
            </radialGradient>
            <radialGradient id="paint10_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(130.982 -6.21991) scale(155.68 155.68)">
                <stop stop-color="#C3C3CA"/>
                <stop offset="0.28" stop-color="#BDBDC5"/>
                <stop offset="0.63" stop-color="#AFAEB8"/>
                <stop offset="1" stop-color="#9897A4"/>
            </radialGradient>
            <radialGradient id="paint11_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.9112 148.861) scale(129.16 129.16)">
                <stop offset="0.32" stop-color="#AEADB7"/>
                <stop offset="0.76" stop-color="#7B7A8B"/>
            </radialGradient>
            <radialGradient id="paint12_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.9107 148.86) scale(129.16 129.16)">
                <stop offset="0.32" stop-color="#AEADB7"/>
                <stop offset="0.76" stop-color="#7B7A8B"/>
            </radialGradient>
            <radialGradient id="paint13_radial_999_5822" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.9116 148.86) scale(129.16 129.16)">
                <stop offset="0.32" stop-color="#AEADB7"/>
                <stop offset="0.76" stop-color="#7B7A8B"/>
            </radialGradient>
            <linearGradient id="paint14_linear_999_5822" x1="109.22" y1="115.65" x2="122.02" y2="108.29" gradientUnits="userSpaceOnUse">
                <stop stop-color="#E8E8EB"/>
                <stop offset="0.99" stop-color="#ABAAB5"/>
            </linearGradient>
            <linearGradient id="paint15_linear_999_5822" x1="117.681" y1="113.582" x2="116.733" y2="107.56" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="0.44" stop-color="#9998A5"/>
                <stop offset="1" stop-color="#817F90"/>
            </linearGradient>
        </defs>
    </svg>
)