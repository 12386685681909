export default () => (
<svg width="249" height="171" viewBox="0 0 249 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.7501 1.08997L63.6901 26.3799C56.4501 26.3399 50.4001 26.3099 39.5301 26.3199C1.03009 26.3699 0.830078 0.959961 0.830078 0.959961L66.7501 1.07996V1.08997Z" fill="#1C79C9"/>
    <path d="M66.7501 1.09003L60.2501 20.85C53.0101 20.81 51.8201 20.78 40.9501 20.79C2.45007 20.84 0.830078 0.969971 0.830078 0.969971L66.7501 1.09003Z" fill="url(#paint0_radial_999_6585)"/>
    <path d="M56.9995 31.13L53.9894 56.43C46.7494 56.39 55.5494 56.43 44.6794 56.43C6.17944 56.48 5.93945 31.01 5.93945 31.01L56.9995 31.13Z" fill="#1C79C9"/>
    <path d="M56.9995 31.13L50.5695 50.93C43.3295 50.89 56.9995 50.86 46.1295 50.87C7.62946 50.92 5.93945 31 5.93945 31L56.9995 31.12V31.13Z" fill="url(#paint1_radial_999_6585)"/>
    <path d="M47.2391 61.1598L20.1191 61.0498C20.1191 61.0498 24.3791 80.0998 44.2191 86.4698L47.2391 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M47.2391 61.1598L20.1191 61.0498C20.1191 61.0498 20.9591 74.5998 40.7991 80.9698L47.2391 61.1598Z" fill="url(#paint2_radial_999_6585)"/>
    <path d="M57.0005 31.1299L60.2505 37.3399L53.9805 56.4299L50.5605 50.9299L57.0005 31.1299Z" fill="#3386CF"/>
    <path d="M47.2302 61.1599L50.4802 67.3699L41.0602 96.0699L35.1602 97.8799L47.2302 61.1599Z" fill="#3386CF"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M66.7501 1.09003L0.830078 0.969971C0.830078 0.969971 2.45007 20.84 40.9501 20.79C51.8201 20.78 53.0101 20.81 60.2501 20.85L66.7501 1.09003Z" fill="url(#paint3_radial_999_6585)"/>
    <path style={{mixBlendMode: 'screen'}} d="M50.5596 50.93L54.3496 39.25C49.1896 40.94 44.2896 43.16 39.7896 45.91C37.9296 47.05 35.7396 48.46 33.3496 50.03C37.0796 50.57 41.3096 50.87 46.1096 50.87C56.9796 50.86 43.3096 50.89 50.5496 50.93H50.5596Z" fill="url(#paint4_radial_999_6585)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.9501 20.8C51.8201 20.79 53.0101 20.82 60.2501 20.86L65.4001 5.20001L61.5701 13.75C59.7301 18.25 58.6401 19.74 54.1501 19.78C49.3001 19.82 40.1701 19.72 35.5301 19.54C15.3201 18.75 3.41008 10.01 0.830078 0.98999C0.830078 0.98999 2.45007 20.86 40.9501 20.81V20.8Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M51.4197 45.0898C50.1997 47.6498 48.8797 49.7998 46.0397 49.6998C43.1097 49.5998 34.8598 49.5398 30.1498 48.4798C19.2198 46.0098 12.6898 41.9398 8.25977 36.9498C11.9798 42.8098 21.5598 50.9098 46.1198 50.8798C56.9897 50.8698 43.3198 50.8998 50.5598 50.9398L55.8798 34.5498L51.4097 45.0998L51.4197 45.0898Z" fill="#4C95D5"/>
    </g>
    <path d="M40.7991 80.9598L46.4191 63.6898C45.3691 65.8898 43.2792 70.9898 41.1492 75.7098C39.7092 78.9198 38.3192 78.5198 36.0892 77.5898C25.0092 72.9798 22.4591 67.4898 20.1191 61.0498C20.1191 61.0498 20.9591 74.5998 40.7991 80.9698V80.9598Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M18.3594 0.969971L66.7594 1.09003L65.1294 6.03998C65.1294 3.50998 63.9294 2.22 61.0094 2.19L18.3594 0.969971Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M18.3594 31.1299H56.9994L55.2194 36.6099L55.4094 34.7899C55.5094 33.0699 54.8994 32.1699 51.7694 32.0199L18.3594 31.1299Z" fill="#C9DFF2"/>
    </g>
    <path d="M124.59 149.55L201.01 94.03L171.82 4.18994H77.3599L48.1699 94.03L124.59 149.55Z" fill="#257ECC"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M84.5508 14.03L124.631 137.38L164.711 14.03H84.5508Z" fill="#5696D2"/>
    </g>
    <path d="M174.55 0.429932H74.63L43.75 95.46L124.59 154.19L205.43 95.46L174.55 0.429932ZM59.74 90.27L84.51 14.03H164.67L189.44 90.27L124.59 137.39L59.74 90.27Z" fill="url(#paint5_radial_999_6585)"/>
    <path d="M174.55 0.429932H74.6302L67.4902 22.4099C71.9302 22.6199 76.5302 23.1699 81.2502 24.0699L84.5103 14.03H164.67L189.44 90.27L180.04 97.0999C182.16 101.21 184.03 105.46 185.64 109.84L205.43 95.46L174.55 0.429932Z" fill="url(#paint6_radial_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M174.55 0.429932H74.6302L67.4902 22.4099C71.9302 22.6199 76.5302 23.1699 81.2502 24.0699L84.5103 14.03H164.67L189.44 90.27L180.04 97.0999C182.16 101.21 184.03 105.46 185.64 109.84L205.43 95.46L174.55 0.429932Z" fill="url(#paint7_radial_999_6585)"/>
    <path style={{mixBlendMode: 'screen'}} d="M169.07 121.88C168.59 116.59 167.81 111.55 166.74 106.76L124.59 137.39L59.7397 90.27L77.5298 35.53C72.4798 35.65 67.4998 36.22 62.6898 37.2L43.7598 95.46L124.6 154.19L169.08 121.87L169.07 121.88Z" fill="url(#paint8_radial_999_6585)"/>
    <path d="M59.7305 90.27L66.1405 88.83L88.4705 20.12H160.711L183.031 88.83L189.44 90.27L164.67 14.03H84.5105L59.7305 90.27Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M66.1406 88.8299L78.6306 56.7799C86.0306 37.7999 104.311 29.5699 124.671 29.5699C145.131 29.5699 163.471 37.9099 170.801 57.0099L183.021 88.8299L160.701 20.1199H88.4606L66.1406 88.8299Z" fill="#5098D6"/>
    </g>
    <path d="M59.7305 90.2701L66.1405 88.8301L124.591 131.29L183.031 88.8301L189.44 90.2701L124.591 137.38L59.7305 90.2701Z" fill="url(#paint9_linear_999_6585)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M66.1406 88.8299L89.0606 30.0199C90.1706 27.1799 92.9006 25.3099 95.9506 25.3099H153.551C156.611 25.3099 159.351 27.1999 160.451 30.0499L183.031 88.8299L160.711 20.1199H88.4706L66.1506 88.8299H66.1406Z" fill="#5098D6"/>
    </g>
    <path d="M84.5098 14.03L88.4697 20.12H160.71L164.67 14.03H84.5098Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M54.8203 61.3799L47.0803 90.3699C46.4803 92.8699 47.6602 96.1798 49.7202 97.7198L124.58 154.19L43.7402 95.4599L54.8102 61.3799H54.8203Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M66.1406 88.8301L121.901 126.38C123.381 127.38 125.801 127.38 127.271 126.38L183.031 88.8301L124.591 131.29L66.1506 88.8301H66.1406Z" fill="#5098D6"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.59 154.2L196.58 98.5999C201.62 94.8699 202.7 94.0599 200.44 86.9099L185.42 33.8599L205.44 95.4799L124.6 154.21L124.59 154.2Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M88.8594 111.43L124.589 137.38L172.829 102.34L124.589 139.59L88.8594 111.43Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M121.881 0.429932H174.551L185.411 33.8499L172.541 2.27997L121.881 0.429932Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M142.84 0.429932C142.4 4.75993 144.29 9.60997 147.94 14.03H164.67L168.81 26.7599C174.05 28.0399 179.07 28.09 183.17 26.96L174.55 0.429932H142.84Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M84.5202 108.27C83.2502 111.52 85.5502 116.38 90.1502 119.72C94.7502 123.06 100.07 123.74 102.78 121.53L84.5302 108.27H84.5202Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M102.89 130.42C102.12 131.6 99.9896 131.58 98.1296 130.37C96.2696 129.16 95.3895 127.22 96.1595 126.03C96.9295 124.85 99.0595 124.87 100.92 126.08C102.78 127.29 103.66 129.23 102.89 130.42Z" fill="#FEF3BD"/>
    </g>
    <path d="M66.75 1.08984L70 7.36981L63.69 26.3898L60.25 20.8498L66.75 1.08984Z" fill="#3386CF"/>
    <path d="M182.439 1.08997L185.499 26.3799C192.739 26.3399 198.789 26.3099 209.659 26.3199C248.159 26.3699 248.359 0.959961 248.359 0.959961L182.439 1.07996V1.08997Z" fill="#1C79C9"/>
    <path d="M182.439 1.09003L188.939 20.85C196.179 20.81 197.369 20.78 208.239 20.79C246.739 20.84 248.359 0.969971 248.359 0.969971L182.439 1.09003Z" fill="url(#paint10_radial_999_6585)"/>
    <path d="M192.199 31.13L195.209 56.43C202.449 56.39 193.649 56.43 204.519 56.43C243.019 56.48 243.259 31.01 243.259 31.01L192.199 31.13Z" fill="#1C79C9"/>
    <path d="M192.199 31.13L198.629 50.93C205.869 50.89 192.199 50.86 203.069 50.87C241.569 50.92 243.259 31 243.259 31L192.199 31.12V31.13Z" fill="url(#paint11_radial_999_6585)"/>
    <path d="M201.949 61.1598L229.069 61.0498C229.069 61.0498 224.809 80.0998 204.969 86.4698L201.949 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M201.949 61.1598L229.069 61.0498C229.069 61.0498 228.229 74.5998 208.389 80.9698L201.949 61.1598Z" fill="url(#paint12_radial_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M182.439 1.09003L188.939 20.85C196.179 20.81 197.369 20.78 208.239 20.79C246.739 20.84 248.359 0.969971 248.359 0.969971L182.439 1.09003Z" fill="url(#paint13_radial_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M192.199 31.13L198.629 50.93C205.869 50.89 192.199 50.86 203.069 50.87C241.569 50.92 243.259 31 243.259 31L192.199 31.12V31.13Z" fill="url(#paint14_radial_999_6585)"/>
    <path d="M182.439 1.08984L179.189 7.36981L185.499 26.3898L188.939 20.8498L182.439 1.08984Z" fill="#3386CF"/>
    <path d="M192.199 31.1299L188.939 37.3399L195.209 56.4299L198.629 50.9299L192.199 31.1299Z" fill="#3386CF"/>
    <path d="M201.961 61.1599L198.711 67.3699L208.171 96.1899L214.021 97.8799L201.961 61.1599Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M56.6406 55.78L73.5206 7.36993C75.2606 2.44993 76.5306 1.15991 81.7506 0.96991L124.591 0.419922H74.6306L56.6506 55.77L56.6406 55.78Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M139.689 7.98979C136.199 5.44979 131.989 5.28981 130.289 7.62981C129.069 9.30981 129.479 11.8098 131.089 14.0298H143.609C143.409 11.9798 141.999 9.67979 139.679 7.98979H139.689Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M197.359 19.3499C192.969 18.9099 189.909 17.3199 187.909 13.3899L182.439 1.09985L188.939 20.8599C196.179 20.8199 197.369 20.7899 208.239 20.7999C214.369 20.7999 219.569 20.3099 223.969 19.4599C214.429 20.6099 204.629 20.0699 197.359 19.3499Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M203.069 50.8799C212.379 50.8899 219.539 49.7298 225.039 47.9698C217.129 50.1698 210.479 50.0099 205.429 49.7099C201.949 49.4999 199.119 46.9399 197.649 43.7799L192.199 31.1299L198.629 50.9299C205.869 50.8899 192.199 50.8599 203.069 50.8699V50.8799Z" fill="#4C95D5"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M208.391 80.96C212.691 79.58 216.091 77.86 218.791 76.02C215.601 77.24 209.601 78.1099 209.601 78.1099L201.961 61.1599V61.1799L208.391 80.96Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M182.44 1.09003L242.72 2.12C245.14 2.16 246.27 3.97997 245.22 6.15997C244.13 8.43997 242.36 11.16 239.47 13.51C247.79 7.85001 248.35 0.969971 248.35 0.969971L182.43 1.09003H182.44Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M192.199 31.13L236.669 32.18C241.869 32.29 241.019 34.5 239.669 37.2C238.839 38.87 237.689 40.69 236.179 42.21C242.759 36.86 243.259 31.02 243.259 31.02L192.199 31.14V31.13Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M188.939 20.85C194.179 20.82 196.249 20.8 201.119 20.79C207.939 16.51 212.489 10.16 213.129 2.76001C213.179 2.18001 213.199 1.61003 213.199 1.03003L182.439 1.09003L188.939 20.85Z" fill="#FEF3BD"/>
    </g>
    <path d="M221.57 73.8798C228.65 67.6998 229.07 61.0498 229.07 61.0498L201.95 61.1598L214.02 97.8798L127.66 160.63V170.61L237.41 109.51L221.57 73.8798Z" fill="url(#paint15_radial_999_6585)"/>
    <path d="M35.1595 97.8798L47.2395 61.1598L20.1195 61.0498C20.1195 61.0498 20.5395 67.6998 27.6095 73.8698L11.7695 109.51L121.52 170.61V160.63L35.1595 97.8798Z" fill="url(#paint16_radial_999_6585)"/>
    <path style={{mixBlendMode: 'screen'}} d="M35.1595 97.8798L47.2395 61.1598L20.1195 61.0498C20.1195 61.0498 20.5395 67.6998 27.6095 73.8698L11.7695 109.51L121.52 170.61V160.63L35.1595 97.8798Z" fill="url(#paint17_radial_999_6585)"/>
    <path d="M41.0602 96.0698L121.52 157.26V160.62L35.1602 97.8798L41.0602 96.0698Z" fill="url(#paint18_linear_999_6585)"/>
    <path d="M208.17 96.1899L127.65 157.26V160.62L214.02 97.8799L208.17 96.1899Z" fill="url(#paint19_linear_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M221.571 73.8798C228.651 67.6998 229.071 61.0498 229.071 61.0498L201.951 61.1598L214.021 97.8798L188.311 116.56C188.581 117.59 188.841 118.62 189.081 119.66C190.471 125.16 191.661 130.07 192.671 134.41L237.411 109.5L221.571 73.8698V73.8798Z" fill="url(#paint20_radial_999_6585)"/>
    <path style={{mixBlendMode: 'screen'}} d="M169.47 139.12C169.71 136.03 169.79 133 169.77 130.03L127.66 160.62V170.6L167.61 148.36C168.57 145.53 169.22 142.46 169.47 139.12Z" fill="url(#paint21_radial_999_6585)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M27.6095 73.8701L11.7695 109.51L68.7895 141.26C68.7895 141.26 29.8495 118.01 17.3195 110.07C15.0995 108.66 14.3295 107.45 15.3795 105.04C19.1095 96.47 27.5195 76.16 29.1095 73.1C24.0495 69.19 21.9695 65.51 20.1195 61.04C20.1195 61.04 20.5395 67.69 27.6095 73.86V73.8701Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M224.411 71.0001C228.781 65.8001 229.071 61.04 229.071 61.04L201.991 61.1501H201.961L224.351 62.1C227.661 62.3 227.061 63.5501 226.671 65.1501C226.231 67.0001 225.491 69.1801 224.411 70.9901V71.0001Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M184.4 139.02L226.18 113.76C233.64 109.27 234.31 108.48 231.1 100.3L224.08 79.52L237.41 109.51L184.4 139.02Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M127.65 160.62C127.65 160.62 127.92 162.02 128.1 163.1C128.92 167.88 130.2 167.64 132.99 166.31L148.71 158.88L127.65 170.6V160.62Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M82.2793 148.76L116.569 166.12C120.069 168.04 120.499 167.05 121.199 162.98C121.279 162.52 121.519 160.9 121.519 160.9V170.61L82.2793 148.76Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M159.17 58.1099V54.0999L136.11 58.1099L124.58 38.1399L113.05 58.1099L89.9902 54.0999V58.1099L101.52 78.0799L89.9902 94.0399V98.0499H113.05L124.58 118.02L136.11 98.0499H159.17V94.0399L147.64 78.0799L159.17 58.1099ZM143.31 88.8899H130.82L124.58 99.6999L118.34 88.8899H105.85L112.09 78.0799V74.0699L105.85 67.2699H118.34L124.58 56.4599L130.82 67.2699H143.31L137.07 74.0699V78.0799L143.31 88.8899Z" fill="#5098D6"/>
    </g>
    <path d="M159.17 54.0999V48.0799L136.11 54.0999L124.58 34.1299L113.05 54.0999L89.9902 48.0799V54.0999L101.52 74.0699L89.9902 88.0199V94.0399H113.05L124.58 114.01L136.11 94.0399H159.17V88.0199L147.64 74.0699L159.17 54.0999ZM143.31 84.8799H130.82L124.58 95.6899L118.34 84.8799H105.85L112.09 74.0699V68.0499L105.85 63.2499H118.34L124.58 52.4399L130.82 63.2499H143.31L137.07 68.0499V74.0699L143.31 84.8799Z" fill="#3386CF"/>
    <path d="M159.17 48.0899H136.11L124.58 28.1199L113.05 48.0899H89.9902L101.52 68.0599L89.9902 88.0299H113.05L124.58 108L136.11 88.0299H159.17L147.64 68.0599L159.17 48.0899ZM143.31 78.8699H130.82L124.58 89.6799L118.34 78.8699H105.85L112.09 68.0599L105.85 57.2499H118.34L124.58 46.4399L130.82 57.2499H143.31L137.07 68.0599L143.31 78.8699Z" fill="url(#paint22_radial_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M154.67 55.8798L159.17 48.0898H145.58C148.71 50.5598 151.75 53.1498 154.67 55.8798Z" fill="url(#paint23_radial_999_6585)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M121.859 32.8399C124.559 34.2299 127.239 35.7099 129.879 37.2799L124.589 28.1099L121.859 32.8299V32.8399Z" fill="url(#paint24_radial_999_6585)"/>
    <path style={{mixBlendMode: 'screen'}} d="M142.3 59.01L137.08 68.05L143.32 78.86H130.83L124.59 89.67L118.35 78.86H105.86L112.1 68.05L105.86 57.24H118.35L124.59 46.43L130.83 57.24H140.38C133.23 50.81 125.24 45.54 116.84 41.53L113.06 48.08H90L101.53 68.05L90 88.02H113.06L124.59 107.99L136.12 88.02H159.18L147.65 68.05L148.89 65.91C146.8 63.49 144.6 61.19 142.3 59.01Z" fill="url(#paint25_radial_999_6585)"/>
    <path d="M124.589 52.4399V46.4299L118.339 57.2399H105.859L109.329 63.2599H118.339L124.589 52.4399Z" fill="#1C79C9"/>
    <path d="M130.83 63.26V57.24H143.32L139.79 63.19L130.83 63.26Z" fill="#1C79C9"/>
    <path d="M90 48.0898L101.53 68.0498L99.79 71.0598L90 54.0998V48.0898Z" fill="#1C79C9"/>
    <path d="M90 88.02H113.06L124.59 107.99V114.01L113.06 94.04H90V88.02Z" fill="#1C79C9"/>
    <path d="M159.179 88.02H136.119V94.04H159.179V88.02Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M145.871 75.4C144.191 74.87 142.551 74.92 141.321 75.4L143.321 78.8699H139.461C139.941 80.3299 141.521 81.73 143.661 82.4C146.621 83.33 149.501 82.52 150.111 80.59C150.721 78.66 148.821 76.33 145.861 75.4H145.871Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M139.129 82.5998C138.829 83.5698 137.379 83.9698 135.899 83.5098C134.419 83.0398 133.469 81.8798 133.779 80.9198C134.079 79.9498 135.529 79.5498 137.009 80.0098C138.489 80.4798 139.439 81.6398 139.129 82.5998Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M124.59 28.1199L134.98 49.6499L159.17 48.0899H136.12L124.59 28.1199Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M90 88.02L113.76 86.46L124.59 107.99L113.06 88.02H90Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.59 107.99L135.18 85.54L159.17 88.0201H136.12L124.59 107.99Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M90 48.0898L104.04 68.0498L90 88.0198L101.53 68.0498L90 48.0898Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M105.859 78.8699L117.129 80.4499L124.589 89.6799L118.339 78.8699H105.859Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.59 46.4299L131.67 55.78L143.32 57.2399H130.83L124.59 46.4299Z" fill="#4C95D5"/>
    </g>
    <path style={{mixBlendMode: 'soft-light'}} d="M5.92969 31.01C5.92969 31.01 6.20967 34.23 9.03967 38.08C13.2397 35.7 17.9297 33.27 22.9197 31.05L5.92969 31.01Z" fill="url(#paint26_radial_999_6585)"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M25.3401 61.0601L47.2401 61.1501L38.7501 87.1401L45.47 63.8901C45.41 62.5701 45.0501 62.1101 43.2401 61.9901L25.3301 61.0601H25.3401Z" fill="#C9DFF2"/>
    </g>
    <path d="M150.43 119.5C150.43 119.5 153.95 112.94 159.42 109.17C162.69 106.91 169 105.14 169 105.14C169 105.14 163.12 112.53 158.78 115.99C154.44 119.45 150.44 119.49 150.44 119.49L150.43 119.5Z" fill="url(#paint27_linear_999_6585)"/>
    <path d="M165.2 107.61C160.37 107.89 156.5 111.75 154.4 114.47C152.3 117.18 153.49 118.09 156.15 116.91C158.8 115.73 167.24 107.5 165.2 107.62V107.61Z" fill="#3A8AD0"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M165.199 107.61C161.929 107.58 157.819 110.67 156.539 112.05C155.249 113.43 157.819 115.55 159.569 114.43C161.319 113.31 166.689 107.63 165.199 107.61Z" fill="url(#paint28_linear_999_6585)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.10002) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.759 -5.09999) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.759 -5.10014) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.23 -5.10003) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint4_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.0696 163.59) scale(157.11 157.11)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint5_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.10007) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.10007) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.23 -5.10007) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.0698 163.59) scale(157.11 157.11)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <linearGradient id="paint9_linear_999_6585" x1="124.59" y1="-579" x2="124.59" y2="-579" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint10_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.759 -5.10002) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.759 -5.09999) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.759 -5.10014) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.229 -5.10003) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.229 -5.09999) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.1002) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.1002) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.0695 163.59) scale(157.11 157.11)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <linearGradient id="paint18_linear_999_6585" x1="35.1602" y1="128.34" x2="121.52" y2="128.34" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <linearGradient id="paint19_linear_999_6585" x1="214.01" y1="128.4" x2="127.65" y2="128.4" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint20_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.231 -5.10018) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint21_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.0701 163.59) scale(157.11 157.11)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint22_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.76 -5.10013) scale(218.41 218.41)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint23_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.23 -5.10001) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint24_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.23 -5.1003) scale(163.84 163.841)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint25_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(54.07 163.59) scale(157.11 157.11)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint26_radial_999_6585" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.23 -5.10003) scale(163.84 163.84)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <linearGradient id="paint27_linear_999_6585" x1="152.98" y1="116.19" x2="165.78" y2="108.83" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D8E8F6"/>
            <stop offset="0.99" stop-color="#72ACDE"/>
        </linearGradient>
        <linearGradient id="paint28_linear_999_6585" x1="161.474" y1="114.118" x2="160.519" y2="108.102" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#559AD7"/>
            <stop offset="1" stop-color="#2C82CD"/>
        </linearGradient>
    </defs>
</svg>
)