import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/game/history';
import Numeric from '../../components/numeric';
import DateLib from '../../../lib/date';
import ModalsLib from '../../../lib/modals';

export default ({login}) => {
    const {history} = useSelector(Selector);

    return (
        <div className={`game-actions ${!login ? 'need-login' : ''} ${history.count < 1 ? 'empty' : ''}`}>
            {history.count < 1 ? (
                <div className="empty-plug">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3337 15.0666V9.38664C27.3337 4.0133 26.0803 2.66663 21.0403 2.66663H10.9603C5.92033 2.66663 4.66699 4.0133 4.66699 9.38664V24.3999C4.66699 27.9466 6.61367 28.7866 8.97367 26.2533L8.98698 26.24C10.0803 25.08 11.747 25.1733 12.6936 26.4399L14.0403 28.24" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M24.2667 28.5333C26.6231 28.5333 28.5333 26.6231 28.5333 24.2667C28.5333 21.9103 26.6231 20 24.2667 20C21.9103 20 20 21.9103 20 24.2667C20 26.6231 21.9103 28.5333 24.2667 28.5333Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M29.3333 29.3333L28 28" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.667 9.33337H21.3337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 14.6666H20" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    Your transactions will be displayed here
                </div>
            ) : (
                <>
                    <div className="head">
                        <div className="col id">ID</div>
                        <div className="col login">Login</div>
                        <div className="col type">Type</div>
                        <div className="col amount">Amount</div>
                        <div className="col status">Status</div>
                        <div className="col date">Date</div>
                    </div>
                    <div className="table">
                        {history.list.map((tx, key) => {
                            return (
                                <>
                                    {key > 0 && history.list[key - 1].login !== tx.login && (
                                        <div className="text">
                                            <div className="line"></div>
                                            <div className="value">LOGIN HAS BEEN CHANGED</div>
                                            <div className="line"></div>
                                        </div>
                                    )}
                                    <div className={`tr ${login ? (login !== tx.login ? 'notlogin' : '') : ''}`} key={key} onClick={e => {
                                        // ModalsLib.open('deposit_success', {
                                        //     transactionId: tx.transactionId
                                        // });
                                    }}>
                                        <div className="col id">{tx.transactionId}</div>
                                        <div className="col login">{tx.login}</div>
                                        {tx.type === 'deposit' && (
                                            <>
                                                {tx.operation_type === 'cash' && <div className="col type">Add Cash</div>}
                                            </>
                                        )}
                                        {tx.type === 'cashout' && (
                                            <>
                                                <div className="col type">Cash Out</div>
                                            </>
                                        )}
                                        <div className="col amount">
                                            {tx.type === 'deposit' && <span className="red">- <Numeric value={tx.amount} /></span>}
                                            {tx.type === 'cashout' && (
                                                <>
                                                    {
                                                        tx.status === 1 
                                                        ? <span className="green">+ <Numeric value={tx.amount} /></span> 
                                                        : `-`
                                                    }
                                                </>
                                            )}
                                        </div>
                                        <div className="col status">
                                            {tx.type === 'deposit' && (
                                                <>
                                                    {tx.status === 0 && (
                                                        <span className="processing">
                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.22722 13.8197C7.86604 16.453 12.1399 16.4484 14.7732 13.8096C17.4064 11.1708 17.4019 6.89695 14.7631 4.26369C12.1243 1.63043 7.85041 1.63494 5.21715 4.27376C3.89968 5.59401 3.24249 7.32356 3.24516 9.05214L3.24673 10.5488" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M1.74512 9.05029L3.2467 10.5487L4.74512 9.04713" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            
                                                            <b>Processing</b>
                                                        </span>
                                                    )}

                                                    {tx.status === 1 && (
                                                        <span className="done">
                                                            Done
                                                        </span>
                                                    )}

                                                    {tx.status === 2 && (
                                                        <span className="canceled">Canceled</span>
                                                    )}
                                                </>
                                            )}

                                            {tx.type === 'cashout' && (
                                                <>
                                                    {tx.status === 0 && (
                                                        <span className="processing">
                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.22722 13.8197C7.86604 16.453 12.1399 16.4484 14.7732 13.8096C17.4064 11.1708 17.4019 6.89695 14.7631 4.26369C12.1243 1.63043 7.85041 1.63494 5.21715 4.27376C3.89968 5.59401 3.24249 7.32356 3.24516 9.05214L3.24673 10.5488" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M1.74512 9.05029L3.2467 10.5487L4.74512 9.04713" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            
                                                            <b>Processing</b>
                                                        </span>
                                                    )}

                                                    {tx.status === 1 && (
                                                        <span className="done">
                                                            Done
                                                        </span>
                                                    )}

                                                    {tx.status === 2 && (
                                                        <span className="canceled">Canceled</span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(tx.timestamp, '<br/>')}}></div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </>
            )}


        </div>
    )
}