import Navigation from './navigation';
import InfoButton from '../../info-button';
import {navigate} from '../../../../utils/navigation';

export default () => {

    return (
        <div className="left">
            <div className="logotype" onClick={e => navigate('/')}>Lucksy</div>
            <div className="separator"></div>
            <InfoButton />

            <Navigation />
        </div>
    )
}