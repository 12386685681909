import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    (user) => {
        return {
            user
        }
    }
)