import {useEffect} from 'react';
import PageContainer from '../page-container';
import Component from './component';
import Loader from '../../components/loader';

export default () => {
    useEffect(() => {
        Loader.close();
    }, []);

    return (
        <PageContainer>
            <Component />
        </PageContainer>
    )
}