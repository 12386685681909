import types from './types';
import API from '../../lib/api';

export const initialize = () => async dispatch => {
    const response = await API.call({
        path: '/initialize'
    });

    if(response.success)
        dispatch({
            type: types.init
        });
}

export const setConnection = connection => {
    return {
        type: types.connection,
        payload: connection
    }
}

export const setMobile = state => {
    return {
        type: types.mobile,
        payload: state
    }
}

export const toggleSidebar = () => {
    return {
        type: types.sidebar
    }
}