import DateLib from '../../../lib/date';

export default () => {

    return (
        <div className="vip-history">
            <div className="top">
                <div className="title">History</div>
            </div>

            <div className="table">
                <div className="head">
                    <div className="col id">ID</div>
                    <div className="col bonus">Bonus</div>
                    <div className="col status">Status</div>
                    <div className="col date">Date</div>
                </div>
                <div className="body">
                    <div className="tr">
                        <div className="col id">#WPH1BA32</div>
                        <div className="col type">Senor</div>
                        <div className="col amount">
                            Cash <span>$100</span>
                        </div>
                        <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(Date.now(), '<br/>')}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}