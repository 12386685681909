export default ({
    children,
    active,
    onClick = e => e
}) => {
    if(active) 
        return (
            <div className="green-button inactive">
                <div className="button-dots">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        )

    return (
        <div className="green-button" onClick={onClick}>
            {children}
        </div>
    );
}