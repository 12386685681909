import {navigate} from '../../../../../../utils/navigation';

export default () => {

    return (
        <div className="slide testyourluck size-params">
            <div className="size-params layer0"><div className="money1"></div></div>
            <div className="layer-margin size-params layer1"><div className="money2"></div></div>
            <div className="layer-margin size-params layer2"><div className="clover"></div></div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Test<br/>
                    your luck
                </div>
                {/* <div className="descriptions">Get +50% up to 600 USD</div> */}
                <div className="descriptions-short">If you are unlucky several times in a row - we give you the opportunity to try your luck with real money</div>
                <div className="details">
                    <div className="button" onClick={e => navigate('/testyourluck')}>How it works?</div>
                </div>
            </div>
        </div>
    )
}