import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.game,
    props.games.buttons.requestLogin,
    (game, button) => {
        return {
            game,
            button
        }
    }
)