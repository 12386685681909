export default {
    tab: "wallet.tab",
    preset: "wallet.preset",
    crypto_deposit: {
        loaded: "wallet.crypto_deposit.loaded",
        change: "wallet.crypto_deposit.change"
    },
    cashapp_deposit: {
        setStage: "wallet.cashapp_deposit.stage",
        toggleStageButton: "wallet.cashapp_deposit.toggle.stage.button",
        updateStageData: "wallet.cashapp_deposit.update.stage.data"
    }
}