const hours = [
    '12:MM AM',
    '01:MM AM',
    '02:MM AM',
    '03:MM AM',
    '04:MM AM',
    '05:MM AM',
    '06:MM AM',
    '07:MM AM',
    '08:MM AM',
    '09:MM AM',
    '10:MM AM',
    '11:MM AM',
    '12:MM PM',
    '01:MM PM',
    '02:MM PM',
    '03:MM PM',
    '04:MM PM',
    '05:MM PM',
    '06:MM PM',
    '07:MM PM',
    '08:MM PM',
    '09:MM PM',
    '10:MM PM',
    '11:MM PM'
]

const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const parse = (timestamp, devider = ' ') => {
    const date = new Date(timestamp);

    let dateString = '';
        dateString += `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`; // Day
        dateString += ` ${month[date.getMonth()]}`; // Month
        dateString += `${devider}${hours[date.getHours()].replace('MM', `${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`)}`;

    return dateString;
}

export default {
    parse
}