import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PageContainer from '../page-container';
import Loader from '../../components/loader';
import Wheel from './wheel';
import History from './history';
import VIPPlug from './vip';
import FAQ from './faq';
import Components from '../../components';
import Plugs from './plugs';

export default () => {
    const [scale, setScale] = useState(1),
          [margin, setMargin] = useState(40),
          [marginBottom, setMarginBottom] = useState(0),
          location = useLocation();

    const resizeHandler = () => {
        const width = document.body.offsetWidth,
              coef = width / 1185,
              mobile_coef = width /  545;

        // Desktop scale
        if(width < 1185 && width > 800) {
            setScale(coef);
            setMargin((width / 1185) * 40);
            setMarginBottom((515 * coef) - 515);
        } else {
            if(width <= 800) {
                setMargin(0);
                setScale(width / (515 + 30));
                setMarginBottom((926 * mobile_coef) - 926);
            } else {
                setScale(1);
                setMargin(40);
                setMarginBottom(0);
            }
        }
    }

    useEffect(() => {
        Loader.close();
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);
 
    return (
        <PageContainer>
            <div className="wheel-page">
                <Components.Winners />

                <div className="wheel-container">
                    <div className="wheel-main" style={{transform: `scale(${scale})`, marginTop: `${margin}px`, marginBottom: `${marginBottom}px`}}>
                        <div className="left">
                            <div className="layer visible">
                                <div className="pretty-girl"></div>
                            </div>
                            <div className="layer content">
                                <div className="details">
                                    <div className="title">SPIN THE WHEEL</div>
                                    <div className="description">Get prizes every day in the win-win lottery wheel of luck!</div>
                                </div>
                                <div className="status notspin">
                                    <div className="rule">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#F28C20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.9991 13.96L10.0391 10" stroke="#F28C20" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.96 10.04L10 14" stroke="#F28C20" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        Make one deposit to any of the games
                                    </div>

                                    <div className="rule">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10 12.5L11.5 14L14.75 11" stroke="#0CDA50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                        You haven't spin the wheel for 24 hours 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <Wheel />
                        </div>
                    </div>

                    <Plugs />

                    <History />
                    <VIPPlug />
                    <FAQ />

                </div>

                <Components.DepositBonus />
                <Components.Footer />
            </div>
        </PageContainer>
    )
}