import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/modals/wallet/tabs';
import {setTab} from '../../../../reducers/wallet/actions';
import Numeric from '../../../components/numeric';
import ModalsLib from '../../../../lib/modals';

import Deposit from './deposit';
import Cashout from './cashout';

export default () => {
    const {user, tab} = useSelector(Selector),
          dispatch = useDispatch(),
          activeRef = useRef();

    useEffect(() => {
        if(activeRef.current) {
            const active_link = document.querySelector(`.modal .modal-nav .tabs .tab[data-tab="${tab}"]`);

            activeRef.current.style.width = `${active_link.offsetWidth - 30}px`;
            activeRef.current.style.marginLeft = `${active_link.offsetLeft - 5}px`;
        }
    }, [tab]);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Wallet</div>
                    <div className="descriptions">Deposit and cash out your funds in your wallet</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body methods">
                <div className="balance-block">
                    <div className="label">Balance</div>
                    <div className="value">
                        <b>$</b> <Numeric value={user.balance} prefix={''} />
                    </div>
                </div>

                <div className="modal-nav">
                    <div className="tabs">
                        <div className={`tab ${tab === "deposit" ? "active": ""}`} data-tab="deposit" onClick={e => dispatch(setTab('deposit'))}>Deposit</div>
                        <div className={`tab ${tab === "cashout" ? "active": ""}`} data-tab="cashout" onClick={e => dispatch(setTab('cashout'))}>Cashout</div>
                        <div className={`tab ${tab === "history" ? "active": ""}`} data-tab="history" onClick={e => dispatch(setTab('history'))}>History</div>
                    </div>
                    <div className="active-block">
                        <div className="active-element" ref={activeRef}></div>
                    </div>
                </div>

                {tab === 'deposit' && <Deposit />}
                {tab === 'cashout' && <Cashout />}
            </div>
        </>
    )
}