import {useState, useEffect} from 'react';
import LightSector from './light-sector';
import DarkSector from './dark-sector';
import Selector from './selector';

export default () => {
    const [rotate, setRotate] = useState(0),
          [button, setButton] = useState(false);

    useEffect(() => {
        if(button)
            window.buttonTimeout = setTimeout(() => {
                setButton(false);
            }, 5e3);
        
        return () => window.clearTimeout(window.buttonTimeout);
    }, [button]);

    return (
        <div className="wheel">
            <div className="selector"><Selector /></div>
            <div className="lights neutral">
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
                <div className="row">
                    <div className="light"><div className="glow"></div></div>
                    <div className="light"><div className="glow"></div></div>
                </div>
            </div>
            <div className="sectors">
                <div className="row" style={{transform: `rotate(${rotate}deg)`}}>
                    <div className="sector light">
                        <LightSector />
                        <div className="values">
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sector dark">
                        <DarkSector />
                        <div className="values">
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                                <div className="value">
                                    <div className="name">FREEPLAY</div>
                                    <div className="sector-value">$5</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-block">
                <div className="button-circle">
                    <div className={`button ${button ? 'active' : ''}`} onClick={e => {
                        setButton(true);
                        setRotate(rotate + (360*5));
                    }}>SPIN</div>
                </div>
            </div>
            <div className="circle"></div>
        </div>
    )
}