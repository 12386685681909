import {useState} from 'react';
import DateLib from '../../../lib/date';

export default () => {
    const [tab, setTab] = useState('all');

    return (
        <div className="wheel-history">
            <div className="top">
                <div className="title">History</div>
                <div className="tabs">
                    <span className={`${tab === 'all' ? 'active' : ''}`} onClick={e => setTab('all')}>Live winners</span>
                    <span className={`${tab === 'user' ? 'active' : ''}`} onClick={e => setTab('user')}>My history</span>
                </div>
            </div>

            <div className={`table ${tab}`}>
                {tab === 'all' ? (
                    <>
                        <div className="head">
                            <div className="col id">ID</div>
                            <div className="col user">User</div>
                            <div className="col bonus">Bonus</div>
                            <div className="col date">Date</div>
                        </div>
                        <div className="body">
                            <div className="tr">
                                <div className="col id">#WPH1BA32</div>
                                <div className="col user">Senor</div>
                                <div className="col bonus">
                                    Cash <span>$100</span>
                                </div>
                                <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(Date.now(), '<br/>')}}></div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="head">
                            <div className="col id">ID</div>
                            <div className="col bonus">Bonus</div>
                            <div className="col status">Status</div>
                            <div className="col date">Date</div>
                        </div>
                        <div className="body">
                            <div className="tr">
                                <div className="col id">#WPH1BA32</div>
                                <div className="col type">Senor</div>
                                <div className="col amount">
                                    Cash <span>$100</span>
                                </div>
                                <div className="col date" dangerouslySetInnerHTML={{__html: DateLib.parse(Date.now(), '<br/>')}}></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}