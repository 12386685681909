import types from '../types';

export const loadWallet = (coin, network) => dispatch => {
    // Disable button and clear error
    setTimeout(() => {
        dispatch({
            type: types.crypto_deposit.loaded,
            payload: {
                address: "0x58f7Eb4CdFfE3503AC50132065b26A5e8bD11E4b",
                minimal: 10
            }
        })
    }, 300);
}

export const changeCoin = (coin, network) => {
    return {
        type: types.crypto_deposit.change,
        payload: {
            coin,
            network
        }
    }
}
