import types from './types';

const InitialState = {
    loaded: false,
    user: null,
    logins: null
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.game:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    game: payload
                } : state.user
            }
        case types.balance:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    balance: payload
                } : state.user
            }
        case types.logins.create:
            return {
                ...state,
                logins: state.logins ? [
                    {
                        ...payload,
                        balance_details: {
                            ...payload.balance_details,
                            lastUpdateTimestamp: payload.balance_details.lastUpdateTimestamp + Date.now()
                        }
                    },
                    ...state.logins
                ] : state.logins
            }
        case types.logins.remove:
            return {
                ...state,
                logins: state.logins ? state.logins.filter(login => login.game !== payload) : state.logins
            }
        case types.logins.update:
            return {
                ...state,
                logins: state.logins ? state.logins.map(login => {
                    return login.id === payload.id ? {
                        ...login,
                        ...payload,
                        balance_details: payload.balance_details ? {
                            ...payload.balance_details,
                            lastUpdateTimestamp: payload.balance_details.lastUpdateTimestamp + Date.now()
                        } : login.balance_details
                    } : login;
                }) : state.logins
            }
        case types.game:
            return {
                ...state,
                user: state.user ? {
                    ...state.user,
                    game: payload
                } : state.user
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                user: payload.user,
                // logins: [{game: 'gameroom'}]
                logins: payload.logins.map(login => {
                    return {
                        ...login,
                        balance_details: {
                            ...login.balance_details,
                            lastUpdateTimestamp: login.balance_details.lastUpdateTimestamp + Date.now()
                        }
                    }
                })
            }
        default:
            return state;
    }
}