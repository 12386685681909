export default () => (
<svg width="248" height="171" viewBox="0 0 248 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M123.76 149.55L200.18 94.03L170.99 4.18994H76.5201L47.3301 94.03L123.76 149.55Z" fill="#257ECC"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M83.7109 14.03L123.791 137.38L163.881 14.03H83.7109Z" fill="#5696D2"/>
    </g>
    <path d="M173.72 0.429932H73.7999L42.9199 95.46L123.76 154.19L204.6 95.46L173.72 0.429932ZM58.9099 90.27L83.6799 14.03H163.84L188.61 90.27L123.76 137.39L58.9099 90.27Z" fill="url(#paint0_radial_999_6356)"/>
    <path d="M173.72 0.429932H73.8002L66.6602 22.4099C71.1002 22.6199 75.7002 23.1699 80.4202 24.0699L83.6802 14.03H163.84L188.61 90.27L179.21 97.0999C181.33 101.21 183.2 105.46 184.81 109.84L204.6 95.46L173.72 0.429932Z" fill="url(#paint1_radial_999_6356)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M173.72 0.429932H73.8002L66.6602 22.4099C71.1002 22.6199 75.7002 23.1699 80.4202 24.0699L83.6802 14.03H163.84L188.61 90.27L179.21 97.0999C181.33 101.21 183.2 105.46 184.81 109.84L204.6 95.46L173.72 0.429932Z" fill="url(#paint2_radial_999_6356)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.24 121.88C167.76 116.59 166.98 111.55 165.91 106.76L123.76 137.39L58.9097 90.27L76.6997 35.53C71.6497 35.65 66.6697 36.22 61.8597 37.2L42.9297 95.46L123.77 154.19L168.25 121.87L168.24 121.88Z" fill="url(#paint3_radial_999_6356)"/>
    <path d="M58.9004 90.27L65.3104 88.83L87.6304 20.12H159.88L182.2 88.83L188.61 90.27L163.84 14.03H83.6704L58.9004 90.27Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.3105 88.8299L77.8006 56.7799C85.2006 37.7999 103.481 29.5699 123.841 29.5699C144.301 29.5699 162.641 37.9099 169.971 57.0099L182.191 88.8299L159.871 20.1199H87.6305L65.3105 88.8299Z" fill="#5098D6"/>
    </g>
    <path d="M58.9004 90.2701L65.3104 88.8301L123.76 131.29L182.2 88.8301L188.61 90.2701L123.76 137.38L58.9004 90.2701Z" fill="url(#paint4_linear_999_6356)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.3105 88.8299L88.2306 30.0199C89.3406 27.1799 92.0706 25.3099 95.1206 25.3099H152.721C155.781 25.3099 158.521 27.1999 159.621 30.0499L182.201 88.8299L159.881 20.1199H87.6405L65.3205 88.8299H65.3105Z" fill="#5098D6"/>
    </g>
    <path d="M83.6699 14.03L87.6299 20.12H159.88L163.84 14.03H83.6699Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M53.9902 61.3799L46.2502 90.3699C45.6502 92.8699 46.8302 96.1798 48.8902 97.7198L123.75 154.19L42.9102 95.4599L53.9802 61.3799H53.9902Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M65.3105 88.8301L121.071 126.38C122.551 127.38 124.971 127.38 126.441 126.38L182.201 88.8301L123.761 131.29L65.3205 88.8301H65.3105Z" fill="#5098D6"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M123.75 154.2L195.73 98.5999C200.77 94.8699 201.85 94.0599 199.59 86.9099L184.57 33.8599L204.59 95.4799L123.75 154.21V154.2Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M88.0293 111.43L123.759 137.38L171.999 102.34L123.759 139.59L88.0293 111.43Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M121.039 0.429932H173.719L184.569 33.8499L171.709 2.27997L121.039 0.429932Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M142.01 0.429932C141.57 4.75993 143.46 9.60997 147.11 14.03H163.84L167.98 26.7599C173.22 28.0399 178.24 28.09 182.34 26.96L173.72 0.429932H142.01Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M83.6901 108.27C82.4201 111.52 84.7201 116.38 89.3201 119.72C93.9201 123.06 99.2401 123.74 101.95 121.53L83.7001 108.27H83.6901Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M102.059 130.42C101.289 131.6 99.1595 131.58 97.2995 130.37C95.4395 129.16 94.5595 127.22 95.3295 126.03C96.0995 124.85 98.2295 124.87 100.089 126.08C101.949 127.29 102.829 129.23 102.059 130.42Z" fill="#FEF3BD"/>
    </g>
    <path d="M65.92 1.09003L62.86 26.38C55.62 26.34 49.57 26.31 38.7 26.32C0.200001 26.38 0 0.969971 0 0.969971L65.92 1.09003Z" fill="#1C79C9"/>
    <path d="M65.92 1.09003L59.42 20.85C52.18 20.81 50.99 20.78 40.12 20.79C1.62 20.84 0 0.969971 0 0.969971L65.92 1.09003Z" fill="url(#paint5_radial_999_6356)"/>
    <path d="M56.1596 31.13L53.1496 56.43C45.9096 56.39 54.7096 56.43 43.8396 56.43C5.33961 56.48 5.09961 31.01 5.09961 31.01L56.1596 31.13Z" fill="#1C79C9"/>
    <path d="M56.1596 31.13L49.7296 50.93C42.4896 50.89 56.1596 50.86 45.2896 50.87C6.78961 50.92 5.09961 31 5.09961 31L56.1596 31.12V31.13Z" fill="url(#paint6_radial_999_6356)"/>
    <path d="M46.4091 61.1598L19.2891 61.0498C19.2891 61.0498 23.5491 80.0998 43.3891 86.4698L46.4091 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M46.4091 61.1598L19.2891 61.0498C19.2891 61.0498 20.1291 74.5998 39.9691 80.9698L46.4091 61.1598Z" fill="url(#paint7_radial_999_6356)"/>
    <path d="M65.9199 1.08984L69.1699 7.36981L62.8599 26.3898L59.4199 20.8498L65.9199 1.08984Z" fill="#3386CF"/>
    <path d="M56.1605 31.1299L59.4205 37.3399L53.1505 56.4299L49.7305 50.9299L56.1605 31.1299Z" fill="#3386CF"/>
    <path d="M46.4001 61.1599L49.6501 67.3699L40.2301 96.0699L34.3301 97.8799L46.4001 61.1599Z" fill="#3386CF"/>
    <path d="M181.609 1.08997L184.669 26.3799C191.909 26.3399 197.959 26.3099 208.829 26.3199C247.329 26.3699 247.529 0.959961 247.529 0.959961L181.609 1.07996V1.08997Z" fill="#1C79C9"/>
    <path d="M181.609 1.09003L188.109 20.85C195.349 20.81 196.539 20.78 207.409 20.79C245.909 20.84 247.529 0.969971 247.529 0.969971L181.609 1.09003Z" fill="url(#paint8_radial_999_6356)"/>
    <path d="M191.359 31.13L194.369 56.43C201.609 56.39 192.809 56.43 203.679 56.43C242.179 56.48 242.419 31.01 242.419 31.01L191.359 31.13Z" fill="#1C79C9"/>
    <path d="M191.359 31.13L197.789 50.93C205.029 50.89 191.359 50.86 202.229 50.87C240.729 50.92 242.419 31 242.419 31L191.359 31.12V31.13Z" fill="url(#paint9_radial_999_6356)"/>
    <path d="M201.119 61.1598L228.239 61.0498C228.239 61.0498 223.979 80.0998 204.139 86.4698L201.119 61.1698V61.1598Z" fill="#1C79C9"/>
    <path d="M201.119 61.1598L228.239 61.0498C228.239 61.0498 227.399 74.5998 207.559 80.9698L201.119 61.1598Z" fill="url(#paint10_radial_999_6356)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M181.609 1.09003L188.109 20.85C195.349 20.81 196.539 20.78 207.409 20.79C245.909 20.84 247.529 0.969971 247.529 0.969971L181.609 1.09003Z" fill="url(#paint11_radial_999_6356)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M191.359 31.13L197.789 50.93C205.029 50.89 191.359 50.86 202.229 50.87C240.729 50.92 242.419 31 242.419 31L191.359 31.12V31.13Z" fill="url(#paint12_radial_999_6356)"/>
    <path d="M181.609 1.08984L178.359 7.36981L184.669 26.3898L188.109 20.8498L181.609 1.08984Z" fill="#3386CF"/>
    <path d="M191.359 31.1299L188.109 37.3399L194.379 56.4299L197.799 50.9299L191.359 31.1299Z" fill="#3386CF"/>
    <path d="M201.131 61.1599L197.881 67.3699L207.331 96.1899L213.181 97.8799L201.131 61.1599Z" fill="#3386CF"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M65.92 1.09003L0 0.969971C0 0.969971 1.62 20.84 40.12 20.79C50.99 20.78 52.18 20.81 59.42 20.85L65.92 1.09003Z" fill="url(#paint13_radial_999_6356)"/>
    <path style={{mixBlendMode: 'screen'}} d="M49.7295 50.93L53.5195 39.25C48.3595 40.94 43.4595 43.16 38.9595 45.91C37.0995 47.05 34.9095 48.46 32.5195 50.03C36.2495 50.57 40.4795 50.87 45.2795 50.87C56.1495 50.86 42.4795 50.89 49.7195 50.93H49.7295Z" fill="url(#paint14_radial_999_6356)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M55.8105 55.78L72.6905 7.36993C74.4305 2.44993 75.7005 1.15991 80.9205 0.96991L123.761 0.419922H73.8005L55.8205 55.77L55.8105 55.78Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M138.849 7.98979C135.359 5.44979 131.149 5.28981 129.449 7.62981C128.229 9.30981 128.639 11.8098 130.249 14.0298H142.769C142.569 11.9798 141.159 9.67979 138.839 7.98979H138.849Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M196.529 19.3499C192.139 18.9099 189.079 17.3199 187.079 13.3899L181.609 1.09985L188.109 20.8599C195.349 20.8199 196.539 20.7899 207.409 20.7999C213.539 20.7999 218.739 20.3099 223.139 19.4599C213.599 20.6099 203.799 20.0699 196.529 19.3499Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M202.239 50.8799C211.549 50.8899 218.709 49.7298 224.209 47.9698C216.299 50.1698 209.649 50.0099 204.599 49.7099C201.119 49.4999 198.289 46.9399 196.819 43.7799L191.369 31.1299L197.799 50.9299C205.039 50.8899 191.369 50.8599 202.239 50.8699V50.8799Z" fill="#4C95D5"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M207.561 80.96C211.861 79.58 215.261 77.86 217.961 76.02C214.771 77.24 208.771 78.1099 208.771 78.1099L201.131 61.1599V61.1799L207.561 80.96Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.12 20.8C50.99 20.79 52.18 20.82 59.42 20.86L64.57 5.20001L60.74 13.75C58.9 18.25 57.81 19.74 53.32 19.78C48.47 19.82 39.34 19.72 34.7 19.54C14.49 18.75 2.58 10.01 0 0.98999C0 0.98999 1.62 20.86 40.12 20.81V20.8Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M50.5897 45.0898C49.3697 47.6498 48.0497 49.7998 45.2097 49.6998C42.2797 49.5998 34.0297 49.5398 29.3197 48.4798C18.3897 46.0098 11.8597 41.9398 7.42969 36.9498C11.1497 42.8098 20.7297 50.9098 45.2897 50.8798C56.1597 50.8698 42.4897 50.8998 49.7297 50.9398L55.0497 34.5498L50.5797 45.0998L50.5897 45.0898Z" fill="#4C95D5"/>
    </g>
    <path d="M39.9691 80.9598L45.5891 63.6898C44.5391 65.8898 42.4491 70.9898 40.3191 75.7098C38.8791 78.9198 37.4891 78.5198 35.2591 77.5898C24.1791 72.9798 21.6291 67.4898 19.2891 61.0498C19.2891 61.0498 20.1291 74.5998 39.9691 80.9698V80.9598Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M181.61 1.09003L241.89 2.12C244.31 2.16 245.44 3.97997 244.39 6.15997C243.3 8.43997 241.53 11.16 238.64 13.51C246.96 7.85001 247.52 0.969971 247.52 0.969971L181.6 1.09003H181.61Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M191.359 31.13L235.829 32.18C241.029 32.29 240.179 34.5 238.829 37.2C237.999 38.87 236.849 40.69 235.339 42.21C241.919 36.86 242.419 31.02 242.419 31.02L191.359 31.14V31.13Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.5195 0.969971L65.9195 1.09003L64.2895 6.03998C64.2895 3.50998 63.0895 2.22 60.1695 2.19L17.5195 0.969971Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.5195 31.1299H56.1595L54.3795 36.6099L54.5695 34.7899C54.6695 33.0699 54.0595 32.1699 50.9295 32.0199L17.5195 31.1299Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M137.119 58.8599L143.809 47.28V41.7399L130.439 47.28L123.749 35.71L117.069 47.28L103.699 41.7399V47.28L110.389 58.8599L103.699 64.9V70.4399H117.069L123.749 82.0099L130.439 70.4399H143.809V64.9L137.119 58.8599Z" fill="#5098D6"/>
    </g>
    <path d="M137.119 53.3199L143.809 41.7399V37.4099L130.439 41.7399L123.749 30.1599L117.069 41.7399L103.699 37.4099V41.7399L110.389 53.3199L103.699 60.5699V64.9H117.069L123.749 76.4699L130.439 64.9H143.809V60.5699L137.119 53.3199Z" fill="#3386CF"/>
    <path d="M137.119 48.9901L143.809 37.41H130.439L123.749 25.8301L117.069 37.41H103.699L110.389 48.9901L103.699 60.5701H117.069L123.749 72.1401L130.439 60.5701H143.809L137.119 48.9901Z" fill="url(#paint15_radial_999_6356)"/>
    <path d="M143.809 60.5698H130.439V64.8998H143.809V60.5698Z" fill="#1C79C9"/>
    <path d="M117.069 64.8998L123.749 76.4698V72.1398L117.069 60.5698H103.699V64.8998H117.069Z" fill="#1C79C9"/>
    <path d="M103.699 37.4099L110.389 48.9899L109.139 51.15L103.699 41.7399V37.4099Z" fill="#1C79C9"/>
    <path style={{mixBlendMode: 'screen'}} d="M117.069 60.57L123.749 72.15L130.429 60.57H138.009C128.439 51.88 117.339 45.28 105.779 41.02L110.379 48.99L103.699 60.57H117.069Z" fill="url(#paint16_radial_999_6356)"/>
    <path d="M103.699 37.4099L112.839 48.9899L103.699 60.5699L110.389 48.9899L103.699 37.4099Z" fill="#3386CF"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M123.76 25.8301L118.25 35.3801C125.07 38.8201 131.71 42.8601 138.03 47.4301L143.81 37.41H130.44L123.76 25.8301Z" fill="url(#paint17_radial_999_6356)"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M124.44 54.86C123.73 57.11 120.37 58.0599 116.93 56.9699C113.49 55.8899 111.28 53.18 111.99 50.93C112.7 48.68 116.06 47.73 119.5 48.82C122.94 49.91 125.15 52.61 124.44 54.86Z" fill="#FCE265"/>
    </g>
    <path d="M123.75 72.1399L129.58 57.8799L143.81 60.5699H130.44L123.75 72.1399Z" fill="#3386CF"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M125.271 60.4898C125.151 61.4698 123.831 62.1098 122.331 61.9198C120.831 61.7298 119.721 60.7798 119.841 59.7998C119.961 58.8198 121.281 58.1798 122.781 58.3698C124.281 58.5598 125.391 59.5098 125.271 60.4898Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M103.699 60.5698L118.329 59.2998L123.749 72.1398L117.069 60.5698H103.699Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M188.109 20.85C193.349 20.82 195.419 20.8 200.289 20.79C207.109 16.51 211.659 10.16 212.299 2.76001C212.349 2.18001 212.369 1.61003 212.369 1.03003L181.609 1.09003L188.109 20.85Z" fill="#FEF3BD"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.5">
        <path d="M123.41 104.91L78.4199 81.5698V87.5898L123.41 120.27L169.09 87.0898V81.0698L123.41 104.91Z" fill="#5098D6"/>
    </g>
    <path d="M123.41 98.9001L78.4199 74.55V81.5701L123.41 114.26L169.09 81.0701V74.0601L123.41 98.9001Z" fill="#3386CF"/>
    <path d="M123.41 91.8799L82.4399 62.1899L78.4199 74.5499L123.41 107.24L169.09 74.0599L165.07 61.6899L123.41 91.8799Z" fill="url(#paint18_radial_999_6356)"/>
    <path d="M220.74 73.8798C227.82 67.6998 228.24 61.0498 228.24 61.0498L201.12 61.1598L213.19 97.8798L126.83 160.63V170.61L236.58 109.51L220.74 73.8798Z" fill="url(#paint19_radial_999_6356)"/>
    <path d="M34.3295 97.8798L46.4095 61.1598L19.2895 61.0498C19.2895 61.0498 19.7095 67.6998 26.7795 73.8698L10.9395 109.51L120.689 170.61V160.63L34.3295 97.8798Z" fill="url(#paint20_radial_999_6356)"/>
    <path style={{mixBlendMode: 'screen'}} d="M34.3295 97.8798L46.4095 61.1598L19.2895 61.0498C19.2895 61.0498 19.7095 67.6998 26.7795 73.8698L10.9395 109.51L120.689 170.61V160.63L34.3295 97.8798Z" fill="url(#paint21_radial_999_6356)"/>
    <path d="M40.2301 96.0698L120.69 157.26V160.62L34.3301 97.8798L40.2301 96.0698Z" fill="url(#paint22_linear_999_6356)"/>
    <path d="M207.33 96.1899L126.82 157.26V160.62L213.18 97.8799L207.33 96.1899Z" fill="url(#paint23_linear_999_6356)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M220.74 73.8798C227.82 67.6998 228.24 61.0498 228.24 61.0498L201.12 61.1598L213.19 97.8798L187.48 116.56C187.75 117.59 188.01 118.62 188.25 119.66C189.64 125.16 190.83 130.07 191.84 134.41L236.58 109.5L220.74 73.8698V73.8798Z" fill="url(#paint24_radial_999_6356)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.63 139.12C168.87 136.03 168.95 133 168.93 130.03L126.82 160.62V170.6L166.77 148.36C167.73 145.53 168.38 142.46 168.63 139.12Z" fill="url(#paint25_radial_999_6356)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M159.08 66.03C161.89 69.06 164.57 72.2299 167.08 75.5099L169.09 74.0499L165.07 61.6799L159.08 66.02V66.03Z" fill="url(#paint26_radial_999_6356)"/>
    <path style={{mixBlendMode: 'screen'}} d="M156.41 83.27C154.32 79.65 152 76.2199 149.5 72.9799L123.42 91.8799L82.4497 62.1899L78.4297 74.5599L123.42 107.25L156.42 83.28L156.41 83.27Z" fill="url(#paint27_radial_999_6356)"/>
    <path d="M78.4199 74.5498L123.41 107.24V114.26L78.4199 81.5698V74.5498Z" fill="#1C79C9"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M97.1309 72.8299L123.411 94.9099L155.471 68.6499L123.411 91.8799L97.1309 72.8299Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M82.5005 62.1599L81.2105 70.3199C81.0405 72.0299 81.3805 74.11 82.7105 75.2L123.41 107.24L78.4805 74.53L82.5005 62.1599Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M146.67 90.45L164.7 74.7399C166.37 73.3099 166.99 71.7399 166.78 69.5599L165.87 64.1799L169.08 74.0699L146.66 90.46L146.67 90.45Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M26.7795 73.8701L10.9395 109.51L67.9595 141.26C67.9595 141.26 29.0195 118.01 16.4895 110.07C14.2695 108.66 13.4995 107.45 14.5495 105.04C18.2795 96.47 26.6895 76.16 28.2795 73.1C23.2195 69.19 21.1395 65.51 19.2895 61.04C19.2895 61.04 19.7095 67.69 26.7795 73.86V73.8701Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M24.51 61.0601L46.41 61.1501L37.92 87.1401L44.64 63.8901C44.58 62.5701 44.22 62.1101 42.41 61.9901L24.5 61.0601H24.51Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M223.581 71.0001C227.951 65.8001 228.241 61.04 228.241 61.04L201.161 61.1501H201.131L223.521 62.1C226.831 62.3 226.231 63.5501 225.841 65.1501C225.401 67.0001 224.661 69.1801 223.581 70.9901V71.0001Z" fill="#C9DFF2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M183.57 139.02L225.35 113.76C232.81 109.27 233.48 108.48 230.27 100.3L223.25 79.52L236.58 109.51L183.57 139.02Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M126.82 160.62C126.82 160.62 127.09 162.02 127.27 163.1C128.09 167.88 129.37 167.64 132.16 166.31L147.88 158.88L126.82 170.6V160.62Z" fill="#4C95D5"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.4492 148.76L115.739 166.12C119.239 168.04 119.669 167.05 120.369 162.98C120.449 162.52 120.689 160.9 120.689 160.9V170.61L81.4492 148.76Z" fill="#4C95D5"/>
    </g>
    <path style={{mixBlendMode: 'soft-light'}} d="M5.09961 31.01C5.09961 31.01 5.37961 34.23 8.20961 38.08C12.4096 35.7 17.0996 33.27 22.0896 31.05L5.09961 31.01Z" fill="url(#paint28_radial_999_6356)"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M123.75 25.8301L130.2 38.55L143.81 37.41H130.44L123.75 25.8301Z" fill="#C9DFF2"/>
    </g>
    <path d="M149.6 119.5C149.6 119.5 153.12 112.94 158.59 109.17C161.86 106.91 168.17 105.14 168.17 105.14C168.17 105.14 162.29 112.53 157.95 115.99C153.61 119.45 149.61 119.49 149.61 119.49L149.6 119.5Z" fill="url(#paint29_linear_999_6356)"/>
    <path d="M164.37 107.61C159.54 107.89 155.67 111.75 153.57 114.47C151.47 117.18 152.66 118.09 155.32 116.91C157.97 115.73 166.41 107.5 164.37 107.62V107.61Z" fill="#3A8AD0"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M164.369 107.61C161.099 107.58 156.989 110.67 155.709 112.05C154.419 113.43 156.989 115.55 158.739 114.43C160.489 113.31 165.859 107.63 164.369 107.61Z" fill="url(#paint30_linear_999_6356)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.11993) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.11993) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.75007) scale(143.68 143.68)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.0997 138.74) scale(93.68 93.68)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#1E7ACA"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_6356" x1="123.75" y1="77.5901" x2="123.75" y2="104.38" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.11997) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.12001) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.11985) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.11997) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint9_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.12001) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint10_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.11985) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.959 -7.75003) scale(143.68 143.68)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.959 -7.74999) scale(143.68 143.68)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.75004) scale(143.69 143.69)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.6995 130.81) scale(116.67 116.67)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.12007) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.6992 130.81) scale(116.67 116.67)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.74988) scale(143.69 143.69)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint18_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.11994) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint19_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.46 8.11981) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint20_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(155.459 8.11981) scale(178.65 178.65)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.51" stop-color="#5098D6"/>
            <stop offset="0.92" stop-color="#9DC5E8"/>
        </radialGradient>
        <radialGradient id="paint21_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.6995 130.81) scale(116.67 116.67)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <linearGradient id="paint22_linear_999_6356" x1="34.3301" y1="128.34" x2="120.69" y2="128.34" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <linearGradient id="paint23_linear_999_6356" x1="213.18" y1="128.4" x2="126.82" y2="128.4" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#7BB1E0"/>
            <stop offset="1" stop-color="#BFD9F0"/>
        </linearGradient>
        <radialGradient id="paint24_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.75019) scale(143.68 143.68)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint25_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.7003 130.81) scale(116.67 116.67)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint26_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.75016) scale(143.68 143.68)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <radialGradient id="paint27_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(49.6997 130.81) scale(116.67)">
            <stop offset="0.32" stop-color="#77AEDF"/>
            <stop offset="0.76" stop-color="#237DCB"/>
        </radialGradient>
        <radialGradient id="paint28_radial_999_6356" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.96 -7.75003) scale(143.69 143.69)">
            <stop stop-color="#B0CEEA"/>
            <stop offset="0.18" stop-color="#AACBE8"/>
            <stop offset="0.41" stop-color="#9BC2E5"/>
            <stop offset="0.65" stop-color="#83B4E0"/>
            <stop offset="0.91" stop-color="#61A1D9"/>
            <stop offset="1" stop-color="#5399D6"/>
        </radialGradient>
        <linearGradient id="paint29_linear_999_6356" x1="152.15" y1="116.19" x2="164.95" y2="108.83" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D8E8F6"/>
            <stop offset="0.99" stop-color="#72ACDE"/>
        </linearGradient>
        <linearGradient id="paint30_linear_999_6356" x1="160.653" y1="114.149" x2="159.691" y2="108.125" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#559AD7"/>
            <stop offset="1" stop-color="#2C82CD"/>
        </linearGradient>
    </defs>
</svg>
)