import {useNavigate} from 'react-router-dom';

const navigate = path => {
    if(path != window.location.pathname)
        window.dispatchEvent(new CustomEvent('location.change', {detail: path}));
}

const NavigationLink = ({path, children}) => {
    return (
        <div onClick={() => navigate(path)}>
            {children}
        </div>
    )
}

export {
    NavigationLink,
    navigate
}

export default {
    navigate
}