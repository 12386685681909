import {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals/addcash';
import {updateBalance, addCash} from '../../reducers/games/actions';
import Numeric from '../components/numeric';
import BlueButton from '../components/blue-button';
import {NumericFormat} from 'react-number-format';
import ModalsLib from '../../lib/modals';
import {getCashoutsRange} from '../../utils/sessions';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default () => {
    const {user, game, login, data, buttons, sessions} = useSelector(Selector),
          dispatch = useDispatch(),
          inputRef = useRef(),
          [state, setState] = useState({
            amount: data.amount
          });

    const modalCloseHandler = () => {
        console.log(`MODAL CLOSE`);
    }

    const setAmount = amount => {
        setState({
            ...state,
            amount
        });
    }

    useEffect(() => {
        document.addEventListener('modal.close', modalCloseHandler);

        tippy('#cashoutRulesInfo', {
            placement: "top",
            animation: 'fade',
            theme: 'translucent',
            arrow: true,
            content: "<b>Play Big - Win Big</b> <br/> Hello world",
            allowHTML: true
        });

        return () => document.removeEventListener('modal.close', modalCloseHandler);
    }, []);

    if(!login || login.status === 0)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="title">Add Cash</div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                    <div className="details">
                        <div className="top">
                            <div className="name">{game.name}</div>
                            <div className="change">change</div>
                        </div>
                        <div className="bottom">
                            <div className="detail">
                                <div className="title">Login</div>
                                <div className="value">{login.login}</div>
                            </div>

                            <div className="balance">
                                <div className="detail">
                                    <div className="title">Balance</div>
                                    <div className="value">
                                        <Numeric value={login.balance} />
                                    </div>
                                </div>

                                <svg className={`${login.balance_details.need_update ? 'updating' : 'noupdating'}`} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={e => {
                                    if(buttons.update)
                                        return;

                                    if(login.balance_details.need_update) {
                                        toast.dismiss();
                                        toast.info(`Your balance is already being updated...`);
                                        return;
                                    }

                                    dispatch(updateBalance(login.game));
                                }}>
                                    <path d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 2.59199 13.7 2.59199 13.7M2.59199 13.7H6.35866M2.59199 13.7V17.8666M1.66699 9.99996C1.66699 5.39996 5.36699 1.66663 10.0003 1.66663C15.5587 1.66663 18.3337 6.29996 18.3337 6.29996M18.3337 6.29996V2.13329M18.3337 6.29996H14.6337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                {login.balance < 2 ? (
                    <>
                        <div className="amount-block">
                            <div className="label">Amount</div>
                            <div className="input">
                                <NumericFormat  
                                    displayType='input'
                                    type='tel'
                                    value={state.amount === 0 ? '' : state.amount}
                                    placeholder='$0.00'
                                    prefix={'$'} 
                                    decimalSeparator="." 
                                    decimalScale={0} 
                                    thousandSeparator=" "
                                    maxLength={8}
                                    getInputRef={inputRef}
                                    onValueChange={(values) => {
                                        setState({
                                            ...state,
                                            amount: values.floatValue
                                        })
                                    }} />

                                <div className="clear" onClick={e => setAmount(0)}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>

                            <div className="buttons">
                                <div className="amounts">
                                    <div className={`amount ${state.amount === 25 ? 'active' : ''}`} onClick={e => setAmount(25)}>$25</div>
                                    <div className={`amount hot ${state.amount === 50 ? 'active' : ''}`} onClick={e => setAmount(50)}>
                                        <div className="hot">
                                            <span>HOT</span>
                                        </div>
                                        $50
                                    </div>
                                    <div className={`amount ${state.amount === 75 ? 'active' : ''}`} onClick={e => setAmount(75)}>$75</div>
                                    <div className={`amount ${state.amount === 100 ? 'active' : ''}`} onClick={e => setAmount(100)}>$100</div>
                                </div>
                                <div className="cashouts" id="cashoutRulesInfo">
                                    <div className="rules">
                                        Cashouts range
                                    </div>
                                    <div className="value">
                                        <span>{getCashoutsRange({type: 'cash', amount: state.amount, loginBalance: login.balance, sessions})}</span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-tip data-for="cashoutRules">
                                            <rect width="20" height="20" rx="10" fill="#30344F"/>
                                            <path d="M11.7697 12.8984C11.5994 12.9584 11.0599 13.1013 10.8139 12.9741C10.7799 12.9562 10.7513 12.9312 10.7304 12.9011C10.7095 12.8709 10.697 12.8367 10.694 12.8012C10.6167 12.3554 10.9842 11.3252 11.183 10.7723C11.2492 10.588 11.2965 10.4537 11.3154 10.3722C11.459 9.77067 11.388 9.31059 11.1041 9.00482C10.7624 8.6369 10.1564 8.56532 9.65928 8.6319C9.15374 8.69294 8.65972 8.81599 8.19081 8.99768C8.16903 9.00723 8.14999 9.02124 8.13521 9.0386C8.12043 9.05595 8.1103 9.07619 8.10563 9.0977L8.00311 9.54206C7.99756 9.56705 7.99946 9.59293 8.00861 9.61704C8.01776 9.64114 8.03382 9.66261 8.05516 9.67923C8.07676 9.69549 8.10264 9.7064 8.13028 9.71091C8.15793 9.71542 8.18638 9.71336 8.21289 9.70495C8.40059 9.64208 8.98735 9.50063 9.19713 9.67494C9.31543 9.77353 9.2823 9.98214 9.23498 10.1379C9.18609 10.3008 9.12773 10.4765 9.06779 10.6608C8.67977 11.8568 8.23813 13.2127 8.93845 13.6356C9.26979 13.8648 9.67309 13.9924 10.0899 14C10.5 14 11.0063 13.8828 11.735 13.6156C11.757 13.6079 11.7767 13.5958 11.7928 13.5802C11.8089 13.5646 11.821 13.546 11.8281 13.5256L11.9779 13.0727C11.9864 13.0469 11.9868 13.0194 11.979 12.9934C11.9712 12.9674 11.9555 12.9439 11.9338 12.9255C11.912 12.9075 11.8851 12.8954 11.856 12.8906C11.827 12.8858 11.7971 12.8885 11.7697 12.8984ZM10.858 6C10.6324 6.00014 10.4119 6.06087 10.2243 6.17452C10.0368 6.28816 9.89063 6.44962 9.80436 6.63848C9.71808 6.82735 9.69555 7.03514 9.7396 7.23561C9.78366 7.43607 9.89233 7.6202 10.0519 7.76473C10.2114 7.90926 10.4147 8.0077 10.636 8.04761C10.8573 8.08752 11.0867 8.06711 11.2952 7.98896C11.5037 7.9108 11.6819 7.77842 11.8073 7.60853C11.9328 7.43863 11.9998 7.23887 12 7.03447C11.9999 6.76014 11.8796 6.49706 11.6654 6.30307C11.4513 6.10909 11.1609 6.00008 10.858 6Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {state.amount < 15 ? (
                            <div className="modal-message warning" style={{alignItems: 'center'}} onClick={e => setState({
                                ...state,
                                amount: 15
                            })}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>The minimum deposit amount in the game is <b>$15</b></span>
                            </div>
                        ) : (
                            <>
                                {state.amount > user.balance && (
                                    <div className="modal-message warning" onClick={e => {
                                        ModalsLib.close();
                                        window.alert(`Открываем модалку кошелька с вкладкой депозит`)
                                    }}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <span><b>Not enough funds!</b> Click on this message to deposit <b><Numeric value={state.amount - user.balance} /></b></span>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="modal-message warning">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 7.75V13" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#DA6C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16.2V16.2999" stroke="#DA6C0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>You cannot make a deposit into this game because <b>your game balance is more than $2</b>.</span>
                        </div>

                        <div className="modal-message info">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Lower your game balance below <b>$2</b> to make a deposit into this game. <b>If the balance is invalid, update it</b></span>
                        </div>
                    </>
                )}
            </div>

            <div className="modal-footer">
                {login.balance < 2 ? (
                    <BlueButton active={buttons.add} onClick={e => {
                        if(!buttons.add)
                            dispatch(addCash({
                                amount: state.amount,
                                game: login.game
                            }));
                    }}>
                        Add Cash
                    </BlueButton>
                ) : (
                    <BlueButton onClick={e => {}}>
                        Change game
                    </BlueButton>
                )}
                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}