import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/components/sidebar';
import {toggleSidebar} from '../../../reducers/app/actions';
import VIPLevels from '../../../views/pages/vip/levels/index';
import {navigate} from '../../../utils/navigation';

export default () => {
    const {isMobile, sidebar, user} = useSelector(Selector),
          [closing, setClosing] = useState(false),
          dispatch = useDispatch();

    const overlay_ref = useRef(),
          sidebar_ref = useRef();

    const close = () => {
        if(closing)
            return;

        setClosing(true);

        if(overlay_ref.current) {
            overlay_ref.current.classList.remove('animate__fadeIn');
            overlay_ref.current.classList.add('animate__fadeOut');
        }

        if(sidebar_ref.current) {
            sidebar_ref.current.classList.remove('animate__fadeInLeft');
            sidebar_ref.current.classList.add('animate__fadeOutLeft');
        }

        setTimeout(() => {
            dispatch(toggleSidebar());
        }, 500);
    }


    const closeHandler = e => {
        if(closing)
            return;

        if(e.target === overlay_ref.current)
            close();
    }

    useEffect(() => {
        if(sidebar)
            setClosing(false);
    }, [sidebar]);

    if(!['tablet', 'mobile'].includes(isMobile))
        return <></>;

    if(!sidebar)
        return <></>;

    return (
        <div className="sidebar-overlay animate__animated animate__fadeIn animate__faster" ref={overlay_ref} onClick={closeHandler}>
            <div className="sidebar animate__animated animate__fadeInLeft animate__faster" ref={sidebar_ref}>
                <div className="sidebar-header">
                    <div className="logotype">Lucksy</div>
                    <div className="close">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.2">
                                <path d="M6 18L18 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18 18L6 6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg>
                    </div>
                </div>

                <div className="sidebar-profile">
                    <div className="profile-info">
                        <div className="user-info">
                            <div className="avatar">
                                <img src={`https://lucksy.net/logo192.png`} alt="" />
                            </div>
                            <div className="names">
                                <div className="name">Senor</div>
                                <div className="username">zusunit</div>
                            </div>
                        </div>
                        <div className="arrow">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.71203 14.9401L11.602 10.0501C12.1795 9.47256 12.1795 8.52756 11.602 7.95006L6.71203 3.06006" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <hr />

                    <div className="balance">
                        <div className="balance-info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#44B1F9"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6192 8C15.6054 8 16.588 8 17.5742 8C17.5162 8.62505 17.4546 9.2535 17.4002 9.87856C18.5496 9.95669 19.6699 10.3032 20.6779 10.8161C20.2428 11.842 19.8041 12.8679 19.3654 13.8938C18.5169 13.2178 17.4002 12.8442 16.287 12.8577C16.0078 12.8645 15.7178 12.9155 15.4857 13.065C15.3334 13.1635 15.221 13.3163 15.1884 13.4862C15.163 13.6221 15.1703 13.7749 15.25 13.8972C15.3407 14.0331 15.5002 14.1146 15.6598 14.1656C15.9861 14.2743 16.3378 14.2811 16.6786 14.3151C17.4799 14.4068 18.2849 14.5359 19.0427 14.8042C19.6301 15.0149 20.1921 15.3308 20.5945 15.7928C20.9752 16.2174 21.1964 16.7541 21.2798 17.3045C21.3777 17.9397 21.3487 18.5987 21.1384 19.217C20.9752 19.713 20.696 20.1749 20.3335 20.5656C19.5829 21.3809 18.4807 21.8701 17.3494 21.9958C17.3857 22.665 17.4292 23.3308 17.4691 24C16.5336 24 15.6018 24 14.6699 24C14.7062 23.3376 14.7533 22.6752 14.7859 22.0127C14.0354 21.989 13.2921 21.8327 12.5923 21.5847C11.9143 21.3435 11.2689 21.0242 10.667 20.6505C11.1093 19.5839 11.5517 18.5172 11.9977 17.4471C12.5017 17.8446 13.0383 18.2149 13.6257 18.4968C14.2566 18.7992 14.9564 18.9928 15.667 18.9996C16.0042 19.003 16.3523 18.986 16.6714 18.8773C16.8672 18.8093 17.0593 18.6904 17.1464 18.5036C17.237 18.3066 17.2298 18.0586 17.092 17.882C16.9941 17.7631 16.8563 17.6815 16.7113 17.6238C16.5155 17.549 16.3124 17.5083 16.1057 17.4777C15.3443 17.3622 14.5793 17.2671 13.8432 17.0429C13.2522 16.8628 12.6757 16.6115 12.2043 16.2242C11.907 15.983 11.6605 15.6875 11.4864 15.3546C11.2254 14.8586 11.1275 14.2981 11.1347 13.7478C11.1347 13.1329 11.2689 12.5079 11.5952 11.9677C11.878 11.4854 12.3095 11.0913 12.7954 10.789C13.3827 10.4255 14.039 10.1775 14.7062 9.97707C14.6772 9.31805 14.6482 8.65902 14.6192 8Z" fill="#45B1FA"/>
                            </svg>

                            <div className="balance-details">
                                <span>Balance</span>
                                5 421.00
                            </div>
                        </div>

                        <div className="wallet-button">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.66699 7.43335H4.66699" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1.33301 7.43331V4.35331C1.33301 2.99331 2.43301 1.89331 3.79301 1.89331H7.53967C8.89967 1.89331 9.99967 2.73998 9.99967 4.09998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M11.653 8.13342C11.3197 8.45342 11.1597 8.94674 11.293 9.45341C11.4597 10.0734 12.073 10.4668 12.713 10.4668H13.333V11.4334C13.333 12.9068 12.1397 14.1001 10.6663 14.1001H3.99967C2.52634 14.1001 1.33301 12.9068 1.33301 11.4334V6.76676C1.33301 5.29343 2.52634 4.1001 3.99967 4.1001H10.6663C12.133 4.1001 13.333 5.3001 13.333 6.76676V7.7334H12.613C12.2397 7.7334 11.8997 7.88009 11.653 8.13342Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.6665 8.41341V9.78674C14.6665 10.1601 14.3598 10.4668 13.9798 10.4668H12.6931C11.9731 10.4668 11.3132 9.94009 11.2532 9.22009C11.2132 8.80009 11.3731 8.40676 11.6531 8.13342C11.8998 7.88009 12.2398 7.7334 12.6131 7.7334H13.9798C14.3598 7.7334 14.6665 8.04008 14.6665 8.41341Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div className="balance wager">
                        <div className="balance-info">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F99637"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6192 8C15.6054 8 16.588 8 17.5742 8C17.5162 8.62505 17.4546 9.2535 17.4002 9.87856C18.5496 9.95669 19.6699 10.3032 20.6779 10.8161C20.2428 11.842 19.8041 12.8679 19.3654 13.8938C18.5169 13.2178 17.4002 12.8442 16.287 12.8577C16.0078 12.8645 15.7178 12.9155 15.4857 13.065C15.3334 13.1635 15.221 13.3163 15.1884 13.4862C15.163 13.6221 15.1703 13.7749 15.25 13.8972C15.3407 14.0331 15.5002 14.1146 15.6598 14.1656C15.9861 14.2743 16.3378 14.2811 16.6786 14.3151C17.4799 14.4068 18.2849 14.5359 19.0427 14.8042C19.6301 15.0149 20.1921 15.3308 20.5945 15.7928C20.9752 16.2174 21.1964 16.7541 21.2798 17.3045C21.3777 17.9397 21.3487 18.5987 21.1384 19.217C20.9752 19.713 20.696 20.1749 20.3335 20.5656C19.5829 21.3809 18.4807 21.8701 17.3494 21.9958C17.3857 22.665 17.4292 23.3308 17.4691 24C16.5336 24 15.6018 24 14.6699 24C14.7062 23.3376 14.7533 22.6752 14.7859 22.0127C14.0354 21.989 13.2921 21.8327 12.5923 21.5847C11.9143 21.3435 11.2689 21.0242 10.667 20.6505C11.1093 19.5839 11.5517 18.5172 11.9977 17.4471C12.5017 17.8446 13.0383 18.2149 13.6257 18.4968C14.2566 18.7992 14.9564 18.9928 15.667 18.9996C16.0042 19.003 16.3523 18.986 16.6714 18.8773C16.8672 18.8093 17.0593 18.6904 17.1464 18.5036C17.237 18.3066 17.2298 18.0586 17.092 17.882C16.9941 17.7631 16.8563 17.6815 16.7113 17.6238C16.5155 17.549 16.3124 17.5083 16.1057 17.4777C15.3443 17.3622 14.5793 17.2671 13.8432 17.0429C13.2522 16.8628 12.6757 16.6115 12.2043 16.2242C11.907 15.983 11.6605 15.6875 11.4864 15.3546C11.2254 14.8586 11.1275 14.2981 11.1347 13.7478C11.1347 13.1329 11.2689 12.5079 11.5952 11.9677C11.878 11.4854 12.3095 11.0913 12.7954 10.789C13.3827 10.4255 14.039 10.1775 14.7062 9.97707C14.6772 9.31805 14.6482 8.65902 14.6192 8Z" fill="#F99F3C"/>
                            </svg>

                            <div className="balance-details">
                                <span>Wager</span>
                                5 421.00
                            </div>
                        </div>

                        <div className="button">
                            <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_636_4011)">
                                    <path d="M5.10326 9.80348C4.90076 9.88223 4.25951 10.0697 3.96701 9.90285C3.92666 9.87941 3.8926 9.84651 3.86778 9.80699C3.84295 9.76747 3.82811 9.72251 3.82451 9.67598C3.73264 9.09098 4.16951 7.7391 4.40576 7.01348C4.48451 6.7716 4.54076 6.59535 4.56326 6.48848C4.73389 5.6991 4.64951 5.09535 4.31201 4.6941C3.90589 4.21129 3.18551 4.11735 2.59451 4.20473C1.99356 4.28482 1.4063 4.4463 0.84889 4.68473C0.822997 4.69726 0.800371 4.71564 0.7828 4.73842C0.765228 4.7612 0.753192 4.78775 0.747639 4.81598L0.625764 5.3991C0.619173 5.4319 0.621431 5.46585 0.632305 5.49749C0.643179 5.52912 0.662278 5.55729 0.687639 5.5791C0.713318 5.60044 0.744084 5.61476 0.776943 5.62068C0.809802 5.62659 0.843632 5.6239 0.875139 5.61285C1.09826 5.53035 1.79576 5.34473 2.04514 5.57348C2.18576 5.70285 2.14639 5.9766 2.09014 6.18098C2.03201 6.39473 1.96264 6.62535 1.89139 6.86723C1.43014 8.4366 0.905139 10.216 1.73764 10.771C2.13151 11.0717 2.61093 11.2392 3.10639 11.2491C3.59389 11.2491 4.19576 11.0954 5.06201 10.7447C5.08812 10.7346 5.11161 10.7187 5.13076 10.6982C5.1499 10.6778 5.16422 10.6533 5.17264 10.6266L5.35076 10.0322C5.36086 9.99834 5.36129 9.96231 5.35201 9.92819C5.34274 9.89407 5.32412 9.86322 5.29826 9.8391C5.2724 9.81542 5.24039 9.79952 5.20589 9.79322C5.1714 9.78691 5.13583 9.79047 5.10326 9.80348ZM4.01951 0.750977C3.75129 0.751162 3.48914 0.830857 3.2662 0.979989C3.04326 1.12912 2.86953 1.341 2.76697 1.58884C2.66441 1.83668 2.63763 2.10936 2.69 2.37242C2.74237 2.63548 2.87156 2.87711 3.06122 3.06677C3.25088 3.25644 3.49251 3.38562 3.75557 3.43799C4.01863 3.49036 4.29131 3.46358 4.53915 3.36102C4.78699 3.25846 4.99887 3.08473 5.148 2.86179C5.29713 2.63885 5.37683 2.3767 5.37701 2.10848C5.37692 1.74848 5.23386 1.40325 4.9793 1.14869C4.72474 0.89413 4.37952 0.751076 4.01951 0.750977Z" fill="#30344F"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_636_4011">
                                        <rect width="4.75494" height="10.4981" fill="white" transform="translate(0.62207 0.750977)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="sidebar-vip" onClick={e => {
                    close();
                    navigate('/vip');
                }}>
                    <div className="info">
                        <div className="icon">
                            <VIPLevels.Diamond1 />
                        </div>
                        <div className="details">
                            <div className="title">VIP Status</div>
                            <div className="name">Diamond I</div>
                        </div>
                    </div>
                    <div className="arrow">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.71203 14.9401L11.602 10.0501C12.1795 9.47256 12.1795 8.52756 11.602 7.95006L6.71203 3.06006" stroke="#30344F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>

                <div className="sidebar-navigation">
                    <div className="link active">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>

                    <div className="link">
                        <div className="active-margin">
                            <div className="active"></div>
                        </div>

                        <div className="navigation-link">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.02 2.83992L3.63 7.03992C2.73 7.73992 2 9.22992 2 10.3599V17.7699C2 20.0899 3.89 21.9899 6.21 21.9899H17.79C20.11 21.9899 22 20.0899 22 17.7799V10.4999C22 9.28992 21.19 7.73992 20.2 7.04992L14.02 2.71992C12.62 1.73992 10.37 1.78992 9.02 2.83992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 17.99V14.99" fill="none" stroke="#161C2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Home
                        </div>
                    </div>
                </div>

                <div className="sidebar-logout">
                    Logout
                </div>
            </div>
        </div>
    )
}