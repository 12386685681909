import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.isAuth,
    (isAuth) => {
        return {
            isAuth
        }
    }
)