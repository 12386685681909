import {createSelector} from 'reselect';
import props from '../../../../props';

export default createSelector(
    props.wallet.methods.crypto_deposit,
    (props) => {
        return {
            props
        }
    }
)