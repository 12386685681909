import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.app.initialized,
    props.user.loaded,
    props.games.loaded,
    (initialized, user, games) => {
        return {
            loaded: initialized && user && games,
            initialized,
            user,
            games
        }
    }
)