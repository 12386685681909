import {useEffect} from 'react';
import PageContainer from '../page-container';
import Components from '../../components';
import VIP from './vip';
import History from './history';
import FAQ from './faq';
import Steps from './steps';

export default () => {
    useEffect(() => {
        Components.Loader.close();
    }, []);
    
    return (
        <PageContainer>
            <div className="invite-page">
                <Components.Winners />

                <div className="invite-container">
                    <div className="invite-top">
                        <div className="layer"><div className="money"></div></div>
                        <div className="layer"><div className="money"></div></div>
                        <div className="layer"><div className="girl"></div></div>
                        <div className="layer"><div className="package"></div></div>
                        <div className="layer">
                            <div className="content">
                                <div className="left">
                                    <div className="top">
                                        <div className="title">Balance</div>
                                        <div className="royalty">7%</div>
                                    </div>

                                    <div className="balance">
                                        <span>$1 500</span>
                                        Invite Income
                                    </div>

                                    <div className="redeem tablet">Redeem <span>$1 500.00</span></div>
                                    <div className="redeem desktop">Redeem balance <span>$1 500.00</span></div>
                                </div>
                                <hr />
                                <div className="right">
                                    <div className="top">
                                        <div className="title">Referral Stats</div>
                                    </div>

                                    <div className="stats">
                                        <div className="stat">
                                            <div className="title">Monthly Income</div>
                                            <div className="values">
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M6.50391 10.7474C6.50391 11.7149 7.24641 12.4949 8.16891 12.4949H10.0514C10.8539 12.4949 11.5064 11.8124 11.5064 10.9724C11.5064 10.0574 11.1089 9.73488 10.5164 9.52488L7.49391 8.47488C6.90141 8.26488 6.50391 7.94238 6.50391 7.02738C6.50391 6.18738 7.15641 5.50488 7.95891 5.50488H9.84141C10.7639 5.50488 11.5064 6.28488 11.5064 7.25238" stroke="#449A61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M9 4.5V13.5" stroke="#449A61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                    <span>$1 258.01</span>
                                                </div>
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.3444 4.40506C17.1064 4.24378 16.8255 4.15757 16.5381 4.15757C16.2506 4.15757 15.9697 4.24378 15.7318 4.40506L13.2252 5.95916C13.2002 5.97047 13.1732 5.97632 13.1458 5.97632C13.1184 5.97632 13.0914 5.97047 13.0664 5.95916C13.0107 5.94408 12.9628 5.9082 12.9327 5.85889L10.259 1.0462C10.129 0.829922 9.94532 0.650954 9.7257 0.526706C9.50608 0.402458 9.25804 0.337158 9.00571 0.337158C8.75338 0.337158 8.50534 0.402458 8.28572 0.526706C8.0661 0.650954 7.88238 0.829922 7.75241 1.0462L4.97843 5.85054C4.94834 5.89984 4.90049 5.93573 4.84474 5.9508C4.81981 5.96211 4.79274 5.96796 4.76536 5.96796C4.73799 5.96796 4.71092 5.96211 4.68599 5.9508L2.22116 4.37164C1.98253 4.22062 1.7047 4.14325 1.42236 4.14919C1.14002 4.15513 0.865695 4.24413 0.633637 4.40506C0.395479 4.56779 0.211109 4.79769 0.103984 5.0655C-0.00314209 5.33331 -0.0281814 5.62694 0.032051 5.90903L1.43575 12.5014C1.50471 12.8294 1.68427 13.1238 1.94437 13.3352C2.20448 13.5467 2.52932 13.6623 2.86452 13.6628H15.1385C15.4737 13.6623 15.7986 13.5467 16.0587 13.3352C16.3188 13.1238 16.4984 12.8294 16.5673 12.5014L17.971 5.90903C18.0287 5.62442 17.9999 5.32907 17.8882 5.06101C17.7765 4.79296 17.5871 4.56449 17.3444 4.40506Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <span>250 XP</span>
                                                </div>
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.11992 8.1525C9.04492 8.145 8.95492 8.145 8.87242 8.1525C7.08742 8.0925 5.66992 6.63 5.66992 4.83C5.66992 2.9925 7.15492 1.5 8.99992 1.5C10.8374 1.5 12.3299 2.9925 12.3299 4.83C12.3224 6.63 10.9049 8.0925 9.11992 8.1525Z" fill="white"/>
                                                            <path d="M5.36906 10.92C3.55406 12.135 3.55406 14.115 5.36906 15.3225C7.43156 16.7025 10.8141 16.7025 12.8766 15.3225C14.6916 14.1075 14.6916 12.1275 12.8766 10.92C10.8216 9.5475 7.43906 9.5475 5.36906 10.92Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <span>25</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="stat">
                                            <div className="title">Total Income</div>
                                            <div className="values">
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M6.50391 10.7474C6.50391 11.7149 7.24641 12.4949 8.16891 12.4949H10.0514C10.8539 12.4949 11.5064 11.8124 11.5064 10.9724C11.5064 10.0574 11.1089 9.73488 10.5164 9.52488L7.49391 8.47488C6.90141 8.26488 6.50391 7.94238 6.50391 7.02738C6.50391 6.18738 7.15641 5.50488 7.95891 5.50488H9.84141C10.7639 5.50488 11.5064 6.28488 11.5064 7.25238" stroke="#449A61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            <path d="M9 4.5V13.5" stroke="#449A61" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </svg>
                                                    </div>
                                                    <span>$1 258.01</span>
                                                </div>
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.3444 4.40506C17.1064 4.24378 16.8255 4.15757 16.5381 4.15757C16.2506 4.15757 15.9697 4.24378 15.7318 4.40506L13.2252 5.95916C13.2002 5.97047 13.1732 5.97632 13.1458 5.97632C13.1184 5.97632 13.0914 5.97047 13.0664 5.95916C13.0107 5.94408 12.9628 5.9082 12.9327 5.85889L10.259 1.0462C10.129 0.829922 9.94532 0.650954 9.7257 0.526706C9.50608 0.402458 9.25804 0.337158 9.00571 0.337158C8.75338 0.337158 8.50534 0.402458 8.28572 0.526706C8.0661 0.650954 7.88238 0.829922 7.75241 1.0462L4.97843 5.85054C4.94834 5.89984 4.90049 5.93573 4.84474 5.9508C4.81981 5.96211 4.79274 5.96796 4.76536 5.96796C4.73799 5.96796 4.71092 5.96211 4.68599 5.9508L2.22116 4.37164C1.98253 4.22062 1.7047 4.14325 1.42236 4.14919C1.14002 4.15513 0.865695 4.24413 0.633637 4.40506C0.395479 4.56779 0.211109 4.79769 0.103984 5.0655C-0.00314209 5.33331 -0.0281814 5.62694 0.032051 5.90903L1.43575 12.5014C1.50471 12.8294 1.68427 13.1238 1.94437 13.3352C2.20448 13.5467 2.52932 13.6623 2.86452 13.6628H15.1385C15.4737 13.6623 15.7986 13.5467 16.0587 13.3352C16.3188 13.1238 16.4984 12.8294 16.5673 12.5014L17.971 5.90903C18.0287 5.62442 17.9999 5.32907 17.8882 5.06101C17.7765 4.79296 17.5871 4.56449 17.3444 4.40506Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <span>250 XP</span>
                                                </div>
                                                <div className="value">
                                                    <div className="icon">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.11992 8.1525C9.04492 8.145 8.95492 8.145 8.87242 8.1525C7.08742 8.0925 5.66992 6.63 5.66992 4.83C5.66992 2.9925 7.15492 1.5 8.99992 1.5C10.8374 1.5 12.3299 2.9925 12.3299 4.83C12.3224 6.63 10.9049 8.0925 9.11992 8.1525Z" fill="white"/>
                                                            <path d="M5.36906 10.92C3.55406 12.135 3.55406 14.115 5.36906 15.3225C7.43156 16.7025 10.8141 16.7025 12.8766 15.3225C14.6916 14.1075 14.6916 12.1275 12.8766 10.92C10.8216 9.5475 7.43906 9.5475 5.36906 10.92Z" fill="white"/>
                                                        </svg>
                                                    </div>
                                                    <span>25</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="link">
                                        <div className="input">
                                            <span><span className="mobile">https://</span>lucksy.net/ref/GH1jSA</span>
                                            <div className="icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6654 8.60004V11.4C10.6654 13.7334 9.73203 14.6667 7.3987 14.6667H4.5987C2.26536 14.6667 1.33203 13.7334 1.33203 11.4V8.60004C1.33203 6.26671 2.26536 5.33337 4.5987 5.33337H7.3987C9.73203 5.33337 10.6654 6.26671 10.6654 8.60004Z" fill="#59EF79"/>
                                                    <path d="M11.3992 1.33337H8.59916C6.53249 1.33337 5.59916 2.06671 5.39916 3.80004C5.3325 4.20004 5.66583 4.46671 6.06583 4.46671H7.46583C10.2658 4.46671 11.5325 5.73337 11.5325 8.53337V10C11.5325 10.4 11.8658 10.6667 12.1992 10.6667C13.9325 10.4667 14.6658 9.53337 14.6658 7.46671V4.60004C14.6658 2.26671 13.7325 1.33337 11.3992 1.33337Z" fill="#59EF79"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="button">Share</div>
                                        <div className="button tablet">
                                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.6003 14.7668C14.5549 14.7668 13.6131 15.2182 12.9594 15.9363L7.07989 12.2949C7.24131 11.8823 7.32407 11.4431 7.3239 11C7.3241 10.5569 7.24134 10.1177 7.07989 9.70508L12.9594 6.06346C13.6131 6.78158 14.5549 7.2332 15.6003 7.2332C17.5695 7.2332 19.1715 5.63119 19.1715 3.66197C19.1715 1.69276 17.5695 0.0909119 15.6003 0.0909119C13.6311 0.0909119 12.0291 1.69293 12.0291 3.66214C12.029 4.1052 12.1118 4.54436 12.2731 4.957L6.39373 8.5985C5.74004 7.88037 4.79823 7.42875 3.75288 7.42875C1.78366 7.42875 0.181641 9.03094 0.181641 11C0.181641 12.9692 1.78366 14.5712 3.75288 14.5712C4.79819 14.5712 5.74008 14.1198 6.39373 13.4015L12.2731 17.043C12.1118 17.4557 12.029 17.8949 12.0291 18.338C12.0291 20.307 13.6311 21.9091 15.6003 21.9091C17.5695 21.9091 19.1715 20.307 19.1715 18.338C19.1715 16.3688 17.5695 14.7668 15.6003 14.7668ZM13.3313 3.66214C13.3313 2.41101 14.3492 1.39314 15.6003 1.39314C16.8514 1.39314 17.8693 2.41101 17.8693 3.66214C17.8693 4.91327 16.8514 5.93114 15.6003 5.93114C14.3491 5.93114 13.3313 4.91323 13.3313 3.66214ZM3.75288 13.269C2.50157 13.269 1.4837 12.2511 1.4837 11C1.4837 9.74889 2.50157 8.73098 3.75288 8.73098C5.00401 8.73098 6.02171 9.74889 6.02171 11C6.02171 12.2511 5.00397 13.269 3.75288 13.269ZM13.3313 18.3379C13.3313 17.0867 14.3492 16.0688 15.6003 16.0688C16.8514 16.0688 17.8693 17.0867 17.8693 18.3378C17.8693 19.589 16.8514 20.6068 15.6003 20.6068C14.3491 20.6068 13.3313 19.589 13.3313 18.3378V18.3379Z" fill="white" stroke="white" stroke-width="0.2"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <History />
                    <Steps />
                    <FAQ />
                    <VIP />
                    <History />
                </div>

                <Components.DepositBonus />
                <Components.Footer />
            </div>
        </PageContainer>
    )
}