import {useEffect} from 'react';
import PageContainer from '../page-container';
import Top from './top';
import Games from './games';
import Compontens from '../../components';

export default () => {
    useEffect(() => {
        Compontens.Loader.close();
    }, []);

    return (
        <PageContainer>
            <div className="page home">
                <Top />
                <Compontens.Winners />
                <Games />

                <Compontens.DepositBonus />
                <Compontens.Footer />
            </div>
        </PageContainer>
    )
}