import Progress from './progress';

export default () => {

    return (
        <div className="vip-top">
            <Progress />
            <div className="cashbacks">
                <div className="cashback weekly">
                    <div className="title">WEEKLY</div>
                    <div className="details">
                        <div className="amount">
                            <span>$1 500</span>
                            BOOST
                        </div>
                        <div className="royalty">
                            Cashback <span>7%</span>
                        </div>
                    </div>

                    <div className="timer">
                        <div className="sector">
                            <div className="time">10</div>
                            hours
                        </div>
                        <div className="dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="sector">
                            <div className="time">10</div>
                            minutes
                        </div>
                        <div className="dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="sector">
                            <div className="time">10</div>
                            seconds
                        </div>
                    </div>
                </div>

                <div className="cashback monthly">
                    <div className="title">MONTHLY</div>
                    <div className="details">
                        <div className="amount">
                            <span>$1 500</span>
                            BOOST
                        </div>
                        <div className="royalty">
                            Cashback <span>7%</span>
                        </div>
                    </div>

                    <div className="timer">
                        <div className="sector">
                            <div className="time">10</div>
                            hours
                        </div>
                        <div className="dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="sector">
                            <div className="time">10</div>
                            minutes
                        </div>
                        <div className="dots">
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                        <div className="sector">
                            <div className="time">10</div>
                            seconds
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    )
}