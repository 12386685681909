export default () => (
<svg width="162" height="154" viewBox="0 0 162 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.3608 148.81L157.581 93.4301L128.471 3.82007H34.2509L5.13086 93.4301L81.3608 148.81Z" fill="#7D7B8C"/>
    <path d="M131.191 0.0700684H31.5207L0.720703 94.8601L81.3507 153.44L161.981 94.8601L131.191 0.0700684ZM16.6707 89.6801L41.3807 13.6401H121.341L146.051 89.6801L81.3707 136.68L16.6807 89.6801H16.6707Z" fill="url(#paint0_radial_999_5771)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M41.3809 13.6401L81.3608 136.68L121.341 13.6401H41.3809Z" fill="#817F90"/>
    </g>
    <path d="M131.19 0.0700684H31.5204L24.4004 22.0001C28.8304 22.2001 33.4204 22.7601 38.1204 23.6601L41.3804 13.6401H121.34L146.05 89.6801L136.67 96.5001C138.78 100.6 140.65 104.84 142.25 109.21L161.99 94.8701L131.19 0.0700684Z" fill="url(#paint1_radial_999_5771)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M131.19 0.0700684H31.5204L24.4004 22.0001C28.8304 22.2001 33.4204 22.7601 38.1204 23.6601L41.3804 13.6401H121.34L146.05 89.6801L136.67 96.5001C138.78 100.6 140.65 104.84 142.25 109.21L161.99 94.8701L131.19 0.0700684Z" fill="url(#paint2_radial_999_5771)"/>
    <path style={{mixBlendMode: 'screen'}} d="M125.731 121.21C125.261 115.93 124.471 110.9 123.411 106.13L81.3607 136.68L16.6707 89.6801L34.4107 35.0801C29.3707 35.2001 24.4107 35.7701 19.6007 36.7501L0.720703 94.8601L81.3507 153.44L125.721 121.21H125.731Z" fill="url(#paint3_radial_999_5771)"/>
    <path d="M16.6699 89.6801L23.0599 88.2501L45.3299 19.7201H117.38L139.65 88.2501L146.04 89.6801L121.34 13.6401H41.3799L16.6699 89.6801Z" fill="#858393"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
        <path d="M23.0608 88.25L35.5208 56.28C42.9008 37.35 61.1308 29.14 81.4508 29.14C101.851 29.14 120.151 37.46 127.461 56.51L139.651 88.25L117.381 19.72H45.3208L23.0508 88.25H23.0608Z" fill="#908E9D"/>
    </g>
    <path d="M16.6699 89.68L23.0599 88.25L81.3599 130.6L139.65 88.25L146.04 89.68L81.3599 136.68L16.6699 89.68Z" fill="url(#paint4_linear_999_5771)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M23.061 88.25L45.921 29.59C47.021 26.76 49.751 24.89 52.791 24.89H110.241C113.291 24.89 116.031 26.77 117.121 29.62L139.641 88.25L117.371 19.72H45.311L23.041 88.25H23.061Z" fill="#908E9D"/>
    </g>
    <path d="M41.3809 13.6401L45.3308 19.7201H117.381L121.341 13.6401H41.3809Z" fill="#777687"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M11.7704 60.8701L4.05045 89.7901C3.45045 92.2801 4.63046 95.5901 6.69046 97.1301L81.3604 153.45L0.730469 94.8701L11.7805 60.8801L11.7704 60.8701Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M23.0605 88.25L78.6706 125.71C80.1406 126.7 82.5605 126.7 84.0305 125.71L139.641 88.25L81.3506 130.6L23.0605 88.25Z" fill="#908E9D"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.3613 153.45L153.161 97.9902C158.181 94.2702 159.271 93.4602 157.011 86.3302L142.031 33.4102L162.001 94.8702L81.3713 153.45H81.3613Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M13.5898 55.2801L30.4298 6.99007C32.1698 2.09007 33.4399 0.800068 38.6299 0.610068L81.3598 0.0700684H31.5298L13.5898 55.2801Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M45.7305 110.79L81.3604 136.68L129.47 101.72L81.3604 138.88L45.7305 110.79Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M78.6504 0.0700684H131.19L142.02 33.4101L129.18 1.92007L78.6504 0.0700684Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M99.5609 0.0700684C99.1209 4.39007 101.011 9.22007 104.651 13.6301H121.341L125.471 26.3301C130.701 27.6101 135.701 27.6501 139.801 26.5301L131.191 0.0700684H99.5609Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M95.5497 7.62002C92.0697 5.09002 87.8697 4.93002 86.1697 7.26002C84.9597 8.93002 85.3597 11.43 86.9697 13.64H99.4597C99.2697 11.6 97.8597 9.30002 95.5397 7.62002H95.5497Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M41.3905 107.64C40.1305 110.89 42.4105 115.73 47.0005 119.06C51.5905 122.39 56.9005 123.07 59.5905 120.87L41.3805 107.64H41.3905Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M59.72 129.73C58.95 130.91 56.82 130.89 54.97 129.68C53.12 128.47 52.23 126.53 53 125.35C53.77 124.17 55.9 124.19 57.75 125.4C59.61 126.61 60.49 128.55 59.72 129.73Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.3615 73.94L43.8516 56.54V61.4L81.3615 88.65L118.872 61.4V56.54L81.3615 73.94Z" fill="#908E9D"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.361 104.58L35.791 81.3401V86.1901L81.361 119.3L126.921 86.1901V81.3401L81.361 104.58Z" fill="#908E9D"/>
    </g>
    <path d="M81.3615 68.47L43.8516 49.26V56.54L81.3615 83.79L118.872 56.54V49.26L81.3615 68.47Z" fill="#858393"/>
    <path d="M81.361 99.12L35.791 74.05V81.34L81.361 114.44L126.921 81.34V74.05L81.361 99.12Z" fill="#858393"/>
    <path d="M81.3615 61.1902L47.8615 36.9102L43.8516 49.2602L81.3615 76.5102L118.872 49.2602L114.852 36.9102L81.3615 61.1902Z" fill="url(#paint5_radial_999_5771)"/>
    <path d="M81.361 91.84L39.801 61.72L35.791 74.05L81.361 107.16L126.921 74.05L122.911 61.72L81.361 91.84Z" fill="url(#paint6_radial_999_5771)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M114.85 36.9102L96.4902 50.2202C99.9002 52.7602 103.22 55.4502 106.44 58.2902L118.86 49.2602L114.85 36.9102Z" fill="url(#paint7_radial_999_5771)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M115.541 67.07C118.381 70.06 121.101 73.18 123.661 76.43L126.921 74.06L122.911 61.72L115.531 67.07H115.541Z" fill="url(#paint8_radial_999_5771)"/>
    <path style={{mixBlendMode: 'screen'}} d="M100.83 62.3602C97.8898 59.2502 94.7698 56.4102 91.4898 53.8402L81.3499 61.1902L47.8499 36.9102L43.8398 49.2602L81.3499 76.5102L100.82 62.3602H100.83Z" fill="url(#paint9_radial_999_5771)"/>
    <path style={{mixBlendMode: 'screen'}} d="M81.361 107.16L115.081 82.66C113.161 78.88 111.021 75.34 108.701 72.02L81.361 91.83L39.801 61.71L35.791 74.05L81.361 107.15V107.16Z" fill="url(#paint10_radial_999_5771)"/>
    <path d="M43.8516 49.26L81.3615 76.51V83.79L43.8516 56.54V49.26Z" fill="#777687"/>
    <path d="M35.791 74.05L81.361 107.16V114.44L35.791 81.34V74.05Z" fill="#777687"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M53.5801 41.0601L81.3601 63.3501L105.47 43.7101L81.3601 61.1901L53.5801 41.0601Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M55.1406 72.8401L81.3606 94.8502L113.331 68.6602L81.3606 91.8401L55.1406 72.8401Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M47.8615 36.9102L46.5016 45.0602C46.2816 47.5302 46.7916 48.9002 48.7216 50.4602L81.3615 76.5102L43.8516 49.2602L47.8615 36.9102Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M39.8012 61.72L38.5113 69.86C38.3413 71.57 38.6813 73.64 40.0113 74.72L81.3513 107.16L35.7812 74.06L39.7913 61.72H39.8012Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M104.102 59.99L113.512 51.43C115.502 49.62 116.492 46.95 116.172 44.28L115.542 39.01L118.872 49.26L104.102 59.99Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M104.102 90.6301L122.582 74.7401C124.252 73.3101 124.872 71.7501 124.652 69.5701L123.762 64.3301L126.922 74.0501L104.102 90.6301Z" fill="#9492A0"/>
    </g>
    <path d="M107.16 118.95C107.16 118.95 110.68 112.39 116.15 108.62C119.42 106.36 125.73 104.59 125.73 104.59C125.73 104.59 119.85 111.98 115.51 115.44C111.17 118.9 107.17 118.94 107.17 118.94L107.16 118.95Z" fill="url(#paint11_linear_999_5771)"/>
    <path d="M121.931 107.06C117.101 107.34 113.231 111.2 111.131 113.92C109.031 116.63 110.221 117.54 112.881 116.36C115.531 115.18 123.971 106.95 121.931 107.07V107.06Z" fill="#898797"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M121.929 107.06C118.659 107.03 114.549 110.12 113.269 111.5C111.979 112.88 114.549 115 116.299 113.88C118.049 112.76 123.419 107.08 121.929 107.06Z" fill="url(#paint12_linear_999_5771)"/>
    <defs>
        <radialGradient id="paint0_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.131 -7.53994) scale(202.4 202.4)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.13 -7.53994) scale(202.4 202.4)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.87 -0.679935) scale(177.15 177.15)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.28" stop-color="#BDBDC5"/>
            <stop offset="0.63" stop-color="#AFAEB8"/>
            <stop offset="1" stop-color="#9897A4"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.5007 140.68) scale(95.72 95.72)">
            <stop offset="0.32" stop-color="#AEADB7"/>
            <stop offset="0.76" stop-color="#7B7A8B"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_5771" x1="81.3599" y1="77.04" x2="81.3599" y2="103.76" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#B0AFB9"/>
            <stop offset="1" stop-color="#D9D9DD"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.132 -7.53985) scale(202.4 202.4)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(133.131 -7.54004) scale(202.4 202.4)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.87 -0.679837) scale(177.15 177.15)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.28" stop-color="#BDBDC5"/>
            <stop offset="0.63" stop-color="#AFAEB8"/>
            <stop offset="1" stop-color="#9897A4"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.871 -0.680033) scale(177.15 177.15)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.28" stop-color="#BDBDC5"/>
            <stop offset="0.63" stop-color="#AFAEB8"/>
            <stop offset="1" stop-color="#9897A4"/>
        </radialGradient>
        <radialGradient id="paint9_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.4998 140.68) scale(95.72 95.72)">
            <stop offset="0.32" stop-color="#AEADB7"/>
            <stop offset="0.76" stop-color="#7B7A8B"/>
        </radialGradient>
        <radialGradient id="paint10_radial_999_5771" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.501 140.68) scale(95.72 95.72)">
            <stop offset="0.32" stop-color="#AEADB7"/>
            <stop offset="0.76" stop-color="#7B7A8B"/>
        </radialGradient>
        <linearGradient id="paint11_linear_999_5771" x1="109.71" y1="115.65" x2="122.51" y2="108.29" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E8E8EB"/>
            <stop offset="0.99" stop-color="#ABAAB5"/>
        </linearGradient>
        <linearGradient id="paint12_linear_999_5771" x1="118.184" y1="113.601" x2="117.23" y2="107.571" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#9998A5"/>
            <stop offset="1" stop-color="#817F90"/>
        </linearGradient>
    </defs>
</svg>
)