import {combineReducers} from 'redux';
import app from './app/reducer';
import user from './user/reducer';
import games from './games/reducer';
import modals from './modals/reducer';
import vip from './vip/reducer';
import wallet from './wallet/reducer';

export default combineReducers({
    app,
    user,
    games,
    modals,
    vip,
    wallet
});