import types from '../types';

export const createOrder = (data) => dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_deposit.updateStageData,
        payload: {
            error: null,
            ...data, // Save new data
            button: true
        }
    });

    // Make API request
    setTimeout(() => {
        // dispatch({
        //     type: types.cashapp_deposit.updateStageData,
        //     payload: {
        //         error: "Something went wrong when creating a deposit request",
        //         button: false
        //     }
        // });

        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                button: false
            }
        });

        dispatch({
            type: types.cashapp_deposit.setStage,
            payload: 'confirm'
        });
    }, 1e3);
}

export const confirmOrder = data => dispatch => {
    // Disable button and clear error
    dispatch({
        type: types.cashapp_deposit.updateStageData,
        payload: {
            error: null,
            ...data, // Save new data
            button: true
        }
    });

    // Make API request
    setTimeout(() => {
        dispatch({
            type: types.cashapp_deposit.updateStageData,
            payload: {
                error: "Something went wrong when confirm a deposit request",
                button: false
            }
        });
    }, 1e3);
}

export const setStage = stage => {
    return {
        type: types.cashapp_deposit.setStage,
        payload: stage
    }
}
