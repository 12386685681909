import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.game,
    props.user.logins,
    props.user.user,
    props.app.isMobile,
    (game, logins, user, isMobile, ) => {
        const login = (game && typeof game.slug !== 'undefined') ? logins.filter(l => l.game === game.slug)[0] || false : false;

        return {
            isMobile,
            game,
            login,
            user
        }
    }
)