import Left from './left';
import Right from './right';

export default () => {

    return (
        <div className="header-container">
            <div className="header animate__animated animate__fadeInDown">
                <Left />
                <Right />
            </div>
        </div>
    )
}