export default () => {

    return (
        <div className="winners">
            <div className="title">
                <div className="text">Winners</div>
            </div>

            <div className="winners-list">
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'gaminator'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+50 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'winstar'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+142 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'noble'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+1 267.21 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'exito'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+78 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'gaminator'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+842 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'milkyways'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+981.21 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'pandamaster'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">+999 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'ultrapanda'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">842 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'firekirin'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">842 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'vblink'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">842 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
                <div className="winner">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${'cashmachine'}.png)`}}></div>
                    <div className="details">
                        <div className="amount">842 USD</div>
                        <div className="username">zus*itqdjqwkljdklqwjkldjqwkl</div>
                    </div>
                    <div className="line"></div>
                </div>
            </div>
        </div>
    )
}