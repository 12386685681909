export default () => {

    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="6.10352e-05" width="46" height="46" rx="14" fill="#6481E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 8.00006V28.0001L32 22.7464L23 8.00006Z" fill="#C1CCF5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 22.7464L23 28.0001V8.00006L14 22.7464Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0003 19.0001L14 22.9119L22.9997 28.0001L32 22.9124L23.0003 19.0001Z" fill="#8299EC"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 29.4501V37.0001L32 24.0001L23 29.4501Z" fill="#C1CCF5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23 19.0001L14 22.9119L22.9994 28.0001L23 19.0001Z" fill="#C1CCF5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 24.0001L23 37.0001V29.4504L14 24.0001Z" fill="white"/>
        </svg>
    )
}