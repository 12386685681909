import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals/download';
import {getDownloadCode} from '../../reducers/games/actions';
import {resetModal} from '../../reducers/modals/actions';
import ModalsLib from '../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import GreenButton from '../components/green-button';
import BlueButton from '../components/blue-button';

export default () => {
    const {game, button, data} = useSelector(Selector),
          dispatch = useDispatch();

    const modalCloseHandler = () => {
        dispatch(resetModal('download'));
    }

    useEffect(() => {
        document.addEventListener('modal.close', modalCloseHandler);
        return () => document.removeEventListener('modal.close', modalCloseHandler);
    }, []);

    if(!game)
        return <></>;

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Download</div>
                    <div className="descriptions">Download the game to your device for the convenience of playing</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game-small">
                    <div className="game-details">
                        <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                        <div className="name">{game.name}</div>
                    </div>
                    <div className="mobile-oc">
                        {game.downloads.ios && (
                            <div className="icon">
                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1082_22547)">
                                        <path d="M14.8508 11.6717C14.8261 9.00281 17.0921 7.70438 17.1957 7.64393C15.9124 5.81967 13.9233 5.57041 13.2243 5.55054C11.5537 5.37913 9.93323 6.52519 9.08221 6.52519C8.21421 6.52519 6.90372 5.5671 5.49131 5.59526C3.67377 5.62258 1.97345 6.64858 1.0409 8.24181C-0.883651 11.4904 0.551692 16.2643 2.39556 18.8901C3.31791 20.1761 4.39569 21.612 5.8064 21.5615C7.18654 21.506 7.70208 20.7036 9.36758 20.7036C11.0178 20.7036 11.5019 21.5615 12.9407 21.5292C14.4219 21.506 15.3544 20.2374 16.2445 18.9398C17.3104 17.4658 17.7384 16.0142 17.7554 15.9397C17.7206 15.9281 14.8788 14.8706 14.8508 11.6717Z" fill="white"/>
                                        <path d="M12.133 3.82316C12.8753 2.91807 13.3831 1.68671 13.2422 0.437134C12.1678 0.483506 10.8242 1.16171 10.0504 2.04693C9.36588 2.82698 8.75438 4.10554 8.91235 5.30791C10.1192 5.39569 11.3584 4.71418 12.133 3.82316Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1082_22547">
                                            <rect width="19.7466" height="21.6575" fill="white" transform="translate(0.126953 0.171234)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        )}

                        {game.downloads.android && (
                            <div className="icon">
                                <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1082_22554)">
                                        <path d="M15.9859 4.2303L17.9918 2.11971C18.3186 1.78725 18.3276 1.23881 18.012 0.894569C17.6962 0.550413 17.1754 0.540909 16.8485 0.873288C16.8417 0.880273 16.835 0.88734 16.8283 0.894569L14.4917 3.35524C13.0759 2.7076 11.5487 2.3734 10.0051 2.3734C8.46144 2.3734 6.93429 2.7076 5.51847 3.35524L3.18182 0.892863C2.855 0.560403 2.33411 0.569988 2.01839 0.914145C1.71047 1.24993 1.71047 1.78221 2.01839 2.11792L4.02267 4.23021C2.81992 5.06826 1.83432 6.20857 1.15488 7.54817C0.475435 8.88778 0.123542 10.3845 0.130975 11.9032C0.130975 12.3817 0.499372 12.7696 0.953775 12.7696H19.0548C19.5092 12.7696 19.8776 12.3817 19.8776 11.9032C19.885 10.3845 19.5331 8.88781 18.8536 7.54822C18.1742 6.20863 17.1886 5.06834 15.9859 4.2303Z" fill="white"/>
                                        <path d="M5.89067 9.30402C6.34509 9.30402 6.71347 8.9161 6.71347 8.43758C6.71347 7.95906 6.34509 7.57114 5.89067 7.57114C5.43625 7.57114 5.06787 7.95906 5.06787 8.43758C5.06787 8.9161 5.43625 9.30402 5.89067 9.30402Z" fill="#101626"/>
                                        <path d="M14.1187 9.30402C14.5731 9.30402 14.9415 8.9161 14.9415 8.43758C14.9415 7.95906 14.5731 7.57114 14.1187 7.57114C13.6643 7.57114 13.2959 7.95906 13.2959 8.43758C13.2959 8.9161 13.6643 9.30402 14.1187 9.30402Z" fill="#101626"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1082_22554">
                                            <rect width="19.7466" height="12.1027" fill="white" transform="translate(0.126953 0.630096)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        )}

                        {game.downloads.windows && (
                            <div className="icon">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_1082_22561)">
                                        <path d="M0.769531 10.4154L0.770158 16.9123L8.57596 17.9815L8.56969 10.4655L0.769531 10.4154Z" fill="white"/>
                                        <path d="M0.770508 16.9123L8.57631 17.9815L8.57004 10.4655M19.7172 0.445175L9.37922 1.9517V9.58708L19.7172 9.505V0.445175Z" fill="white"/>
                                        <path d="M19.7169 0.445175L9.37891 1.9517V9.37343" fill="white"/>
                                        <path d="M0.76416 3.1509L0.771365 9.6444L8.57748 9.59992L8.57372 2.09079L0.76416 3.1509Z" fill="white"/>
                                        <path d="M0.770996 9.6444L8.57711 9.59992L8.57335 2.09079" fill="white"/>
                                        <path d="M9.51685 18.0977L19.8708 19.5548L19.8736 10.536L9.50244 10.5191L9.51685 18.0977Z" fill="white"/>
                                        <path d="M9.51709 18.0977L19.871 19.5548L19.8739 10.536" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1082_22561">
                                            <rect width="19.1096" height="19.1096" fill="white" transform="translate(0.76416 0.445175)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>

                <div className="text">
                    Go to the download page of the game and follow the installation instructions. {game.allows.download_code ? 'During installation, the game may request a download code. You can get it by clicking on the "Generate download code" button' : ''}
                </div>

                {game.allows.download_code && (
                    <>
                        {data.code === false && (
                            <GreenButton active={button} onClick={e => {
                                if(!button)
                                    dispatch(getDownloadCode({
                                        game: game.slug
                                    }));
                            }}>
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.8337 9.99999C18.8337 14.6 15.1003 18.3333 10.5003 18.3333C5.90033 18.3333 3.09199 13.7 3.09199 13.7M3.09199 13.7H6.85866M3.09199 13.7V17.8667M2.16699 9.99999C2.16699 5.39999 5.86699 1.66666 10.5003 1.66666C16.0587 1.66666 18.8337 6.29999 18.8337 6.29999M18.8337 6.29999V2.13332M18.8337 6.29999H15.1337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                Generate download code
                            </GreenButton>
                        )}

                        {data.code === null && (
                            <GreenButton active={true}></GreenButton>
                        )}

                        {data.code && (
                            <div className="download_code">
                                <div className="name">Download code</div>
                                <div className="code">
                                    <span>{data.code}</span>

                                    <CopyToClipboard text={data.code} onCopy={e => {
                                        toast.info(`Download code has been copied to clipboard!`);
                                    }}>
                                        <div className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className="modal-footer">


                <BlueButton onClick={e => {
                    window.open(game.links.download, '_blank');
                }}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_673_1927)">
                            <path d="M12.4444 16H3.55555C2.60588 16 1.71302 15.6302 1.04142 14.9586C0.369847 14.287 0 13.3941 0 12.4444V11.5555C0 11.0646 0.397948 10.6666 0.888894 10.6666C1.37984 10.6666 1.77779 11.0646 1.77779 11.5555V12.4444C1.77779 12.9193 1.96273 13.3657 2.29845 13.7015C2.63426 14.0372 3.08068 14.2222 3.55555 14.2222H12.4444C12.9193 14.2222 13.3657 14.0372 13.7015 13.7015C14.0372 13.3656 14.2222 12.9192 14.2222 12.4444V11.5555C14.2222 11.0646 14.6202 10.6666 15.1111 10.6666C15.602 10.6666 16 11.0646 16 11.5555V12.4444C16 13.3941 15.6302 14.2869 14.9586 14.9586C14.287 15.6302 13.3941 16 12.4444 16ZM7.99998 12.4444C7.76788 12.4447 7.54491 12.3539 7.37903 12.1916L7.37897 12.1915L7.3772 12.1898L7.3767 12.1893C7.3762 12.1888 7.37577 12.1884 7.3753 12.1879L7.3744 12.187C7.37408 12.1867 7.37375 12.1864 7.37341 12.186L7.37148 12.1841L3.81591 8.62852C3.46879 8.2814 3.46879 7.71857 3.81591 7.37142C4.16302 7.0243 4.72588 7.02427 5.073 7.37142L7.11112 9.40954V0.888894C7.11109 0.397948 7.50904 0 7.99998 0C8.49093 0 8.88891 0.397948 8.88891 0.888894V9.40951L10.927 7.37142C11.2741 7.0243 11.837 7.0243 12.1841 7.37142C12.5312 7.71854 12.5312 8.2814 12.1841 8.62852L8.62852 12.1841L8.62659 12.186L8.6256 12.1869L8.6247 12.1878C8.62426 12.1883 8.62377 12.1888 8.62333 12.1892L8.6228 12.1897C8.62224 12.1903 8.62166 12.1909 8.62106 12.1915L8.621 12.1915C8.61122 12.201 8.60132 12.2103 8.59119 12.2193C8.51863 12.2841 8.43585 12.3366 8.3462 12.3744L8.34533 12.3748C8.34499 12.3749 8.34471 12.3751 8.34437 12.3752C8.23535 12.421 8.11825 12.4446 7.99998 12.4444Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_673_1927">
                                <rect width="16" height="16" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    
                    Download
                </BlueButton>

                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}