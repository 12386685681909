import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/game/nologin';
import {requestLogin} from '../../../../reducers/games/actions';
import BlueButton from '../../../components/blue-button';

export default () => {
    const {game, button} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div className="no-login">
            <div className="info-block">
                <div className="icon">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.10424 9.80348C7.90174 9.88223 7.26049 10.0697 6.96799 9.90285C6.92763 9.87941 6.89358 9.84651 6.86875 9.80699C6.84393 9.76747 6.82908 9.72251 6.82549 9.67598C6.73362 9.09098 7.17049 7.7391 7.40674 7.01348C7.48549 6.7716 7.54174 6.59535 7.56424 6.48848C7.73487 5.6991 7.65049 5.09535 7.31299 4.6941C6.90687 4.21129 6.18649 4.11735 5.59549 4.20473C4.99454 4.28482 4.40728 4.4463 3.84987 4.68473C3.82397 4.69726 3.80135 4.71564 3.78378 4.73842C3.76621 4.7612 3.75417 4.78775 3.74862 4.81598L3.62674 5.3991C3.62015 5.4319 3.62241 5.46585 3.63328 5.49749C3.64416 5.52912 3.66325 5.55729 3.68862 5.5791C3.71429 5.60044 3.74506 5.61476 3.77792 5.62068C3.81078 5.62659 3.84461 5.6239 3.87612 5.61285C4.09924 5.53035 4.79674 5.34473 5.04612 5.57348C5.18674 5.70285 5.14737 5.9766 5.09112 6.18098C5.03299 6.39473 4.96362 6.62535 4.89237 6.86723C4.43112 8.4366 3.90612 10.216 4.73862 10.771C5.13249 11.0717 5.61191 11.2392 6.10737 11.2491C6.59487 11.2491 7.19674 11.0954 8.06299 10.7447C8.0891 10.7346 8.11259 10.7187 8.13173 10.6982C8.15088 10.6778 8.16519 10.6533 8.17362 10.6266L8.35174 10.0322C8.36183 9.99834 8.36227 9.96231 8.35299 9.92819C8.34372 9.89407 8.3251 9.86322 8.29924 9.8391C8.27338 9.81542 8.24136 9.79952 8.20687 9.79322C8.17238 9.78691 8.1368 9.79047 8.10424 9.80348ZM7.02049 0.750977C6.75227 0.751162 6.49012 0.830857 6.26718 0.979989C6.04424 1.12912 5.87051 1.341 5.76795 1.58884C5.66539 1.83668 5.6386 2.10936 5.69098 2.37242C5.74335 2.63548 5.87253 2.87711 6.06219 3.06677C6.25186 3.25644 6.49349 3.38562 6.75655 3.43799C7.01961 3.49036 7.29229 3.46358 7.54013 3.36102C7.78797 3.25846 7.99985 3.08473 8.14898 2.86179C8.29811 2.63885 8.37781 2.3767 8.37799 2.10848C8.37789 1.74848 8.23484 1.40325 7.98028 1.14869C7.72572 0.89413 7.38049 0.751076 7.02049 0.750977Z" fill="white"/>
                    </svg>
                </div>

                <span>You don't have an account, get your access right now</span>
            </div>

            <BlueButton active={button} onClick={e => {
                dispatch(requestLogin(game.slug));
            }}>
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8036 1.69635C14.5418 -0.565472 10.8618 -0.565437 8.59995 1.69638C7.055 3.24132 6.51838 5.51808 7.18733 7.58369L0.654512 14.1165C0.605517 14.1655 0.566652 14.2236 0.54014 14.2876C0.513627 14.3516 0.499987 14.4202 0.5 14.4894V17.4727C0.5 17.7642 0.735864 18.0001 1.02734 18.0001H4.01064C4.1507 18.0001 4.28461 17.9444 4.38347 17.8455L5.12917 17.0993C5.18538 17.0431 5.22815 16.9749 5.25428 16.8998C5.28041 16.8247 5.28922 16.7447 5.28006 16.6657L5.18736 15.8644L6.29766 15.7598C6.41989 15.7484 6.53429 15.6947 6.62109 15.6079C6.70788 15.5211 6.76162 15.4067 6.77301 15.2845L6.87757 14.1742L7.67888 14.2674C7.8277 14.2874 7.97757 14.2375 8.09035 14.1376C8.14585 14.0881 8.19027 14.0274 8.22071 13.9595C8.25115 13.8916 8.26691 13.818 8.26698 13.7436V12.7618H9.23104C9.3711 12.7618 9.50501 12.7061 9.60387 12.6073L10.9562 11.2728C13.0213 11.9423 15.2587 11.4461 16.8036 9.90007C19.0655 7.63829 19.0655 3.95816 16.8036 1.69635ZM15.3118 5.42538C14.6948 6.04233 13.6916 6.04233 13.0747 5.42538C12.4577 4.80842 12.4577 3.80523 13.0747 3.18827C13.6916 2.57132 14.6948 2.57132 15.3118 3.18827C15.9287 3.80523 15.9287 4.80842 15.3118 5.42538Z" fill="white"/>
                </svg>

                Get access
            </BlueButton>
        </div>
    )
}