import {navigate} from '../../../../../utils/navigation';

export default () => {

    return (
        <div className="col vip" onClick={e => navigate('/vip')}>
            <div className="layer-col layer0">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer1">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer2">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer3">
                <div className="crown"></div>
            </div>

            <div className="layer-col layer-margin layer4">
                <div className="crown"></div>
            </div>

            <div className="layer-col layer-margin layer5">
                <div className="title">VIP Club</div>
                <div className="descriptions">Get up to 25% cashback with real money</div>
            </div>
        </div>
    )
}