import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/components/connection';
import {setConnection} from '../../reducers/app/actions';

export default () => {
    const {connection} = useSelector(Selector),
          dispatch = useDispatch(),
          connectionBlock = createRef();

    useEffect(() => {
        if(connection === 'connected') {
            setTimeout(() => {
                if(connectionBlock.current) {
                    connectionBlock.current.classList.remove('animate__fadeInUp');
                    connectionBlock.current.classList.add('animate__fadeOutDown');
                }

                window.connectionTimeout = setTimeout(() => {
                    dispatch(setConnection(null));
                }, 1000);
            }, 2000);
        }
    }, [connection])

    if(connection === null)
        return <></>;

    return (
        <div className="connection-block animate__animated animate__fadeInUp" ref={connectionBlock}>
            <div className={`connection ${connection}`}>
                {connection === 'lost' && (
                    <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 3C15.97 5.92 16.46 8.96 16.46 12" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 16V15C5.92 15.97 8.96 16.46 12 16.46" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 15L15 22" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M22 22L15 15" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        Internet connection is lost
                    </>
                )}

                {connection === 'connecting' && (
                    <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 3C15.97 5.92 16.46 8.96 16.46 12" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 16V15C5.92 15.97 8.96 16.46 12 16.46" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19.4998 14.7C19.1298 14.59 18.7098 14.52 18.2498 14.52C16.1798 14.52 14.5098 16.2 14.5098 18.26C14.5098 20.33 16.1898 22 18.2498 22C20.3098 22 21.9897 20.32 21.9897 18.26C21.9897 17.49 21.7597 16.77 21.3597 16.18" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.04 14.8L18.79 13.37" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.0401 14.8L18.5801 15.86" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        Trying to connect to the server...
                    </>
                )}

                {connection === 'connected' && (
                    <>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15 3C16.95 8.84 16.95 15.16 15 21" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        The connection has been restored!
                    </>
                )}
            </div>
        </div>
    )
}