export default () => {
    
    return (
        <div className="block profile">
            <div className="layer"><div className="gifts"></div></div>
            <div className="layer"><div className="done"></div></div>
            <div className="layer">
                <div className="content">
                    <div className="title">Complete your profile and get $5</div>
                    <div className="steps">
                        <div className="step">
                            <div className="top">
                                <span>Step 1</span>
                                <div className="icon">
                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1021_3716)">
                                            <path d="M27.7735 22.878L25.0052 26.4365C23.8827 27.8797 21.6324 28.185 20.162 27.0332L16.6127 24.2529L6.66981 37.0326C7.11617 37.1758 7.59614 37.235 8.09115 37.174L39.1764 33.3444C39.6715 33.2834 40.1226 33.1097 40.5207 32.8623L27.7735 22.878Z" fill="white"/>
                                            <path d="M35.9098 6.82607L4.82455 10.6557C4.32954 10.7167 3.87828 10.8906 3.48018 11.1379L17.1017 21.8076C17.1026 21.8083 17.1036 21.8084 17.1045 21.8091C17.1051 21.8095 17.1055 21.8102 17.1057 21.8109L21.5348 25.2801C22.0051 25.6472 22.8818 25.5392 23.2489 25.069L26.7028 20.6292C26.7028 20.6292 26.7036 20.6273 26.7043 20.6264C26.7043 20.6264 26.7059 20.6252 26.7066 20.6243L37.3309 6.96747C36.8847 6.82413 36.4049 6.76509 35.9098 6.82607ZM2.09111 12.8778C1.92706 13.3466 1.85342 13.8535 1.91814 14.3789L4.36838 34.2676C4.43311 34.7929 4.62744 35.2669 4.90043 35.6818L14.8605 22.88L2.09111 12.8778ZM39.1009 8.31815L29.141 21.1207L41.9103 31.1224C42.0743 30.6536 42.148 30.1467 42.0832 29.6212L39.633 9.73248C39.5683 9.20698 39.3738 8.73305 39.1009 8.31815Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1021_3716">
                                                <rect width="38" height="38" fill="white" transform="translate(0.820312 5.46576) rotate(-7.02332)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <div className="info">
                                <div className="name">Confirm your email</div>
                                <div className="text">We will send you a 6-digit code to confirm your email.</div>
                            </div>

                            <div className="button">Confirm</div>
                        </div>

                        <div className="step">
                            <div className="top">
                                <span>Step 2</span>
                                <div className="icon">
                                    <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1021_3731)">
                                            <path d="M31.9054 7.60586L20.5158 2.09539C18.5047 1.12517 16.0879 1.96597 15.1134 3.97502L2.07501 30.9241C1.1045 32.9351 1.94537 35.3519 3.95434 36.3264L15.344 41.8369C17.355 42.8074 19.7718 41.9665 20.7465 39.9577L33.7849 13.0086C34.7551 10.9974 33.9143 8.58068 31.9054 7.60586ZM11.1548 35.97C10.2953 35.5541 9.93564 34.5206 10.3515 33.661C10.7673 32.8017 11.8008 32.4421 12.6604 32.858C13.5197 33.2737 13.8793 34.3073 13.4635 35.1666C13.0477 36.0261 12.0141 36.3858 11.1548 35.97ZM26.2863 10.6478L21.6184 8.38938C21.1888 8.18154 21.0089 7.66453 21.2168 7.23486C21.4247 6.8052 21.9415 6.62564 22.3712 6.83352L27.0391 9.09191C27.4687 9.29975 27.6484 9.81634 27.4405 10.246C27.2327 10.6757 26.716 10.8556 26.2863 10.6478Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1021_3731">
                                                <rect width="20.9259" height="38.027" fill="white" transform="translate(16.8633 0.327637) rotate(25.8184)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <div className="info">
                                <div className="name">Confirm your phone number</div>
                                <div className="text">We will send you a 6-digit code to confirm your phone number.</div>
                            </div>

                            <div className="button">Confirm</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}