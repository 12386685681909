import types from './types';

const InitialState = {
    loaded: false,
    games: null,
    game: null,
    sessions: null,
    buttons: {
        requestLogin: false,
        removeLogin: false,
        requestDownloadCode: false,
        updateBalance: false,
        addCash: false,
        cashOut: false,
        resetPassword: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.history.update:
            return {
                ...state,
                history: {
                    ...state.history,
                    list: state.history.list.map(tx => {
                        return tx.transactionId === payload.transactionId ? {
                            ...payload,
                            timestamp: Date.now() + payload.timestamp
                        } : tx
                    })
                }
            }
        case types.history.add:
            return {
                ...state,
                history: {
                    ...state.history,
                    count: state.history.count + 1,
                    list: [
                        {
                            ...payload,
                            timestamp: Date.now() + payload.timestamp
                        },
                        ...state.history.list
                    ]
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                games: payload.games,
                sessions: payload.sessions
            }
        case types.game:
            return {
                ...state,
                game: payload.game,
                history: payload.history ? {
                    ...payload.history,
                    list: payload.history.list.map(tx => {
                        return {
                            ...tx,
                            timestamp: Date.now() + tx.timestamp
                        }
                    })
                } : state.history
            }
        default:
            return state;
    }
}