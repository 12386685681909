import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.vip.levels,
    props.user.user,
    (levels, user) => {
        const current = user ? levels.filter(lvl => lvl.xp.from <= user.xp && lvl.xp.to > user.xp)[0] || false : false,
              next = current ? levels[levels.findIndex(lvl => lvl.icon === current.icon) + 1] || false : false;

        return {
            current,
            next,
            user
        }
    }
)