import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/crypto_deposit';
import {loadWallet} from '../../../../../reducers/wallet/actions/crypto_deposit';
import ModalsLib from '../../../../../lib/modals';

import Wallet from './wallet';

export default () => {
    const {props} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!props.loaded)
            dispatch(loadWallet(props.coin, props.network));
    }, [props.loaded]);

    if(!props.loaded)
        return (
            <>
                <div className="modal-header">
                    <div className="modal-header-left">
                        <div className="title"></div>
                        <div className="descriptions"></div>
                    </div>
                    <div className="close" onClick={e => ModalsLib.close()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="modal-loader">
                        <div className="element"></div>
                    </div>
                </div>
            </>
        )

    return <Wallet />
}