import InfoButton from '../info-button';
import Methods from './methods';

export default () => {

    return (
        <div className="footer">
            <div className="links">
                <a href="#">Terms & Conditions</a>
                <a href="#">Privacy Policy</a>
                <a href="#">AML & KYC</a>
                <a href="#">Sport Betting</a>
            </div>
            <div className="body">
                <div className="left">
                    <div className="images">
                        <a href="#"><img src={`${window.location.origin}/source/system/footer/image-plug.png`} /></a>
                        <a href="#"><img src={`${window.location.origin}/source/system/footer/image-plug.png`} /></a>
                        <a href="#"><img src={`${window.location.origin}/source/system/footer/image-plug.png`} /></a>
                    </div>

                    <InfoButton />
                </div>
                <div className="right">
                    <div className="block">
                        <div className="app">
                            <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_445_1236)">
                                    <path d="M23.1148 18.0546C23.0761 13.8647 26.6335 11.8263 26.7961 11.7314C24.7815 8.86754 21.6588 8.47624 20.5615 8.44504C17.9388 8.17594 15.3948 9.97514 14.0588 9.97514C12.6961 9.97514 10.6388 8.47104 8.42146 8.51524C5.56813 8.55814 2.89879 10.1688 1.43479 12.67C-1.58654 17.7699 0.666794 25.2644 3.56146 29.3867C5.00946 31.4056 6.70146 33.6598 8.91613 33.5805C11.0828 33.4934 11.8921 32.2337 14.5068 32.2337C17.0975 32.2337 17.8575 33.5805 20.1161 33.5298C22.4415 33.4934 23.9055 31.5018 25.3028 29.4647C26.9761 27.1507 27.6481 24.8718 27.6748 24.7548C27.6201 24.7366 23.1588 23.0765 23.1148 18.0546Z" fill="white"/>
                                    <path d="M18.8481 5.73324C20.0135 4.31234 20.8108 2.37924 20.5895 0.417542C18.9028 0.490341 16.7935 1.55504 15.5788 2.94474C14.5041 4.16934 13.5441 6.17654 13.7921 8.06414C15.6868 8.20194 17.6321 7.13204 18.8481 5.73324Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_445_1236">
                                        <rect width="31" height="34" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="details">
                                <span>App</span>
                                For IOS
                            </div>
                        </div>
                        <div className="app">
                            <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_445_1245)">
                                    <path d="M24.8975 12.6519L28.0465 9.33853C28.5596 8.8166 28.5738 7.95561 28.0782 7.41519C27.5826 6.8749 26.765 6.85999 26.2518 7.38178C26.241 7.39275 26.2305 7.40384 26.2201 7.41519L22.5518 11.2782C20.3291 10.2615 17.9316 9.73679 15.5083 9.73679C13.085 9.73679 10.6875 10.2615 8.46481 11.2782L4.79652 7.41252C4.28344 6.89059 3.4657 6.90564 2.97006 7.44593C2.48665 7.97308 2.48665 8.8087 2.97006 9.33572L6.11656 12.6518C4.22837 13.9674 2.68109 15.7576 1.61443 17.8606C0.547782 19.9637 -0.00465139 22.3134 0.00701701 24.6975C0.00701701 25.4487 0.585362 26.0578 1.29872 26.0578H29.7154C30.4288 26.0578 31.0071 25.4487 31.0071 24.6975C31.0187 22.3134 30.4663 19.9637 29.3996 17.8607C28.3329 15.7577 26.7857 13.9676 24.8975 12.6519Z" fill="#4CAF50"/>
                                    <path d="M9.04903 20.6172C9.76242 20.6172 10.3407 20.0082 10.3407 19.2569C10.3407 18.5057 9.76242 17.8967 9.04903 17.8967C8.33564 17.8967 7.75732 18.5057 7.75732 19.2569C7.75732 20.0082 8.33564 20.6172 9.04903 20.6172Z" fill="#FAFAFA"/>
                                    <path d="M21.9655 20.6172C22.6789 20.6172 23.2572 20.0082 23.2572 19.2569C23.2572 18.5057 22.6789 17.8967 21.9655 17.8967C21.2521 17.8967 20.6738 18.5057 20.6738 19.2569C20.6738 20.0082 21.2521 20.6172 21.9655 20.6172Z" fill="#FAFAFA"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_445_1245">
                                        <rect width="31" height="19" fill="white" transform="translate(0 7)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="details">
                                <span>App</span>
                                For Android
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="app app-big">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_445_1256)">
                                    <path d="M0.00878906 15.6522L0.00977267 25.8517L12.264 27.5302L12.2542 15.7308L0.00878906 15.6522Z" fill="#0DA2D6"/>
                                    <path d="M0.00976562 25.8516L12.264 27.5302L12.2542 15.7308M29.754 0L13.5245 2.36508V14.3518L29.754 14.223V0Z" fill="#20B5EC"/>
                                    <path d="M29.7542 0L13.5247 2.36508V14.0164" fill="#0DA2D6"/>
                                    <path d="M0 4.2477L0.0113115 14.4418L12.2661 14.372L12.2602 2.58344L0 4.2477Z" fill="#20B5EC"/>
                                    <path d="M0.0112305 14.4418L12.266 14.372L12.2601 2.58344" fill="#0DA2D6"/>
                                    <path d="M13.7409 27.7126L29.9955 30L29.9999 15.8415L13.7183 15.8149L13.7409 27.7126Z" fill="#20B5EC"/>
                                    <path d="M13.741 27.7126L29.9956 30L30 15.8415" fill="#0DA2D6"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_445_1256">
                                        <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div className="details">
                                <span>App</span>
                                For Windows
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright"> 
                All Rights Reserved © 2024 Lucksy

                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 41C32.0457 41 41 32.0457 41 21C41 9.95434 32.0457 1.00003 21 1.00003C9.9543 1.00003 1 9.95434 1 21C1 32.0457 9.9543 41 21 41Z" stroke="#69707F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M31.8931 16.7592V25.2407M36.0835 20.9496H27.602M7.44434 13.8809H10.4876V28.119" stroke="#69707F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.557 28.1127C21.829 28.1127 23.6707 26.2709 23.6707 23.999C23.6707 21.7271 21.829 19.8853 19.557 19.8853C17.2851 19.8853 15.4434 21.7271 15.4434 23.999C15.4434 26.2709 17.2851 28.1127 19.557 28.1127Z" stroke="#69707F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.5568 19.8856C21.2131 19.8856 22.5559 18.5428 22.5559 16.8864C22.5559 15.2301 21.2131 13.8873 19.5568 13.8873C17.9004 13.8873 16.5576 15.2301 16.5576 16.8864C16.5576 18.5428 17.9004 19.8856 19.5568 19.8856Z" stroke="#69707F" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div className="business">
                <div className="left">
                    www.sitename.uno owned and operated by Jeton Gaming Ltd, registered in accordance with the laws of the Autonomous Island of Anjouan, registration number: 15774, registered address: Hamchako, Mutsamudu, Autonomous Island of Anjouan, Union of Comoros. www.jetton.uno licensed and regulated by the Government of the
                </div>
                <div className="right">
                    <Methods.CashApp />
                    <Methods.Visa />
                    <Methods.MasterCard />
                    <Methods.Bitcoin />
                    <Methods.Ton />
                    <Methods.Ethereum />
                    <Methods.Litecoin />
                    <Methods.USDT />
                    <Methods.USDC />
                    <div className="more">+41</div>
                </div>
            </div>
        </div>
    )
}