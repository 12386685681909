import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.games,
    props.modals.data.game,
    (games, data) => {
        const game = games.filter(g => g.slug === data.game)[0] || false

        return {
            game
        }
    }
)