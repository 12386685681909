import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/components/header/right';

import Guest from './guest';
import User from './user';

export default () => {
    const {isAuth} = useSelector(Selector);

    return (
        <div className="right">
            {isAuth ? <User /> : <Guest />}
        </div>
    )
}