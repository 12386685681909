import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/modals/wallet/methods';

import CashappDeposit from './cashapp-deposit/index';
import CryptoDeposit from './crypto-deposit/index';

export default () => {
    const {method} = useSelector(Selector);

    if(method === 'cashapp-deposit') return <CashappDeposit />
    if(method === 'crypto-deposit') return <CryptoDeposit />
    return <></>;
}