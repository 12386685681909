import Silver1 from './silver1';
import Silver2 from './silver2';
import Silver3 from './silver3';
import Gold1 from './gold1';
import Gold2 from './gold2';
import Gold3 from './gold3';
import Diamond1 from './diamond1';
import Diamond2 from './diamond2';
import Diamond3 from './diamond3';
import Lucksy from './lucksy';

const Icons = {
    Silver1,
    Silver2,
    Silver3,
    Gold1,
    Gold2,
    Gold3,
    Diamond1,
    Diamond2,
    Diamond3,
    Lucksy
}

export const getIcon = name => {
    if(typeof Icons[name] === 'undefined')
        return <></>;

    const Icon = Icons[name];

    return <Icon />
}

export default Icons