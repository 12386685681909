import types from './types';

export const set = modal => {
    console.log(modal);

    return {
        type: types.set,
        payload: modal
    }
}

export const updateData = (slug, data) => {
    return {
        type: types.update,
        payload: {
            slug,
            data
        }
    }
}

export const resetModal = slug => {
    return {
        type: types.reset,
        payload: slug
    }
}


// Wallet
export const setWalletPreset = ({
    tab,
    mode,
    method
}) => {
    return {
        type: types.wallet.preset,
        payload: {
            tab,
            mode,
            method
        }
    }
}

export const setWalletTab = tab => {
    return {
        type: types.wallet.tab,
        payload: tab
    }
}