import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/modals/wallet';
import {setPreset} from '../../../reducers/wallet/actions';

import Tabs from './tabs';
import Methods from './methods';

export default () => {
    const {mode} = useSelector(Selector),
          dispatch = useDispatch();

    const modalCloseHandler = () => {
        dispatch(setPreset({
            mode: 'tabs',
            tab: 'deposit',
            method: null
        }));
    }

    useEffect(() => {
        document.addEventListener('modal.close', modalCloseHandler);
        return () => document.removeEventListener('modal.close', modalCloseHandler);
    }, []);

    return (
        <div className="modal wallet animate__animated animate__fadeInUp animate__faster">
            {mode === "tabs" && <Tabs />}
            {mode === "methods" && <Methods />}
        </div>
    )
}