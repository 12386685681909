import {useRef, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/vip/levels';
import {useLocation} from 'react-router-dom';
import {getIcon} from './levels/index';
import {Swiper, SwiperSlide} from 'swiper/react';
import Numeric from '../../components/numeric';

export default () => {
    const {levels, current, next} = useSelector(Selector),
          [active, setActive] = useState(current.icon),
          location = useLocation(),
          swiperRef = useRef();

    const selected_level = levels.filter(lvl => lvl.icon === active)[0] || false,
          selected_wheel_sectors = selected_level ? selected_level.preset.wheels_sectors[1].sort((a,b) => {
            if(a.value < b.value) return -1;
            if(a.value > b.value) return 1;
            return 0;
          }) : false;

    const getSlidesCount = () => {
        let slides_count = 'auto';
    
        if(document.body.offsetWidth >= 1024) {
            slides_count = 5; // * - 1024
        } else if(document.body.offsetWidth >= 882) {
            slides_count = 4; // 1023 - 882
        } else if(document.body.offsetWidth >= 732) {
            slides_count = 3; // 881 - 732
        } else if(document.body.offsetWidth >= 580) {
            slides_count = 2; // 731 - 500
        } else {
            slides_count = 'auto';
        }
    
        return slides_count;
    }

    const resizeHandler = e => {
        if(swiperRef.current)
            swiperRef.current.params.slidesPerView = getSlidesCount();
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);


    return (
        <div className="vip-levels">
            <div className="top">
                <div className="title">VIP Levels</div>
            </div>
            <div className="levels">
                {selected_level && (
                    <div className="details">
                        <div className="level_info">
                            <div className="level">
                                {getIcon(selected_level.icon)}
                                <div className="info">
                                    <div className="name">{selected_level.name}</div>
                                    {
                                        selected_level.default
                                        ? <div className="xp">Default level</div>
                                        : <div className="xp">From <b><Numeric value={selected_level.xp.from} fixedDecimalScale={0} prefix={''} /> XP</b></div>
                                    }
                                </div>
                            </div> 
                            {!selected_level.default && (
                                <div className="bonus">
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.14536 5.02085H6.99953C6.93084 5.01805 6.86375 4.99932 6.80355 4.96614C6.74335 4.93296 6.69169 4.88624 6.65265 4.82966C6.61361 4.77308 6.58827 4.7082 6.57861 4.64014C6.56896 4.57209 6.57526 4.50272 6.59703 4.43751C6.80119 3.92418 6.98786 3.39918 7.13953 2.88585C7.26786 2.48918 7.76953 1.24668 9.02953 1.24668C9.52059 1.23094 9.99784 1.41067 10.3565 1.74641C10.7153 2.08215 10.9261 2.54648 10.9429 3.03751C10.9429 4.16335 9.66536 4.62418 9.19286 4.78751C8.52485 4.96183 7.83546 5.04039 7.14536 5.02085ZM9.02369 2.10418C8.35286 2.10418 8.04953 2.88001 7.97369 3.11918C7.86869 3.45751 7.75786 3.79585 7.63536 4.13418C8.05356 4.13152 8.46951 4.07266 8.87203 3.95918C9.20453 3.84251 10.0387 3.52751 10.0387 3.02001C10.0207 2.76575 9.90497 2.52833 9.71573 2.35758C9.52648 2.18682 9.27846 2.096 9.02369 2.10418Z" fill="#F99737"/>
                                        <path d="M6.83677 5.02082C6.16167 5.03271 5.48822 4.95025 4.83594 4.77582C3.38344 4.30332 3.08594 3.56249 3.08594 3.02582C3.08838 2.79915 3.13666 2.57531 3.22789 2.36779C3.31912 2.16027 3.4514 1.97336 3.61677 1.81832C3.9845 1.44838 4.4835 1.23872 5.0051 1.23499C5.9851 1.23499 6.48094 2.15082 6.84844 2.80415C7.08726 3.33973 7.28234 3.89376 7.43177 4.46082C7.44984 4.52517 7.45293 4.5928 7.44079 4.65853C7.42866 4.72426 7.40163 4.78633 7.36177 4.83999C7.32313 4.89417 7.27249 4.93868 7.2138 4.97005C7.1551 5.00142 7.08996 5.0188 7.02344 5.02082H6.83677ZM4.94094 2.10415C4.66392 2.11608 4.40242 2.23533 4.21177 2.43665C4.12862 2.50998 4.06139 2.59957 4.01423 2.69991C3.96708 2.80024 3.941 2.90918 3.9376 3.01999C3.9376 3.37582 4.36927 3.71999 5.10427 3.95915C5.53315 4.07001 5.97383 4.12877 6.41677 4.13415C6.32242 3.8235 6.20744 3.51949 6.0726 3.22415C5.73427 2.57665 5.4076 2.11582 4.95844 2.10415H4.94094Z" fill="#F99737"/>
                                        <path d="M12.3952 6.47915V5.74998C12.3952 5.32453 12.2262 4.9165 11.9253 4.61566C11.6245 4.31482 11.2165 4.14581 10.791 4.14581H3.20768C2.78223 4.14581 2.3742 4.31482 2.07337 4.61566C1.77253 4.9165 1.60352 5.32453 1.60352 5.74998V6.47915H12.3952ZM7.43685 7.35415V13.7708H10.791C11.2165 13.7708 11.6245 13.6018 11.9253 13.301C12.2262 13.0001 12.3952 12.5921 12.3952 12.1666V7.35415H7.43685ZM6.56185 7.35415H1.60352V12.1666C1.60352 12.5921 1.77253 13.0001 2.07337 13.301C2.3742 13.6018 2.78223 13.7708 3.20768 13.7708H6.56185V7.35415Z" fill="#F99737"/>
                                    </svg>

                                    ${selected_level.bonus}
                                </div>
                            )}
                        </div>
                        <div className="benefits">
                            BENEFITS <hr />
                        </div>
                        <div className="list">
                            <div className="item">
                                <div className="name">Weekly Cashback</div>
                                <div className="value green1">{selected_level.preset.cashback.weekly}%</div>
                            </div>

                            <div className="item">
                                <div className="name">Monthly Cashback</div>
                                <div className="value blue1">{selected_level.preset.cashback.monthly}%</div>
                            </div>

                            <div className="item">
                                <div className="name">Referral Royalty</div>
                                <div className="value green2">{selected_level.preset.referrals.royalty}%</div>
                            </div>

                            {selected_wheel_sectors && (
                                <div className="item">
                                    <div className="name">Wheel Cash</div>
                                    <div className="value blue2">${selected_wheel_sectors[0].value} - {selected_wheel_sectors[selected_wheel_sectors.length - 1].value}</div>
                                </div>
                            )}

                            <div className="item">
                                <div className="name">Personal Bonuses</div>
                                {
                                    selected_level.preset.personal_bonuses
                                    ? <div className="value green3">Yes</div>
                                    : <div className="value red">No</div>
                                }
                            </div>

                            <div className="item">
                                <div className="name">Personal Manager</div>
                                {
                                    selected_level.preset.personal_manager
                                    ? <div className="value green3">Yes</div>
                                    : <div className="value red">No</div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                <div className="list">
                    <Swiper
                        speed={500}
                        slidesPerView={getSlidesCount()}
                        spaceBetween={20}
                        initialSlide={levels.findIndex(lvl => lvl.icon === current.icon)}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        >
                        {levels.map((lvl, key) => {
                            return (
                                <SwiperSlide className={`${active === lvl.icon ? 'active-slide' : ''}`}>
                                    <div className={`level ${lvl.icon.replace(/[^a-z]/gi, '').toLowerCase()} ${active === lvl.icon ? 'active' : ''}`} key={key} onClick={e => {
                                        if(swiperRef.current) {
                                            swiperRef.current.slideTo(levels.findIndex(l => l.icon === lvl.icon));
                                            setActive(lvl.icon);
                                        }
                                    }}>
                                        <div className="icon">
                                            {getIcon(lvl.icon)}
                                        </div>
                                        <span>{lvl.name}</span>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}