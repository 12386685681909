export default () => (
<svg width="163" height="154" viewBox="0 0 163 154" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M81.429 148.74L157.659 93.36L128.539 3.75H34.319L5.20898 93.36L81.429 148.74Z" fill="#7D7B8C"/>
    <path d="M131.259 0H31.5891L0.789062 94.79L81.4191 153.37L162.049 94.79L131.259 0ZM16.7391 89.61L41.4491 13.57H121.409L146.119 89.61L81.4391 136.61L16.7491 89.61H16.7391Z" fill="url(#paint0_radial_999_6004)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M41.4492 13.5701L81.4292 136.6L121.409 13.5701H41.4492Z" fill="#817F90"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.4287 90.8099L39.4688 70.1799V75.6399L81.4287 106.13L123.389 75.6399V70.1799L81.4287 90.8099Z" fill="#908E9D"/>
    </g>
    <path d="M131.259 0H31.5887L24.4688 21.93C28.8987 22.13 33.4888 22.69 38.1888 23.59L41.4487 13.57H121.409L146.119 89.61L136.739 96.43C138.849 100.53 140.719 104.77 142.319 109.14L162.059 94.8L131.259 0Z" fill="url(#paint1_radial_999_6004)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M131.259 0H31.5887L24.4688 21.93C28.8987 22.13 33.4888 22.69 38.1888 23.59L41.4487 13.57H121.409L146.119 89.61L136.739 96.43C138.849 100.53 140.719 104.77 142.319 109.14L162.059 94.8L131.259 0Z" fill="url(#paint2_radial_999_6004)"/>
    <path style={{mixBlendMode: 'screen'}} d="M125.799 121.14C125.329 115.86 124.539 110.83 123.479 106.06L81.4291 136.61L16.7391 89.61L34.4791 35.01C29.4391 35.13 24.4791 35.7 19.6691 36.68L0.789062 94.79L81.4191 153.37L125.789 121.13L125.799 121.14Z" fill="url(#paint3_radial_999_6004)"/>
    <path d="M16.75 89.6101L23.14 88.1701L45.4 19.6401H117.46L139.72 88.1701L146.12 89.6101L121.41 13.5701H41.45L16.75 89.6101Z" fill="#858393"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
        <path d="M23.1389 88.1699L35.5989 56.1999C42.9789 37.2699 61.2089 29.0599 81.5289 29.0599C101.929 29.0599 120.229 37.3799 127.539 56.4299L139.729 88.1699L117.459 19.6399H45.3989L23.1289 88.1699H23.1389Z" fill="#908E9D"/>
    </g>
    <path d="M16.75 89.6099L23.14 88.1699L81.43 130.52L139.72 88.1699L146.12 89.6099L81.43 136.6L16.75 89.6099Z" fill="url(#paint4_linear_999_6004)"/>
    <path d="M39.4688 62.8899V70.1799L81.4287 100.67L123.389 70.1799V62.8899L81.4287 93.3799L39.4688 62.8899Z" fill="#858393"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M23.1391 88.1699L45.9991 29.5099C47.0991 26.6799 49.8291 24.8099 52.8691 24.8099H110.319C113.369 24.8099 116.109 26.6899 117.199 29.5399L139.719 88.1699L117.449 19.6399H45.3891L23.1191 88.1699H23.1391Z" fill="#908E9D"/>
    </g>
    <path d="M41.4492 13.5701L45.3992 19.6401H117.459L121.409 13.5701H41.4492Z" fill="#777687"/>
    <path d="M81.4287 93.3799V100.67L39.4688 70.1799V62.8899L81.4287 93.3799Z" fill="#777687"/>
    <path d="M81.4287 78.06L43.4788 50.55L39.4688 62.89L81.4287 93.38L123.389 62.89L119.379 50.55L81.4287 78.06Z" fill="url(#paint5_radial_999_6004)"/>
    <path style={{mixBlendMode: 'screen'}} d="M81.4285 93.38L109.499 72.99C107.139 69.5 104.569 66.2701 101.839 63.2701L81.4385 78.06L43.4885 50.55L39.4785 62.89L81.4385 93.38H81.4285Z" fill="url(#paint6_radial_999_6004)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M107.539 59.1401C110.639 61.9301 113.619 64.8601 116.479 67.9201L123.389 62.9001L119.379 50.5601L107.539 59.1401Z" fill="url(#paint7_radial_999_6004)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M43.4785 50.55L41.9485 58.93C41.6485 60.59 42.2785 62.29 43.6085 63.34L81.4385 93.38L39.4785 62.89L43.4885 50.55H43.4785Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M11.8388 60.8L4.11883 89.7201C3.51883 92.2101 4.69883 95.52 6.75883 97.06L81.4288 153.38L0.798828 94.8001L11.8488 60.8101L11.8388 60.8Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M23.1387 88.1699L78.7487 125.63C80.2187 126.62 82.6387 126.62 84.1087 125.63L139.719 88.1699L81.4287 130.52L23.1387 88.1699Z" fill="#908E9D"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.4297 153.37L153.23 97.9101C158.25 94.1901 159.34 93.3801 157.08 86.2501L142.1 33.3301L162.07 94.7901L81.4397 153.37H81.4297Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M13.6582 55.21L30.4982 6.92C32.2382 2.02 33.5082 0.73 38.6982 0.54L81.4282 0H31.5982L13.6582 55.21Z" fill="#9492A0"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M45.7988 110.72L81.4288 136.6L129.549 101.65L81.4288 138.8L45.7988 110.72Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M53.1895 57.5901L81.4294 78.0601L113.849 54.5701L81.4294 80.5601L53.1895 57.5901Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M78.7285 0H131.259L142.089 33.33L129.259 1.85L78.7285 0Z" fill="#DEDEE2"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M99.639 0C99.199 4.32 101.089 9.15 104.729 13.57H121.419L125.549 26.27C130.779 27.55 135.779 27.59 139.879 26.47L131.259 0H99.629H99.639Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M95.6298 7.53994C92.1498 5.00994 87.9498 4.84994 86.2498 7.17994C85.0398 8.84994 85.4398 11.3499 87.0498 13.5599H99.5398C99.3498 11.5199 97.9398 9.21994 95.6198 7.53994H95.6298Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M41.4686 107.57C40.2086 110.82 42.4886 115.66 47.0786 118.99C51.6686 122.32 56.9786 123 59.6686 120.8L41.4586 107.57H41.4686Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M59.7897 129.66C59.0197 130.84 56.8897 130.82 55.0397 129.61C53.1797 128.4 52.2997 126.46 53.0697 125.28C53.8397 124.1 55.9697 124.12 57.8197 125.33C59.6697 126.54 60.5597 128.48 59.7897 129.66Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M105.359 75.99L119.059 64.21C120.519 62.96 121.209 61.03 120.879 59.13L119.389 50.54L123.399 62.88L105.369 75.98L105.359 75.99Z" fill="#9492A0"/>
    </g>
    <path d="M107.229 118.88C107.229 118.88 110.749 112.32 116.219 108.55C119.489 106.29 125.799 104.52 125.799 104.52C125.799 104.52 119.919 111.91 115.579 115.37C111.239 118.83 107.239 118.87 107.239 118.87L107.229 118.88Z" fill="url(#paint8_linear_999_6004)"/>
    <path d="M121.999 106.98C117.169 107.26 113.299 111.12 111.199 113.84C109.099 116.55 110.289 117.46 112.949 116.28C115.599 115.1 124.039 106.87 121.999 106.99V106.98Z" fill="#898797"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M121.998 106.98C118.728 106.95 114.618 110.04 113.338 111.42C112.048 112.8 114.618 114.92 116.368 113.8C118.118 112.68 123.488 107 121.998 106.98Z" fill="url(#paint9_linear_999_6004)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.549 -8.98999) scale(200.1 200.1)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.549 -8.98999) scale(200.1 200.1)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(135.319 -9.38) scale(155 155)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.28" stop-color="#BDBDC5"/>
            <stop offset="0.63" stop-color="#AFAEB8"/>
            <stop offset="1" stop-color="#9897A4"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.7591 147.28) scale(124.54 124.54)">
            <stop offset="0.32" stop-color="#AEADB7"/>
            <stop offset="0.76" stop-color="#7B7A8B"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_6004" x1="81.43" y1="76.9699" x2="81.43" y2="103.69" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#B0AFB9"/>
            <stop offset="1" stop-color="#D9D9DD"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(132.549 -8.98996) scale(200.1 200.1)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.51" stop-color="#9695A3"/>
            <stop offset="0.92" stop-color="#C5C4CB"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.7585 147.28) scale(124.54 124.54)">
            <stop offset="0.32" stop-color="#AEADB7"/>
            <stop offset="0.76" stop-color="#7B7A8B"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6004" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(135.319 -9.37993) scale(155 155)">
            <stop stop-color="#C3C3CA"/>
            <stop offset="0.28" stop-color="#BDBDC5"/>
            <stop offset="0.63" stop-color="#AFAEB8"/>
            <stop offset="1" stop-color="#9897A4"/>
        </radialGradient>
        <linearGradient id="paint8_linear_999_6004" x1="109.789" y1="115.57" x2="122.579" y2="108.21" gradientUnits="userSpaceOnUse">
            <stop stop-color="#E8E8EB"/>
            <stop offset="0.99" stop-color="#ABAAB5"/>
        </linearGradient>
        <linearGradient id="paint9_linear_999_6004" x1="118.26" y1="113.533" x2="117.313" y2="107.511" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#9998A5"/>
            <stop offset="1" stop-color="#817F90"/>
        </linearGradient>
    </defs>
</svg>
)