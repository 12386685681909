import {useState, useRef, useEffect} from 'react';

export default ({
    type,
    placeholder,
    defaultValue = "",
    validators = [],
    onChange = e => e
}) => {
    const getValidatorsMap = (value) => {
        let map = [];
        for(let i = 0; i < validators.length; i++) map.push(validators[i](value) || false);
        return map;
    }

    const getValidatorResult = value => {
        const map = getValidatorsMap(value);
        return map.length === 0 ? true : map.every(v => v === true);
    }

    const ref = useRef(),
          [value, setValue] = useState(defaultValue),
          [validatorsResults, setValidatorsResults] = useState(getValidatorsMap(defaultValue));

    useEffect(() => {
        setValidatorsResults(getValidatorsMap(value));
        
        onChange({
            value,
            validator: getValidatorResult(value)
        });
    }, [value]);

    return (
        <div className="form-input">
            <div className="label">Your $cashtag</div>
            <div className="input" onClick={e => {
                if(ref.current)
                    ref.current.focus();
            }}>
                <input type={type} placeholder={placeholder} defaultValue={defaultValue} ref={ref} onInput={e => setValue(e.target.value)} />

                <div className="validators">
                    {validatorsResults.map((result, key) => {
                        return <div className={`validator ${result ? 'okay' : 'failed'}`} key={key}></div>
                    })}
                </div>
            </div>
        </div>
    )
}