import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.user.user,
    props.games.games,
    (user, games) => {
        const game = user ? games.filter(g => g.slug === user.game)[0] || false : false;

        return {
            user,
            game
        }
    }
)