const call = ({
    path,
    method="POST",
    body,
    file=false
}) => new Promise(async(resolve) => {
    fetch(`${window.location.origin}/api${path}`, {
        method,
        headers: file ? {} : {
            "Content-Type": "application/json",
        },
        body: file ? body : JSON.stringify(body),
        credentials: 'include'
    }).then(res => res.json()).then(res => resolve(res)).catch(e => {
        return resolve({
            success: false,
            error: "Connection lost"
        });
    });
});

export default {
    call
}