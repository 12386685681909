import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.history,
    (history) => {
        return {
            history: {
                ...history,
                list: history.list.sort((a,b) => {
                    if(a.timestamp > b.timestamp) return -1;
                    if(a.timestamp < b.timestamp) return 1;
                    return 0;
                })
            }
        }
    }
)