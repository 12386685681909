import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.user.logins,
    props.games.games,
    props.modals.data.login_reset,
    props.games.buttons.resetPassword,
    props.games.buttons.updateBalance,
    (logins, games, login_remove, resetPassword, updateBalance) => {
        const login = logins.filter(l => l.game === login_remove.game)[0] || false,
              game = games.filter(g => g.slug === login_remove.game)[0] || false;

        return {
            login,
            game,
            buttons: {
                update: updateBalance,
                reset: resetPassword
            }
        }
    }
)