export default () => (
    <svg width="249" height="155" viewBox="0 0 249 155" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.441 150.23L200.941 94.6502L171.721 4.72021H77.1614L47.9414 94.6502L124.441 150.23Z" fill="#D59D35"/>
        <path d="M174.461 0.950195H74.4315L43.5215 96.0802L124.441 154.87L205.361 96.0802L174.451 0.950195H174.461ZM59.5315 90.8802L84.3315 14.5602H164.571L189.371 90.8802L124.451 138.05L59.5315 90.8802Z" fill="url(#paint0_radial_999_6044)"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
            <path d="M84.3203 14.5703L124.44 138.05L164.57 14.5703H84.3203Z" fill="#D26200"/>
        </g>
        <path d="M174.461 0.950195H74.4313L67.2812 22.9502C71.7213 23.1602 76.3313 23.7202 81.0513 24.6102L84.3213 14.5602H164.561L189.361 90.8802L179.951 97.7202C182.071 101.84 183.951 106.09 185.551 110.48L205.361 96.0802L174.451 0.950195H174.461Z" fill="url(#paint1_radial_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M174.461 0.950195H74.4313L67.2812 22.9502C71.7213 23.1602 76.3313 23.7202 81.0513 24.6102L84.3213 14.5602H164.561L189.361 90.8802L179.951 97.7202C182.071 101.84 183.951 106.09 185.551 110.48L205.361 96.0802L174.451 0.950195H174.461Z" fill="url(#paint2_radial_999_6044)"/>
        <path style={{mixBlendMode: 'screen'}} d="M168.971 122.53C168.491 117.23 167.711 112.19 166.641 107.39L124.441 138.05L59.5215 90.8801L77.3315 36.0801C72.2715 36.2001 67.2915 36.7701 62.4715 37.7601L43.5215 96.0801L124.441 154.87L168.971 122.52V122.53Z" fill="url(#paint3_radial_999_6044)"/>
        <path d="M59.5215 90.8903L65.9415 89.4503L88.2915 20.6703H160.601L182.951 89.4503L189.361 90.8903L164.571 14.5703H84.3215L59.5215 90.8903Z" fill="#D8A342"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
            <path d="M65.9397 89.4502L78.4397 57.3702C85.8397 38.3702 104.14 30.1302 124.53 30.1302C145.01 30.1302 163.37 38.4802 170.71 57.6002L182.95 89.4602L160.6 20.6802H88.2797L65.9297 89.4602L65.9397 89.4502Z" fill="#DA7D2B"/>
        </g>
        <path d="M59.5215 90.8902L65.9415 89.4502L124.441 131.95L182.951 89.4502L189.361 90.8902L124.441 138.05L59.5215 90.8902Z" fill="url(#paint4_linear_999_6044)"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M65.9399 89.4502L88.8799 30.5802C89.9899 27.7402 92.7199 25.8702 95.7699 25.8702H153.43C156.49 25.8702 159.24 27.7602 160.34 30.6202L182.94 89.4602L160.59 20.6802H88.2699L65.9199 89.4602L65.9399 89.4502Z" fill="#DA7D2B"/>
        </g>
        <path d="M84.3203 14.5703L88.2903 20.6703H160.6L164.57 14.5703H84.3203Z" fill="#D4992C"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M54.6113 61.9702L46.8613 90.9902C46.2513 93.4902 47.4513 96.8102 49.5113 98.3502L124.451 154.88L43.5312 96.0902L54.6113 61.9702Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M65.9414 89.4502L121.751 127.04C123.231 128.04 125.651 128.04 127.131 127.04L182.941 89.4502L124.441 131.96L65.9414 89.4502Z" fill="#DA7D2B"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M124.441 154.88L196.501 99.2202C201.541 95.4802 202.631 94.6701 200.361 87.5201L185.321 34.4102L205.361 96.0901L124.441 154.88Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M88.6797 112.07L124.44 138.05L172.73 102.97L124.44 140.26L88.6797 112.07Z" fill="#F5E7CC"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M121.73 0.950195H174.46L185.33 34.4102L172.44 2.81018L121.73 0.950195Z" fill="#F5E7CC"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M142.711 0.950195C142.271 5.2902 144.161 10.1402 147.811 14.5602H164.561L168.701 27.3102C173.951 28.6002 178.971 28.6402 183.081 27.5102L174.451 0.950195H142.711Z" fill="white"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M84.3307 108.91C83.0607 112.17 85.3607 117.03 89.9607 120.37C94.5707 123.72 99.8907 124.4 102.601 122.18L84.3307 108.9V108.91Z" fill="white"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M102.73 131.08C101.96 132.26 99.8203 132.24 97.9603 131.03C96.1003 129.82 95.2103 127.87 95.9803 126.69C96.7503 125.51 98.8903 125.53 100.75 126.74C102.61 127.95 103.5 129.9 102.73 131.08Z" fill="white"/>
        </g>
        <path d="M66.5506 1.62L63.4905 26.94C56.2405 26.9 50.1906 26.87 39.3006 26.88C0.760551 26.93 0.560547 1.5 0.560547 1.5L66.5506 1.62Z" fill="#D4992C"/>
        <path d="M66.5506 1.62L60.0406 21.4C52.7906 21.36 51.6006 21.33 40.7206 21.34C2.18055 21.39 0.560547 1.5 0.560547 1.5L66.5506 1.62Z" fill="url(#paint5_radial_999_6044)"/>
        <path d="M56.7802 31.6903L53.7602 57.0203C46.5102 56.9803 55.3302 57.0203 44.4402 57.0203C5.90016 57.0703 5.66016 31.5703 5.66016 31.5703L56.7802 31.6903Z" fill="#D4992C"/>
        <path d="M56.7802 31.6901L50.3402 51.5101C43.0902 51.4701 56.7802 51.4401 45.8902 51.4501C7.35015 51.5001 5.66016 31.5601 5.66016 31.5601L56.7802 31.6801V31.6901Z" fill="url(#paint6_radial_999_6044)"/>
        <path d="M47.0211 61.7401L19.8711 61.6301C19.8711 61.6301 24.1311 80.6901 43.9911 87.0701L47.0211 61.7401Z" fill="#D4992C"/>
        <path d="M47.0211 61.7401L19.8711 61.6301C19.8711 61.6301 20.7111 75.1901 40.5711 81.5701L47.0211 61.7401Z" fill="url(#paint7_radial_999_6044)"/>
        <path d="M66.551 1.62012L69.801 7.90012L63.491 26.9401L60.041 21.4001L66.551 1.62012Z" fill="#D8A342"/>
        <path d="M56.7798 31.6904L60.0398 37.9104L53.7698 57.0104L50.3398 51.5104L56.7798 31.6904Z" fill="#D8A342"/>
        <path d="M47.0103 61.7502L50.2603 67.9702L43.9903 87.0703L40.5703 81.5703L47.0103 61.7502Z" fill="#D8A342"/>
        <path d="M182.361 1.62L185.421 26.94C192.671 26.9 198.721 26.87 209.611 26.88C248.151 26.93 248.351 1.5 248.351 1.5L182.361 1.62Z" fill="#D4992C"/>
        <path d="M182.361 1.62L188.871 21.4C196.121 21.36 197.311 21.33 208.191 21.34C246.731 21.39 248.351 1.5 248.351 1.5L182.361 1.62Z" fill="url(#paint8_radial_999_6044)"/>
        <path d="M192.121 31.6903L195.141 57.0203C202.391 56.9803 193.571 57.0203 204.461 57.0203C243.001 57.0703 243.241 31.5703 243.241 31.5703L192.121 31.6903Z" fill="#D4992C"/>
        <path d="M192.121 31.6901L198.561 51.5101C205.811 51.4701 192.121 51.4401 203.011 51.4501C241.551 51.5001 243.241 31.5601 243.241 31.5601L192.121 31.6801V31.6901Z" fill="url(#paint9_radial_999_6044)"/>
        <path d="M201.891 61.7401L229.041 61.6301C229.041 61.6301 224.781 80.6901 204.921 87.0701L201.891 61.7401Z" fill="#D4992C"/>
        <path d="M201.891 61.7401L229.041 61.6301C229.041 61.6301 228.201 75.1901 208.341 81.5701L201.891 61.7401Z" fill="url(#paint10_radial_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M182.361 1.62L188.871 21.4C196.121 21.36 197.311 21.33 208.191 21.34C246.731 21.39 248.351 1.5 248.351 1.5L182.361 1.62Z" fill="url(#paint11_radial_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M192.121 31.6901L198.561 51.5101C205.811 51.4701 192.121 51.4401 203.011 51.4501C241.551 51.5001 243.241 31.5601 243.241 31.5601L192.121 31.6801V31.6901Z" fill="url(#paint12_radial_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M201.891 61.7401L229.041 61.6301C229.041 61.6301 228.201 75.1901 208.341 81.5701L201.891 61.7401Z" fill="url(#paint13_radial_999_6044)"/>
        <path d="M182.36 1.62012L179.1 7.90012L185.42 26.9401L188.87 21.4001L182.36 1.62012Z" fill="#D8A342"/>
        <path d="M192.121 31.6904L188.871 37.9104L195.141 57.0104L198.561 51.5104L192.121 31.6904Z" fill="#D8A342"/>
        <path d="M201.901 61.7502L198.641 67.9702L204.911 87.0703L208.341 81.5703L201.901 61.7502Z" fill="#D8A342"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M66.5506 1.62L0.560547 1.5C0.560547 1.5 2.18055 21.39 40.7206 21.34C51.6006 21.33 52.7906 21.36 60.0406 21.4L66.5506 1.62Z" fill="url(#paint14_radial_999_6044)"/>
        <path style={{mixBlendMode: 'screen'}} d="M47.0211 61.7401L19.8711 61.6301C19.8711 61.6301 20.7111 75.1901 40.5711 81.5701L47.0211 61.7401Z" fill="url(#paint15_radial_999_6044)"/>
        <path style={{mixBlendMode: 'screen'}} d="M50.3411 51.5103L54.1411 39.8203C48.9711 41.5103 44.0711 43.7303 39.5611 46.4903C37.7011 47.6303 35.5011 49.0403 33.1211 50.6103C36.8511 51.1503 41.0911 51.4503 45.8911 51.4503C56.7811 51.4403 43.0911 51.4703 50.3411 51.5103Z" fill="url(#paint16_radial_999_6044)"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M56.4297 56.3602L73.3297 7.90018C75.0797 2.98018 76.3497 1.69018 81.5597 1.50018L124.44 0.950195H74.4297L56.4297 56.3602Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M139.56 8.52007C136.06 5.98007 131.85 5.82008 130.15 8.16008C128.93 9.84008 129.34 12.3401 130.96 14.5701H143.5C143.3 12.5201 141.89 10.2101 139.57 8.52007H139.56Z" fill="white"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M144.98 79.9904L155.33 62.0704V56.5904L134.64 62.0704L124.29 44.1504L113.95 62.0704L93.2598 56.5904V62.0704L103.6 79.9904L93.2598 92.4204V97.9104H113.95L124.29 115.82L134.64 97.9104H155.33V92.4204L144.98 79.9904Z" fill="#DA7D2B"/>
        </g>
        <path d="M144.98 74.5004L155.33 56.5904V49.8904L134.64 56.5904L124.29 38.6704L113.95 56.5904L93.2598 49.8904V56.5904L103.6 74.5004L93.2598 85.7204V92.4204H113.95L124.29 110.34L134.64 92.4204H155.33V85.7204L144.98 74.5004Z" fill="#D8A342"/>
        <path d="M144.98 67.8002L155.33 49.8902H134.64L124.29 31.9702L113.95 49.8902H93.2598L103.6 67.8002L93.2598 85.7202H113.95L124.29 103.64L134.64 85.7202H155.33L144.98 67.8002Z" fill="url(#paint17_radial_999_6044)"/>
        <path d="M155.331 85.7202H134.641V92.4202H155.331V85.7202Z" fill="#D4992C"/>
        <path d="M113.95 92.4202L124.29 110.34V103.64L113.95 85.7202H93.2598V92.4202H113.95Z" fill="#D4992C"/>
        <path style={{mixBlendMode: 'screen'}} d="M117.23 44.2002L113.95 49.8902H93.2598L103.6 67.8102L93.2598 85.7302H113.95L124.29 103.65L134.64 85.7302H155.33L144.99 67.8102L146.15 65.8102C137.86 56.3202 127.92 49.1402 117.24 44.2102L117.23 44.2002Z" fill="url(#paint18_radial_999_6044)"/>
        <path d="M93.2598 49.8901L103.6 67.8001L101.67 71.1501L93.2598 56.5901V49.8901Z" fill="#D4992C"/>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M93.2598 49.8901L105.41 65.2901C106.8 67.1501 107.22 67.8101 105.41 70.3301L93.2598 85.7301L103.6 67.8101L93.2598 49.8901Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.8">
            <path d="M124.289 31.9702L134.269 51.6502L155.329 49.8902H134.639L124.289 31.9702Z" fill="#FEFAF7"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M93.2598 85.7203L115.89 83.7703L124.29 103.64L113.95 85.7203H93.2598Z" fill="#FEFAF7"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M124.289 103.64L132.359 83.8701C133.239 81.9801 134.399 81.7801 135.739 82.0301L155.319 85.7201H134.629L124.279 103.64H124.289Z" fill="#DDAF59"/>
        </g>
        <path style={{mixBlendMode: 'soft-light'}} d="M150.06 59.0004L155.32 49.8804H138.82C142.7 52.7304 146.45 55.7704 150.05 59.0004H150.06Z" fill="url(#paint19_radial_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M131.85 45.0602L124.29 31.9702L120.58 38.4002C124.41 40.4402 128.17 42.6602 131.85 45.0602Z" fill="url(#paint20_radial_999_6044)"/>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M131.829 67.0804C130.219 69.8604 125.469 70.1305 121.209 67.6705C116.949 65.2105 114.809 60.9604 116.409 58.1804C118.019 55.4004 122.769 55.1304 127.029 57.5904C131.289 60.0504 133.429 64.3004 131.829 67.0804Z" fill="#FC6500"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M116.45 68.7902C115.94 69.6802 114.24 69.6702 112.67 68.7602C111.1 67.8502 110.24 66.3902 110.75 65.5002C111.26 64.6102 112.96 64.6202 114.53 65.5302C116.1 66.4402 116.96 67.9002 116.45 68.7902Z" fill="#FC6500"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M197.29 19.8904C192.9 19.4504 189.83 17.8604 187.83 13.9204L182.35 1.61035L188.86 21.3904C196.11 21.3504 197.3 21.3204 208.18 21.3304C214.32 21.3304 219.52 20.8404 223.93 19.9904C214.38 21.1404 204.57 20.6004 197.29 19.8804V19.8904Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M203.011 51.4504C212.331 51.4604 219.491 50.3004 225.001 48.5404C217.081 50.7404 210.431 50.5904 205.371 50.2804C201.891 50.0704 199.061 47.5104 197.581 44.3504L192.131 31.6904L198.571 51.5104C205.821 51.4704 192.131 51.4404 203.021 51.4504H203.011Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M208.34 81.5703C212.64 80.1903 216.05 78.4702 218.75 76.6202C215.55 77.8402 209.55 78.7102 209.55 78.7102L201.9 61.7402V61.7603L208.34 81.5602V81.5703Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M40.7203 21.34C51.6003 21.33 52.7903 21.36 60.0403 21.4L65.2003 5.73001L61.3703 14.28C59.5303 18.79 58.4403 20.27 53.9403 20.31C49.0903 20.35 39.9503 20.25 35.3003 20.07C15.0703 19.28 3.15031 10.53 0.570312 1.5C0.570312 1.5 2.19032 21.39 40.7303 21.34H40.7203Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M51.2 45.6701C49.98 48.2401 48.66 50.3901 45.82 50.2901C42.88 50.1901 34.63 50.1301 29.91 49.0601C18.97 46.5901 12.43 42.5201 8 37.5201C11.72 43.3901 21.31 51.4901 45.89 51.4601C56.78 51.4501 43.09 51.4801 50.34 51.5201L55.67 35.1201L51.2 45.6801V45.6701Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
            <path d="M40.5711 81.5701L46.1911 64.2801C45.1311 66.4801 43.0411 71.5801 40.9211 76.3101C39.4711 79.5201 38.0911 79.1201 35.8611 78.1901C24.7711 73.5801 22.2211 68.0801 19.8711 61.6301C19.8711 61.6301 20.7111 75.1901 40.5711 81.5701Z" fill="#DDAF59"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M182.361 1.62L242.711 2.64999C245.141 2.68999 246.261 4.51001 245.211 6.70001C244.121 8.98001 242.351 11.71 239.461 14.06C247.791 8.4 248.351 1.5 248.351 1.5L182.361 1.62Z" fill="#F5E7CC"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M192.121 31.6901L236.641 32.7401C241.841 32.8501 241.001 35.0601 239.641 37.7601C238.801 39.4301 237.661 41.25 236.151 42.7701C242.731 37.4201 243.231 31.5601 243.231 31.5601L192.111 31.6801L192.121 31.6901Z" fill="#F5E7CC"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M224.37 71.6001C228.74 66.3901 229.04 61.6301 229.04 61.6301L201.93 61.7401H201.9L224.31 62.6901C227.62 62.8901 227.02 64.1401 226.64 65.7401C226.19 67.5901 225.46 69.7701 224.38 71.5901L224.37 71.6001Z" fill="#F5E7CC"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M18.0996 1.5L66.5496 1.62L64.9196 6.57001C64.9196 4.03001 63.7096 2.75 60.7896 2.72L18.0996 1.5Z" fill="#FEFAF7"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M18.0996 31.6904H56.7796L54.9996 37.1704L55.1896 35.3504C55.2896 33.6304 54.6796 32.7304 51.5396 32.5804L18.0996 31.6904Z" fill="#FEFAF7"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.5">
            <path d="M25.0898 61.6501L47.0198 61.7401L45.3498 66.8502L45.2498 64.4802C45.1898 63.1502 44.8298 62.7001 43.0198 62.5801L25.0898 61.6501Z" fill="#FEFAF7"/>
        </g>
        <g style={{mixBlendMode: 'screen'}} opacity="0.85">
            <path d="M188.871 21.4003C193.541 21.3803 195.701 21.3503 199.551 21.3503C206.381 17.0703 210.941 10.7103 211.581 3.30029C211.631 2.72029 211.651 2.15031 211.651 1.57031L182.371 1.6203L188.881 21.4003H188.871Z" fill="white"/>
        </g>
        <path d="M150.48 120.1C150.48 120.1 154 113.54 159.47 109.77C162.74 107.51 169.05 105.74 169.05 105.74C169.05 105.74 163.17 113.13 158.83 116.59C154.49 120.05 150.49 120.09 150.49 120.09L150.48 120.1Z" fill="url(#paint21_linear_999_6044)"/>
        <path d="M165.251 108.21C160.421 108.49 156.551 112.35 154.451 115.07C152.351 117.78 153.541 118.69 156.201 117.51C158.851 116.33 167.291 108.1 165.251 108.22V108.21Z" fill="#D9A648"/>
        <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M165.25 108.21C161.98 108.18 157.87 111.27 156.59 112.65C155.3 114.03 157.87 116.15 159.62 115.03C161.37 113.91 166.74 108.23 165.25 108.21Z" fill="url(#paint22_linear_999_6044)"/>
        <path style={{mixBlendMode: 'soft-light'}} d="M5.66992 31.5703C5.66992 31.5703 5.94992 34.8003 8.77992 38.6503C12.9899 36.2703 17.6799 33.8403 22.6799 31.6103L5.66992 31.5703Z" fill="url(#paint23_radial_999_6044)"/>
        <defs>
            <radialGradient id="paint0_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83019) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint1_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.8302) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint2_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.641 -3.7898) scale(167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint3_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.5615 142.52) scale(123.54 123.54)">
                <stop offset="0.32" stop-color="#E5C281"/>
                <stop offset="0.76" stop-color="#D59C33"/>
            </radialGradient>
            <linearGradient id="paint4_linear_999_6044" x1="124.441" y1="78.2002" x2="124.441" y2="105.01" gradientUnits="userSpaceOnUse">
                <stop offset="0.63" stop-color="#E6C485"/>
                <stop offset="1" stop-color="#F3E2C4"/>
            </linearGradient>
            <radialGradient id="paint5_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint6_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.02 6.83004) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint7_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83011) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint8_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint9_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83004) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint10_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.021 6.83011) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint11_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.641 -3.79) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint12_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.641 -3.78996) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint13_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.641 -3.78988) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint14_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.641 -3.79) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint15_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.5611 142.52) scale(123.54 123.54)">
                <stop offset="0.32" stop-color="#E5C281"/>
                <stop offset="0.76" stop-color="#D59C33"/>
            </radialGradient>
            <radialGradient id="paint16_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.5611 142.52) scale(123.54 123.54)">
                <stop offset="0.32" stop-color="#E5C281"/>
                <stop offset="0.76" stop-color="#D59C33"/>
            </radialGradient>
            <radialGradient id="paint17_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.02 6.8302) scale(194.24 194.24)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.51" stop-color="#DEB15D"/>
                <stop offset="0.92" stop-color="#ECD3A4"/>
            </radialGradient>
            <radialGradient id="paint18_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.5598 142.52) scale(123.54 123.54)">
                <stop offset="0.32" stop-color="#E5C281"/>
                <stop offset="0.76" stop-color="#D59C33"/>
            </radialGradient>
            <radialGradient id="paint19_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.64 -3.78959) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <radialGradient id="paint20_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.64 -3.78978) scale(167.44 167.44)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
            <linearGradient id="paint21_linear_999_6044" x1="153.03" y1="116.8" x2="165.83" y2="109.44" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F8EEDB"/>
                <stop offset="0.99" stop-color="#E4C07C"/>
            </linearGradient>
            <linearGradient id="paint22_linear_999_6044" x1="161.523" y1="114.756" x2="160.562" y2="108.732" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="0.44" stop-color="#DEB361"/>
                <stop offset="1" stop-color="#D7A03B"/>
            </linearGradient>
            <radialGradient id="paint23_radial_999_6044" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(168.19 2.90036) scale(203.82 203.82)">
                <stop stop-color="#EAB587"/>
                <stop offset="0.3" stop-color="#E8B481"/>
                <stop offset="0.67" stop-color="#E3B372"/>
                <stop offset="1" stop-color="#DEB25F"/>
            </radialGradient>
        </defs>
    </svg>
)