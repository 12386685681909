import {navigate} from '../../../utils/navigation';

export default () => {

    return (
        <div className="vip-plug" onClick={e => navigate('/vip')}>
            <div className="layer">
                <div className="icon">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9816 19.5444L22.9816 10.9601L14.3973 10.9601" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.9607 22.9809L22.8613 11.0803" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="layer">
                <div className="vip-1"></div>
            </div>
            <div className="layer">
                <div className="vip-2"></div>
            </div>
            <div className="layer">
                <div className="crown small"></div>
            </div>
            <div className="layer">
                <div className="crown"></div>
            </div>
            <div className="layer">
                <div className="content">
                    <div className="title">Increase your income with VIP system</div>
                    <div className="descriptions">
                        The higher your VIP level, the more royalties we pay!
                    </div>
                </div>
                <div className="right"></div>
            </div>
        </div>
    )
}