import {dispatch} from '../lib/store';
import {set, updateData} from '../reducers/modals/actions';

const getActiveModal = () => {
    const modal = document.querySelector('.modal-overlay .modal');
    return modal
}

const open = (key, data = null) => {
    if(data)
        dispatch(updateData(key, data));

    // Has active modal
    const active_modal = getActiveModal();

    if(active_modal) {
        active_modal.classList.remove('animate__fadeInUp');
        active_modal.classList.add('animate__fadeOutDown');

        setTimeout(() => {
            document.dispatchEvent(new CustomEvent('modal.close'));
            
            setTimeout(() => {
                dispatch(set(key))
            }, 100);
        }, 500);
    } else {
        dispatch(set(key));
    }
}

const close = () => {
    // Есть ли уже модалка
    const active_modal = getActiveModal(),
          overlay = document.querySelector(`.modal-overlay`);

    if(active_modal) {
        active_modal.classList.remove('animate__fadeInUp');
        active_modal.classList.add('animate__fadeOutDown');
        overlay.classList.remove('animate__fadeIn');
        overlay.classList.add('animate__fadeOut');

        setTimeout(() => {
            document.dispatchEvent(new CustomEvent('modal.close'));
            
            setTimeout(() => {
                dispatch(set(null))
            }, 100);
        }, 500);
    } else {
        dispatch(set(null));
    }
}

export default {
    open,
    close
}