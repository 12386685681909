import {useSelector} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/cashapp_deposit';

import Create from './create';
import Confirm from './confirm';

export default () => {
    const {stage} = useSelector(Selector);

    if(stage === 'create') return <Create />
    if(stage === 'confirm') return <Confirm />
    return <>{stage}</>;
}