import types from './types';

const InitialState = {
    loaded: false,
    levels: []
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.loaded:
            return {
                ...state,
                loaded: true,
                levels: payload.levels
            }
        default:
            return state;
    }
}