import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.games,
    props.games.history,
    props.modals.data.deposit_failed,
    (games, history, data) => {
        const transaction = history.list.filter(h => h.transactionId === data.transactionId)[0] || false,
              game = transaction ? games.filter(g => g.slug === transaction.game)[0] || false : false;

        return {
            transaction,
            game,
            reason: data.reason,
            param: data.param
        }
    }
)