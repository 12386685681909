export default () => (
    <svg width="63" height="56" viewBox="0 0 63 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.8897 27.0826L62.3105 2.0926C52.1355 0.712695 41.7483 0 31.1943 0C20.6403 0 10.2531 0.712695 0.078125 2.0926L14.4989 27.0826L31.1943 56L47.8897 27.0826Z" fill="#999999"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.8652 6.33846C46.3288 5.58027 38.762 5.20117 31.1953 5.20117C23.6286 5.20117 16.0618 5.58027 8.52539 6.33846L31.1953 45.6128L53.8652 6.33846Z" fill="#9712FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1953 45.5975L31.1953 21.3202L8.52539 6.33838L31.1953 45.5975Z" fill="url(#paint0_radial_838_3854)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52539 6.33846L31.1953 21.3203L53.8652 6.33846C46.3288 5.58027 38.762 5.20117 31.1953 5.20117C23.6286 5.20117 16.0618 5.58027 8.52539 6.33846Z" fill="url(#paint1_radial_838_3854)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1934 21.3202L31.1934 45.5975L53.8633 6.33838L31.1934 21.3202Z" fill="url(#paint2_radial_838_3854)"/>
        <path style={{mixBlendMode: 'screen'}} fill-rule="evenodd" clip-rule="evenodd" d="M13.8496 9.85645L30.1811 21.9875L31.197 42.3828L32.213 21.9875L40.8716 14.9212L31.197 20.0465L13.8496 9.85645Z" fill="url(#paint3_radial_838_3854)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52439 6.33846C16.0608 5.58027 23.6276 5.20117 31.1943 5.20117C38.761 5.20117 46.3278 5.58027 53.8642 6.33846L62.3105 2.10775C52.1355 0.727844 41.7483 0.0151367 31.1943 0.0151367C20.6403 0.0151367 10.2531 0.727844 0.078125 2.10775L8.52439 6.33846Z" fill="url(#paint4_radial_838_3854)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1958 45.5975L8.52587 6.3384L0.0644531 2.09253L14.5004 27.0825L31.1958 55.9999V45.5975Z" fill="url(#paint5_radial_838_3854)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.8633 6.3384L31.1934 45.5975V55.9999L47.8887 27.0825L62.3095 2.09253L53.8633 6.3384Z" fill="url(#paint6_radial_838_3854)"/>
        <defs>
            <radialGradient id="paint0_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.6034 6.01994) scale(41.6853 41.6853)">
                <stop stop-color="#57F9FF"/>
                <stop offset="0.65" stop-color="#1AB8E4"/>
                <stop offset="1" stop-color="#007FD9" stop-opacity="0.62"/>
            </radialGradient>
            <radialGradient id="paint1_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.4098 13.9052) scale(45.8251 45.8251)">
                <stop stop-color="#BFFFFA"/>
                <stop offset="0.31" stop-color="#8EFDFD"/>
                <stop offset="0.78" stop-color="#49FAE5"/>
                <stop offset="1" stop-color="#2FFAFA"/>
            </radialGradient>
            <radialGradient id="paint2_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.85705 6.01994) scale(44.7181 44.7181)">
                <stop stop-color="#00B07E"/>
                <stop offset="0.64" stop-color="#007A57"/>
                <stop offset="1" stop-color="#006159"/>
            </radialGradient>
            <radialGradient id="paint3_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.0143 15.7248) scale(46.2194 46.2193)">
                <stop stop-color="#F08CFF"/>
                <stop offset="0.04" stop-color="#E284F0"/>
                <stop offset="0.25" stop-color="#9E5FA8"/>
                <stop offset="0.45" stop-color="#66406C"/>
                <stop offset="0.63" stop-color="#3A273D"/>
                <stop offset="0.78" stop-color="#1A161B"/>
                <stop offset="0.91" stop-color="#060B07"/>
                <stop offset="1" stop-color="#000800"/>
            </radialGradient>
            <radialGradient id="paint4_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(3.18671 -3.12378) scale(78.4576 78.4577)">
                <stop stop-color="#D3E1ED"/>
                <stop offset="1" stop-color="#7B8EBD"/>
            </radialGradient>
            <radialGradient id="paint5_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.2183 -22.6852) scale(82.8855 82.8855)">
                <stop stop-color="#95B0C7"/>
                <stop offset="1" stop-color="#536694"/>
            </radialGradient>
            <radialGradient id="paint6_radial_838_3854" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-35.2849 15.3305) scale(107.314 107.314)">
                <stop stop-color="#6B8091"/>
                <stop offset="1" stop-color="#3F4D70"/>
            </radialGradient>
        </defs>
    </svg>
)