export default () => (
<svg width="303" height="171" viewBox="0 0 303 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M93.8906 1.26013L90.8406 26.4901C83.6206 26.4501 77.5906 26.4201 66.7406 26.4301C28.3406 26.4801 28.1406 1.14014 28.1406 1.14014L93.8906 1.26013Z" fill="#200968"/>
    <path d="M93.8906 1.26013L87.4006 20.9701C80.1806 20.9301 78.9906 20.9001 68.1506 20.9101C29.7506 20.9601 28.1406 1.14014 28.1406 1.14014L93.8906 1.26013Z" fill="url(#paint0_radial_999_5883)"/>
    <path d="M84.1605 31.22L81.1605 56.4501C73.9405 56.4101 82.7205 56.4501 71.8705 56.4501C33.4705 56.5001 33.2305 31.0901 33.2305 31.0901L84.1605 31.2101V31.22Z" fill="#200968"/>
    <path d="M84.1607 31.22L77.7407 50.97C70.5207 50.93 84.1607 50.9 73.3107 50.91C34.9107 50.96 33.2207 31.0901 33.2207 31.0901L84.1507 31.2101L84.1607 31.22Z" fill="url(#paint1_radial_999_5883)"/>
    <path d="M74.4289 61.17L47.3789 61.0601C47.3789 61.0601 51.6289 80.06 71.4189 86.41L74.4389 61.17H74.4289Z" fill="#200968"/>
    <path d="M74.4289 61.17L47.3789 61.0601C47.3789 61.0601 48.2189 74.5701 68.0089 80.9301L74.4389 61.17H74.4289Z" fill="url(#paint2_radial_999_5883)"/>
    <path d="M84.1602 31.2202L87.4002 37.4202L81.1502 56.4503L77.7402 50.9702L84.1602 31.2202Z" fill="#4B0EA7"/>
    <path d="M74.4189 61.1702L77.6589 67.3702L68.2689 95.9902L62.3789 97.8002L74.4189 61.1702Z" fill="#4B0EA7"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M93.8906 1.26013L28.1406 1.14014C28.1406 1.14014 29.7506 20.9601 68.1506 20.9101C79.0006 20.9001 80.1806 20.9301 87.4006 20.9701L93.8906 1.26013Z" fill="url(#paint3_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M77.7401 50.97L81.5201 39.3201C76.3701 41.0001 71.4901 43.22 67.0001 45.97C65.1401 47.11 62.9601 48.5101 60.5801 50.0801C64.3001 50.6201 68.5201 50.92 73.3101 50.92C84.1601 50.91 70.5201 50.94 77.7401 50.98V50.97Z" fill="url(#paint4_radial_999_5883)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M68.1506 20.9101C79.0006 20.9001 80.1806 20.9301 87.4006 20.9701L92.5406 5.3501L88.7206 13.8701C86.8906 18.3601 85.8006 19.8401 81.3206 19.8801C76.4906 19.9201 67.3806 19.8201 62.7506 19.6401C42.5906 18.8501 30.7206 10.1401 28.1406 1.13013C28.1406 1.13013 29.7506 20.9501 68.1506 20.9001V20.9101Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M78.6006 45.1501C77.3806 47.7101 76.0705 49.8501 73.2405 49.7501C70.3105 49.6501 62.0906 49.5902 57.3906 48.5302C46.4806 46.0702 39.9705 42.0102 35.5605 37.0302C39.2705 42.8802 48.8206 50.9501 73.3206 50.9201C84.1706 50.9101 70.5305 50.9401 77.7505 50.9801L83.0605 34.6401L78.6105 45.1601L78.6006 45.1501Z" fill="#612CB7"/>
    </g>
    <path d="M67.9991 80.9301L73.5992 63.7001C72.5492 65.9001 70.4591 70.9801 68.3391 75.6801C66.8991 78.8801 65.5191 78.48 63.2991 77.55C52.2491 72.95 49.7091 67.48 47.3691 61.05C47.3691 61.05 48.2091 74.56 67.9991 80.92V80.9301Z" fill="#4B0EA7"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M45.6191 1.14014L93.8892 1.26013L92.2691 6.19012C92.2691 3.66012 91.0691 2.3801 88.1591 2.3501L45.6191 1.13013V1.14014Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M45.6191 31.2202H84.1591L82.3892 36.6802L82.5791 34.8702C82.6791 33.1602 82.0692 32.2602 78.9492 32.1102L45.6292 31.2202H45.6191Z" fill="#E6ADFF"/>
    </g>
    <path d="M151.58 149.34L227.8 93.9601L198.68 4.3501H104.47L75.3496 93.9601L151.58 149.34Z" fill="#391054"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M111.641 14.1602L151.611 137.2L191.591 14.1602H111.641Z" fill="#0C363D"/>
    </g>
    <path d="M201.409 0.600098H101.739L70.9395 95.3901L151.569 153.97L232.199 95.3901L201.399 0.600098H201.409ZM86.8895 90.2101L111.599 14.1701H191.559L216.269 90.2101L151.589 137.21L86.8994 90.2101H86.8895Z" fill="url(#paint5_radial_999_5883)"/>
    <path d="M201.409 0.600098H101.739L94.6191 22.5302C99.0491 22.7302 103.639 23.2901 108.339 24.1901L111.599 14.1701H191.559L216.269 90.2101L206.889 97.0302C208.999 101.13 210.869 105.37 212.469 109.74L232.209 95.4001L201.409 0.610107V0.600098Z" fill="url(#paint6_radial_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M201.409 0.600098H101.739L94.6191 22.5302C99.0491 22.7302 103.639 23.2901 108.339 24.1901L111.599 14.1701H191.559L216.269 90.2101L206.889 97.0302C208.999 101.13 210.869 105.37 212.469 109.74L232.209 95.4001L201.409 0.610107V0.600098Z" fill="url(#paint7_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M195.94 121.73C195.47 116.45 194.68 111.42 193.62 106.65L151.57 137.2L86.8797 90.2001L104.62 35.6001C99.5797 35.7201 94.6197 36.2901 89.8097 37.2701L70.9297 95.3801L151.56 153.96L195.93 121.72L195.94 121.73Z" fill="url(#paint8_radial_999_5883)"/>
    <path d="M86.8906 90.2102L93.2806 88.7702L115.551 20.2402H187.601L209.871 88.7702L216.261 90.2102L191.551 14.1602H111.601L86.8906 90.2102Z" fill="#4B0EA7"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
        <path d="M93.2795 88.7703L105.74 56.8002C113.12 37.8702 131.35 29.6602 151.67 29.6602C172.07 29.6602 190.37 37.9803 197.68 57.0303L209.87 88.7703L187.6 20.2402H115.54L93.2695 88.7703H93.2795Z" fill="#892334"/>
    </g>
    <path d="M86.8906 90.21L93.2806 88.77L151.581 131.12L209.871 88.77L216.261 90.21L151.581 137.2L86.8906 90.21Z" fill="url(#paint9_linear_999_5883)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M93.2798 88.7703L116.14 30.1102C117.24 27.2802 119.97 25.4102 123.01 25.4102H180.46C183.51 25.4102 186.25 27.2903 187.34 30.1403L209.86 88.7703L187.59 20.2402H115.53L93.2598 88.7703H93.2798Z" fill="#892334"/>
    </g>
    <path d="M111.6 14.1602L115.55 20.2402H187.6L191.55 14.1602H111.6Z" fill="#200968"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M81.9892 61.3901L74.2692 90.3101C73.6692 92.8001 74.8492 96.1101 76.9092 97.6501L151.579 153.97L70.9492 95.3901L81.9992 61.4001L81.9892 61.3901Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M93.2793 88.77L148.889 126.23C150.359 127.22 152.779 127.22 154.249 126.23L209.859 88.77L151.569 131.12L93.2793 88.77Z" fill="#892334"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M151.58 153.97L223.38 98.5102C228.4 94.7902 229.49 93.9802 227.23 86.8502L212.25 33.9302L232.22 95.3902L151.59 153.97H151.58Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M115.939 111.31L151.579 137.2L199.689 102.24L151.579 139.4L115.939 111.31Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M148.869 0.600098H201.409L212.239 33.9301L199.399 2.45013L148.869 0.600098Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M169.78 0.600098C169.34 4.9201 171.23 9.7501 174.87 14.1601H191.56L195.69 26.8601C200.92 28.1401 205.92 28.1801 210.02 27.0601L201.42 0.600098H169.79H169.78Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M111.609 108.17C110.349 111.42 112.629 116.26 117.219 119.59C121.809 122.92 127.119 123.6 129.809 121.4L111.599 108.17H111.609Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M129.94 130.25C129.17 131.43 127.04 131.41 125.19 130.2C123.33 128.99 122.45 127.05 123.22 125.87C123.99 124.69 126.12 124.71 127.97 125.92C129.83 127.13 130.71 129.07 129.94 130.25Z" fill="#FFF2BB"/>
    </g>
    <path d="M93.8904 1.26025L97.1304 7.52026L90.8404 26.4902L87.4004 20.9702L93.8904 1.26025Z" fill="#4B0EA7"/>
    <path d="M209.279 1.26013L212.329 26.4901C219.549 26.4501 225.579 26.4201 236.429 26.4301C274.829 26.4801 275.029 1.14014 275.029 1.14014L209.279 1.26013Z" fill="#200968"/>
    <path d="M209.279 1.26013L215.769 20.9701C222.989 20.9301 224.179 20.9001 235.019 20.9101C273.419 20.9601 275.029 1.14014 275.029 1.14014L209.279 1.26013Z" fill="url(#paint10_radial_999_5883)"/>
    <path d="M219.01 31.22L222.01 56.4501C229.23 56.4101 220.45 56.4501 231.3 56.4501C269.7 56.5001 269.94 31.0901 269.94 31.0901L219.01 31.2101V31.22Z" fill="#200968"/>
    <path d="M219.01 31.22L225.43 50.97C232.65 50.93 219.01 50.9 229.86 50.91C268.26 50.96 269.95 31.0901 269.95 31.0901L219.02 31.2101L219.01 31.22Z" fill="url(#paint11_radial_999_5883)"/>
    <path d="M228.74 61.17L255.79 61.0601C255.79 61.0601 251.54 80.06 231.75 86.41L228.73 61.17H228.74Z" fill="#200968"/>
    <path d="M228.74 61.17L255.79 61.0601C255.79 61.0601 254.95 74.5701 235.16 80.9301L228.73 61.17H228.74Z" fill="url(#paint12_radial_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M209.279 1.26013L215.769 20.9701C222.989 20.9301 224.179 20.9001 235.019 20.9101C273.419 20.9601 275.029 1.14014 275.029 1.14014L209.279 1.26013Z" fill="url(#paint13_radial_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M219.01 31.22L225.43 50.97C232.65 50.93 219.01 50.9 229.86 50.91C268.26 50.96 269.95 31.0901 269.95 31.0901L219.02 31.2101L219.01 31.22Z" fill="url(#paint14_radial_999_5883)"/>
    <path d="M209.279 1.26025L206.039 7.52026L212.329 26.4902L215.769 20.9702L209.279 1.26025Z" fill="#4B0EA7"/>
    <path d="M219.01 31.2202L215.77 37.4202L222.01 56.4503L225.43 50.9702L219.01 31.2202Z" fill="#4B0EA7"/>
    <path d="M228.75 61.1702L225.51 67.3702L234.94 96.1102L240.77 97.8002L228.75 61.1702Z" fill="#4B0EA7"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M83.8008 55.8101L100.641 7.52014C102.381 2.62014 103.651 1.33014 108.841 1.14014L151.571 0.600098H101.741L83.8008 55.8101Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M166.64 8.14003C163.16 5.61003 158.96 5.45004 157.26 7.78004C156.05 9.45004 156.45 11.95 158.06 14.16H170.55C170.36 12.12 168.95 9.82003 166.63 8.14003H166.64Z" fill="#FFF2BB"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M224.16 19.47C219.78 19.03 216.73 17.45 214.73 13.53L209.27 1.27002L215.76 20.98C222.98 20.94 224.17 20.91 235.01 20.92C241.13 20.92 246.31 20.43 250.7 19.59C241.18 20.74 231.41 20.2 224.16 19.48V19.47Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M229.86 50.9202C239.15 50.9302 246.29 49.7703 251.77 48.0203C243.88 50.2103 237.25 50.0602 232.21 49.7502C228.74 49.5402 225.92 46.9903 224.45 43.8403L219.02 31.2202L225.44 50.9702C232.66 50.9302 219.02 50.9002 229.87 50.9102L229.86 50.9202Z" fill="#612CB7"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M235.17 80.9302C239.46 79.5502 242.85 77.8402 245.54 76.0002C242.35 77.2102 236.37 78.0902 236.37 78.0902L228.75 61.1802V61.2002L235.17 80.9302Z" fill="#4B0EA7"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M209.279 1.26013L269.409 2.2901C271.829 2.3301 272.949 4.14013 271.899 6.32013C270.809 8.59013 269.049 11.3101 266.169 13.6501C274.469 8.01015 275.029 1.14014 275.029 1.14014L209.279 1.26013Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M219.01 31.2201L263.37 32.2601C268.55 32.3701 267.71 34.5701 266.36 37.2701C265.53 38.9301 264.39 40.7501 262.88 42.2701C269.44 36.9301 269.94 31.1101 269.94 31.1101L219.01 31.2301V31.2201Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M215.769 20.9702C220.999 20.9402 223.059 20.9202 227.919 20.9102C234.729 16.6402 239.259 10.3102 239.899 2.93018C239.949 2.35018 239.969 1.78021 239.969 1.21021L209.289 1.2702L215.779 20.9802L215.769 20.9702Z" fill="#FFF2BB"/>
    </g>
    <path d="M248.31 73.86C255.37 67.7 255.8 61.0601 255.8 61.0601L228.75 61.17L240.79 97.8L154.65 160.38V170.34L264.12 109.39L248.32 73.85L248.31 73.86Z" fill="url(#paint15_radial_999_5883)"/>
    <path d="M62.3808 97.8L74.4308 61.17L47.3808 61.0601C47.3808 61.0601 47.8008 67.69 54.8508 73.85L39.0508 109.4L148.521 170.35V160.39L62.3808 97.8101V97.8Z" fill="url(#paint16_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M62.3808 97.8L74.4308 61.17L47.3808 61.0601C47.3808 61.0601 47.8008 67.69 54.8508 73.85L39.0508 109.4L148.521 170.35V160.39L62.3808 97.8101V97.8Z" fill="url(#paint17_radial_999_5883)"/>
    <path d="M68.2689 95.9902L148.519 157.03V160.38L62.3789 97.8002L68.2689 95.9902Z" fill="url(#paint18_linear_999_5883)"/>
    <path d="M234.939 96.1101L154.629 157.03V160.38L240.769 97.8001L234.939 96.1101Z" fill="url(#paint19_linear_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M248.311 73.86C255.371 67.7 255.801 61.0601 255.801 61.0601L228.751 61.17L240.791 97.8L215.141 116.43C215.411 117.46 215.671 118.49 215.911 119.53C217.301 125.01 218.491 129.91 219.491 134.25L264.121 109.4L248.321 73.86H248.311Z" fill="url(#paint20_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M196.339 138.94C196.579 135.86 196.659 132.84 196.629 129.87L154.629 160.39V170.35L194.479 148.16C195.439 145.34 196.079 142.28 196.339 138.94Z" fill="url(#paint21_radial_999_5883)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M54.8508 73.85L39.0508 109.4L95.9208 141.06C95.9208 141.06 57.0808 117.87 44.5808 109.95C42.3708 108.55 41.6008 107.34 42.6508 104.94C46.3708 96.3901 54.7608 76.1401 56.3508 73.0901C51.3108 69.1901 49.2308 65.5201 47.3808 61.0701C47.3808 61.0701 47.8008 67.7 54.8508 73.86V73.85Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M251.14 70.9901C255.49 65.8001 255.79 61.0601 255.79 61.0601L228.78 61.17H228.75L251.08 62.1201C254.38 62.3201 253.78 63.56 253.4 65.16C252.96 67.01 252.22 69.1801 251.14 70.9901Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M211.23 138.83L252.9 113.64C260.34 109.16 261 108.37 257.81 100.22L250.81 79.4902L264.1 109.4L211.23 138.83Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M154.629 160.38C154.629 160.38 154.899 161.78 155.079 162.86C155.899 167.63 157.179 167.39 159.949 166.06L175.629 158.65L154.629 170.34V160.38Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M109.379 148.55L143.579 165.86C147.069 167.77 147.499 166.78 148.199 162.73C148.279 162.27 148.519 160.65 148.519 160.65V170.34L109.379 148.55Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M180.65 40.1602H122.49L104.52 90.4702V95.4702L151.57 129.65L198.62 95.4702V90.4702L180.65 40.1602ZM172.31 92.6302H158.48L151.57 104.6L144.66 92.6302H130.83L137.74 80.6602L130.83 68.6902H144.66L151.57 56.7202L158.48 68.6902H172.31L165.4 80.6602L172.31 92.6302Z" fill="#892334"/>
    </g>
    <path d="M180.65 35.1602H122.49L104.52 81.4702V90.4702L151.57 124.65L198.62 90.4702V81.4702L180.65 35.1602ZM172.31 87.6302H158.48L151.57 99.6002L144.66 87.6302H130.83L137.74 75.6602V66.6602L130.83 63.6902H144.66L151.57 51.7202L158.48 63.6902H172.31L165.4 66.6602V75.6602L172.31 87.6302Z" fill="#4B0EA7"/>
    <path d="M180.65 26.1602H122.49L104.52 81.4702L151.57 115.65L198.62 81.4702L180.65 26.1602ZM172.31 78.6302H158.48L151.57 90.6002L144.66 78.6302H130.83L137.74 66.6602L130.83 54.6902H144.66L151.57 42.7202L158.48 54.6902H172.31L165.4 66.6602L172.31 78.6302Z" fill="url(#paint22_radial_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M197.55 78.1802L180.65 26.1702H122.49L122.23 26.9602C150.39 34.6402 178.63 52.8202 197.55 78.1802Z" fill="url(#paint23_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M167.919 62.3L165.399 66.67L172.309 78.6401H158.479L151.569 90.61L144.659 78.6401H130.829L137.739 66.67L130.829 54.7001H144.659L148.329 48.35C138.849 43.21 128.739 39.7201 118.639 38.0601L104.529 81.4901L151.579 115.67L188.319 88.98C183.069 78.68 176.049 69.7401 167.929 62.3101L167.919 62.3Z" fill="url(#paint24_radial_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M158.519 54.6901C158.519 54.6901 158.479 54.6601 158.469 54.6501L158.489 54.6901H158.519Z" fill="url(#paint25_radial_999_5883)"/>
    <path d="M151.58 124.66V115.66L104.52 81.48V90.48L151.58 124.66Z" fill="#200968"/>
    <path d="M151.58 51.7102V42.7102L144.66 54.6902H130.84L136.03 63.6902H144.66L151.58 51.7102Z" fill="#200968"/>
    <path d="M158.49 54.6902V63.6902H167.12L172.32 54.6902H158.49Z" fill="#200968"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M135.391 26.1602H180.651L189.861 54.6502L181.541 32.8302C180.261 29.8502 178.321 27.6402 175.081 27.4902L135.391 26.1602Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M130.84 78.6401H144.66L151.58 90.6101L143.44 80.5001L130.84 78.6401Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M151.58 42.7102L159.49 52.6102L172.32 54.6902H158.49L151.58 42.7102Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M122.499 26.1602C122.299 26.7802 113.559 58.9902 109.319 73.2002C107.439 79.4902 108.309 81.3302 111.599 84.0002L151.579 115.67L104.529 81.4902L122.499 26.1802V26.1602Z" fill="#612CB7"/>
    </g>
    <path d="M179.391 95.4603L192.141 84.6702C196.561 80.9302 196.021 78.7603 195.171 75.3203L190.501 56.4702L198.631 81.4902L179.391 95.4702V95.4603Z" fill="#4B0EA7"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M130.84 54.6901L143.67 53.2001L150.93 43.8401L144.66 54.6901H130.84Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M175.459 37.7701C174.329 40.7401 169.719 41.7501 165.179 40.0201C160.629 38.2901 157.869 34.4701 158.999 31.5001C160.129 28.5301 164.739 27.5201 169.279 29.2501C173.819 30.9801 176.589 34.8001 175.459 37.7701Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M180.211 46.6303C179.671 48.0303 177.501 48.5103 175.351 47.6903C173.201 46.8703 171.901 45.0702 172.431 43.6602C172.961 42.2502 175.141 41.7802 177.291 42.6002C179.441 43.4202 180.741 45.2203 180.211 46.6303Z" fill="#FCE265"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M136.631 90.42C136.091 91.82 133.921 92.3 131.771 91.48C129.621 90.66 128.321 88.86 128.851 87.45C129.391 86.05 131.561 85.57 133.711 86.39C135.861 87.21 137.161 89.01 136.631 90.42Z" fill="#FCE265"/>
    </g>
    <path d="M134.12 170.32L30.2996 111.75L44.7196 77.3301L0.849609 118.94L134.12 170.32Z" fill="url(#paint26_radial_999_5883)"/>
    <path d="M44.7308 77.3301L47.9808 83.5201L36.1908 109.95L30.3008 111.75L44.7308 77.3301Z" fill="#4B0EA7"/>
    <path d="M36.1908 109.95L134.121 166.96V170.32L30.3008 111.75L36.1908 109.95Z" fill="url(#paint27_linear_999_5883)"/>
    <path style={{mixBlendMode: 'screen'}} d="M134.12 170.32L30.2996 111.75L44.7196 77.3301L0.849609 118.94L134.12 170.32Z" fill="url(#paint28_radial_999_5883)"/>
    <path d="M169.029 170.32L272.849 111.75L258.429 77.3301L302.299 118.94L169.029 170.32Z" fill="url(#paint29_radial_999_5883)"/>
    <path d="M258.42 77.3301L255.17 83.5201L266.96 109.95L272.85 111.75L258.42 77.3301Z" fill="#4B0EA7"/>
    <path d="M266.959 109.95L169.029 166.96V170.32L272.849 111.75L266.959 109.95Z" fill="url(#paint30_linear_999_5883)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M223.77 149.21L302.3 118.94L258.43 77.3301L272.85 111.76L222 140.44C222.72 143.79 223.31 146.71 223.76 149.22L223.77 149.21Z" fill="url(#paint31_radial_999_5883)"/>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M116.509 160.38L67.4894 134.24L51.6895 123.82L116.509 160.38Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M52.5801 61.0801L74.4301 61.17L65.9601 87.0901L72.6601 63.9001C72.6001 62.5801 72.2401 62.1301 70.4401 62.0101L52.5801 61.0801Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M44.7208 77.3301L35.5508 97.3701L30.3008 111.75L44.7208 77.3301Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M263.22 88.7701C262.15 83.3201 263.33 83.7101 266.05 86.1401L293.63 110.72L258.42 77.3201L263.22 88.7701Z" fill="#E6ADFF"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M216.26 152.11L292.98 120.16C296.42 118.71 297.56 116.31 293.86 111.78L292.53 109.73L302.31 118.94L216.27 152.11H216.26Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M86.8899 152.11L10.1699 120.16C6.72986 118.71 5.58986 116.31 9.28986 111.78L10.6198 109.73L0.839844 118.94L86.8799 152.11H86.8899Z" fill="#612CB7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M213.34 145.32L251.77 125.4L272.85 111.75L213.34 145.32Z" fill="#E6ADFF"/>
    </g>
    <path style={{mixBlendMode: 'soft-light'}} d="M33.2305 31.1001C33.2305 31.1001 33.5104 34.3201 36.3304 38.1501C40.5204 35.7801 45.1905 33.3601 50.1805 31.1401L33.2305 31.1001Z" fill="url(#paint32_radial_999_5883)"/>
    <defs>
        <radialGradient id="paint0_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.631 -1.58986) scale(228.19 228.189)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.631 -1.58982) scale(228.19 228.189)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.629 -1.58992) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.911 0.390128) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint4_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7701 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <radialGradient id="paint5_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.629 -1.58991) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.629 -1.58991) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.909 0.390105) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7697 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <linearGradient id="paint9_linear_999_5883" x1="151.581" y1="77.56" x2="151.581" y2="104.28" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#BD45E8"/>
            <stop offset="1" stop-color="#F59CDE"/>
        </linearGradient>
        <radialGradient id="paint10_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.629 -852.498) scale(228.19 228.189)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.63 -1.58982) scale(228.19 228.189)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.63 -1.58992) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.909 -852.498) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.91 0.390174) scale(190.52 190.519)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.63 -1.58995) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.631 -1.58992) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7708 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <linearGradient id="paint18_linear_999_5883" x1="62.3789" y1="128.19" x2="148.519" y2="128.19" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#BD45E8"/>
            <stop offset="1" stop-color="#F59CDE"/>
        </linearGradient>
        <linearGradient id="paint19_linear_999_5883" x1="240.769" y1="128.25" x2="154.629" y2="128.25" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#BD45E8"/>
            <stop offset="1" stop-color="#F59CDE"/>
        </linearGradient>
        <radialGradient id="paint20_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.911 0.390058) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint21_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7689 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <radialGradient id="paint22_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.63 -1.58986) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <radialGradient id="paint23_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.91 0.390164) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint24_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7693 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <radialGradient id="paint25_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7555 154.575) scale(116.891 116.856)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <radialGradient id="paint26_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.63 -1.58991) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <linearGradient id="paint27_linear_999_5883" x1="30.3008" y1="140.13" x2="134.121" y2="140.13" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#BD45E8"/>
            <stop offset="1" stop-color="#F59CDE"/>
        </linearGradient>
        <radialGradient id="paint28_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.7696 154.68) scale(116.94 116.94)">
            <stop offset="0.32" stop-color="#D332E0"/>
            <stop offset="0.76" stop-color="#45094A"/>
        </radialGradient>
        <radialGradient id="paint29_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.629 -1.58991) scale(228.19 228.19)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.51" stop-color="#682BC7"/>
            <stop offset="0.92" stop-color="#F768CA"/>
        </radialGradient>
        <linearGradient id="paint30_linear_999_5883" x1="272.849" y1="140.13" x2="169.019" y2="140.13" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#BD45E8"/>
            <stop offset="1" stop-color="#F59CDE"/>
        </linearGradient>
        <radialGradient id="paint31_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.91 0.390135) scale(190.52 190.52)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
        <radialGradient id="paint32_radial_999_5883" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(202.91 0.389893) scale(190.52 190.521)">
            <stop stop-color="#5DAFD2"/>
            <stop offset="0.17" stop-color="#5CA9D0"/>
            <stop offset="0.37" stop-color="#599ACD"/>
            <stop offset="0.59" stop-color="#5582C7"/>
            <stop offset="0.82" stop-color="#5060BF"/>
            <stop offset="1" stop-color="#4B40B8"/>
        </radialGradient>
    </defs>
</svg>
)