import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals';

import AddCash from './addcash';
import CashOut from './cashout';
import LoginReady from './loginReady';
import CashoutFailed from './cashoutFailed';
import DepositFailed from './depositFailed';
import DepositSuccess from './depositSuccess';
import LoginRemove from './loginRemove';
import LoginReset from './loginReset';
import LoginResetDone from './loginResetDone';
import Game from './game';
import Download from './download';
import Wallet from './wallet';

export default () => {
    const {modal} = useSelector(Selector);

    if(!modal)
        return <></>;

    return (
        <div className="modal-overlay animate__animated animate__fadeIn animate__faster">
            {modal === 'wallet' && <Wallet />}
            {modal === 'addcash' && <AddCash />}
            {modal === 'cashout' && <CashOut />}
            {modal === 'cashout_failed' && <CashoutFailed />}
            {modal === 'login_ready' && <LoginReady />}
            {modal === 'login_remove' && <LoginRemove />}
            {modal === 'login_reset' && <LoginReset />}
            {modal === 'login_reset_done' && <LoginResetDone />}
            {modal === 'deposit_failed' && <DepositFailed />}
            {modal === 'deposit_success' && <DepositSuccess />}
            {modal === 'game' && <Game />}
            {modal === 'download' && <Download />}
        </div>
    )
}