import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/modals/login_reset_done';
import {updateBalance} from '../../reducers/games/actions';
import {toast} from 'react-toastify';
import Numeric from '../components/numeric';
import ModalsLib from '../../lib/modals';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default () => {
    const {game, login, buttons} = useSelector(Selector),
          [showPassword, setShowPassword] = useState(false),
          dispatch = useDispatch();

    return (
        <div className="modal animate__animated animate__fadeInUp animate__faster">
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">Password has been restored!</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <div className="game">
                    <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game.slug}.png)`}}></div>
                    <div className="details">
                        <div className="top">
                            <div className="name">{game.name}</div>
                        </div>
                        <div className="bottom">
                            <div className="detail">
                                <div className="title">Login</div>
                                <div className="value">{login.login}</div>
                            </div>

                            <div className="balance">
                                <div className="detail">
                                    <div className="title">Balance</div>
                                    <div className="value">
                                        <Numeric value={login.balance} />
                                    </div>
                                </div>

                                <svg className={`${login.balance_details.need_update ? 'updating' : 'noupdating'}`} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={e => {
                                    if(buttons.update)
                                        return;

                                    if(login.balance_details.need_update) {
                                        toast.dismiss();
                                        toast.info(`Your balance is already being updated...`);
                                        return;
                                    }

                                    dispatch(updateBalance(login.game));
                                }}>
                                    <path d="M18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 2.59199 13.7 2.59199 13.7M2.59199 13.7H6.35866M2.59199 13.7V17.8666M1.66699 9.99996C1.66699 5.39996 5.36699 1.66663 10.0003 1.66663C15.5587 1.66663 18.3337 6.29996 18.3337 6.29996M18.3337 6.29996V2.13329M18.3337 6.29996H14.6337" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="line-title">
                    <div className="line"></div>
                    <span>LOGIN DETAILS</span>
                    <div className="line"></div>
                </div>

                <div className="login-details">
                    <div className="input">
                        <div className="value">{login.login}</div>
                        <div className="icons">
                            <CopyToClipboard text={login.login} onCopy={e => {
                                toast.dismiss();
                                toast.info(`Login copied to clipboard`);
                            }}>
                                <div className="icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                        <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                    </svg>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="input">
                        <div className="value">{showPassword ? login.password : '••••••'}</div>
                        <div className="icons">
                            <div className={`icon ${showPassword ? 'active' : ''}`} onClick={e => setShowPassword(!showPassword)}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <CopyToClipboard text={login.password} onCopy={e => {
                                toast.dismiss();
                                toast.info(`Password copied to clipboard`);
                            }}>
                                <div className="icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                        <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                                    </svg>
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <div className="button close" onClick={e => ModalsLib.close()}>
                    Close
                </div>
            </div>
        </div>
    )
}