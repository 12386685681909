export default () => (
<svg width="248" height="155" viewBox="0 0 248 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M124.08 149.36L200.58 93.7796L171.36 3.84961H76.8L47.5801 93.7796L124.08 149.36Z" fill="#D59D35"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M84 13.71L124.12 137.19L164.24 13.71H84Z" fill="#D26200"/>
    </g>
    <path d="M174.09 0.0898438H74.0604L43.1504 95.2198L124.07 154.01L204.99 95.2198L174.08 0.0898438H174.09ZM59.1603 90.0198L83.9604 13.6999H164.2L189 90.0198L124.08 137.19L59.1603 90.0198Z" fill="url(#paint0_radial_999_6240)"/>
    <path d="M174.09 0.0898438H74.0602L66.9102 22.0898C71.3502 22.2998 75.9602 22.8598 80.6802 23.7498L83.9502 13.6999H164.19L188.99 90.0198L179.58 96.8598C181.7 100.98 183.58 105.23 185.18 109.62L204.99 95.2198L174.08 0.0898438H174.09Z" fill="url(#paint1_radial_999_6240)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M174.09 0.0898438H74.0602L66.9102 22.0898C71.3502 22.2998 75.9602 22.8598 80.6802 23.7498L83.9502 13.6999H164.19L188.99 90.0198L179.58 96.8598C181.7 100.98 183.58 105.23 185.18 109.62L204.99 95.2198L174.08 0.0898438H174.09Z" fill="url(#paint2_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.61 121.66C168.13 116.36 167.35 111.32 166.28 106.52L124.08 137.18L59.1602 90.01L76.9702 35.21C71.9102 35.33 66.9302 35.9 62.1102 36.89L43.1602 95.21L124.08 154L168.61 121.65V121.66Z" fill="url(#paint3_radial_999_6240)"/>
    <path d="M59.1602 90.02L65.5702 88.58L87.9202 19.81H160.24L182.58 88.58L189 90.02L164.2 13.71H83.9602L59.1602 90.02Z" fill="#D8A342"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
        <path d="M65.5686 88.58L78.0686 56.5001C85.4686 37.5001 103.769 29.2601 124.159 29.2601C144.639 29.2601 162.999 37.6101 170.339 56.7301L182.579 88.5901L160.229 19.8101H87.9086L65.5586 88.5901L65.5686 88.58Z" fill="#DA7D2B"/>
    </g>
    <path d="M59.1602 90.0198L65.5702 88.5798L124.08 131.09L182.58 88.5798L189 90.0198L124.08 137.19L59.1602 90.0198Z" fill="url(#paint4_linear_999_6240)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M65.5688 88.58L88.5089 29.7101C89.6189 26.8701 92.3489 25.0001 95.3989 25.0001H153.059C156.119 25.0001 158.869 26.8901 159.969 29.7501L182.569 88.5901L160.219 19.8101H87.8989L65.5488 88.5901L65.5688 88.58Z" fill="#DA7D2B"/>
    </g>
    <path d="M83.959 13.71L87.9189 19.81H160.239L164.199 13.71H83.959Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M54.2402 61.1099L46.4902 90.1299C45.8802 92.6299 47.0702 95.9499 49.1402 97.4899L124.08 154.02L43.1602 95.2299L54.2402 61.1099Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M65.5703 88.5798L121.38 126.17C122.86 127.17 125.28 127.17 126.76 126.17L182.57 88.5798L124.07 131.09L65.5703 88.5798Z" fill="#DA7D2B"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.08 154.02L196.14 98.3598C201.18 94.6198 202.27 93.8098 200 86.6598L184.96 33.5498L205 95.2298L124.08 154.02Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M88.3203 111.21L124.08 137.19L172.37 102.1L124.08 139.39L88.3203 111.21Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M121.369 0.0898438H174.089L184.959 33.5399L172.079 1.94986L121.369 0.0898438Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M142.35 0.0898438C141.91 4.42984 143.8 9.27986 147.45 13.6999H164.2L168.34 26.4499C173.59 27.7399 178.61 27.7798 182.72 26.6498L174.09 0.0898438H142.35Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M83.9694 108.05C82.6994 111.31 84.9994 116.17 89.5994 119.51C94.2094 122.86 99.5294 123.54 102.239 121.32L83.9694 108.04V108.05Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M102.359 130.21C101.589 131.39 99.4492 131.37 97.5892 130.16C95.7292 128.95 94.8392 127 95.6092 125.82C96.3792 124.64 98.5192 124.66 100.379 125.87C102.239 127.08 103.129 129.03 102.359 130.21Z" fill="white"/>
    </g>
    <path d="M66.1794 0.759644L63.1194 26.0796C55.8694 26.0396 49.8194 26.0096 38.9294 26.0196C0.389442 26.0696 0.189453 0.639648 0.189453 0.639648L66.1794 0.759644Z" fill="#D4992C"/>
    <path d="M66.1794 0.759644L59.6694 20.5396C52.4194 20.4996 51.2294 20.4696 40.3494 20.4796C1.80943 20.5296 0.189453 0.639648 0.189453 0.639648L66.1794 0.759644Z" fill="url(#paint5_radial_999_6240)"/>
    <path d="M56.4188 30.8197L53.3989 56.1497C46.1489 56.1097 54.9689 56.1497 44.0789 56.1497C5.53886 56.1997 5.29883 30.6997 5.29883 30.6997L56.4188 30.8197Z" fill="#D4992C"/>
    <path d="M56.4188 30.8199L49.9788 50.64C42.7288 50.6 56.4189 50.5699 45.5289 50.5799C6.98887 50.6299 5.29883 30.6899 5.29883 30.6899L56.4188 30.8099V30.8199Z" fill="url(#paint6_radial_999_6240)"/>
    <path d="M46.65 60.88L19.5 60.77C19.5 60.77 23.76 79.83 43.62 86.21L46.65 60.88Z" fill="#D4992C"/>
    <path d="M46.65 60.88L19.5 60.77C19.5 60.77 20.34 74.33 40.2 80.71L46.65 60.88Z" fill="url(#paint7_radial_999_6240)"/>
    <path d="M66.1799 0.759766L69.4299 7.03976L63.1199 26.0797L59.6699 20.5398L66.1799 0.759766Z" fill="#D8A342"/>
    <path d="M56.4185 30.8198L59.6685 37.0398L53.3986 56.1498L49.9785 50.6498L56.4185 30.8198Z" fill="#D8A342"/>
    <path d="M46.6392 60.8896L49.8992 67.1096L43.6292 86.2096L40.1992 80.7096L46.6392 60.8896Z" fill="#D8A342"/>
    <path d="M181.99 0.759644L185.05 26.0796C192.3 26.0396 198.35 26.0096 209.24 26.0196C247.78 26.0696 247.98 0.639648 247.98 0.639648L181.99 0.759644Z" fill="#D4992C"/>
    <path d="M181.99 0.759644L188.5 20.5396C195.75 20.4996 196.94 20.4696 207.82 20.4796C246.36 20.5296 247.98 0.639648 247.98 0.639648L181.99 0.759644Z" fill="url(#paint8_radial_999_6240)"/>
    <path d="M191.76 30.8197L194.78 56.1497C202.03 56.1097 193.21 56.1497 204.1 56.1497C242.64 56.1997 242.88 30.6997 242.88 30.6997L191.76 30.8197Z" fill="#D4992C"/>
    <path d="M191.76 30.8199L198.2 50.64C205.45 50.6 191.76 50.5699 202.65 50.5799C241.19 50.6299 242.88 30.6899 242.88 30.6899L191.76 30.8099V30.8199Z" fill="url(#paint9_radial_999_6240)"/>
    <path d="M201.52 60.88L228.669 60.77C228.669 60.77 224.41 79.83 204.549 86.21L201.52 60.88Z" fill="#D4992C"/>
    <path d="M201.52 60.88L228.669 60.77C228.669 60.77 227.829 74.33 207.969 80.71L201.52 60.88Z" fill="url(#paint10_radial_999_6240)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M181.99 0.759644L188.5 20.5396C195.75 20.4996 196.94 20.4696 207.82 20.4796C246.36 20.5296 247.98 0.639648 247.98 0.639648L181.99 0.759644Z" fill="url(#paint11_radial_999_6240)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M191.76 30.8199L198.2 50.64C205.45 50.6 191.76 50.5699 202.65 50.5799C241.19 50.6299 242.88 30.6899 242.88 30.6899L191.76 30.8099V30.8199Z" fill="url(#paint12_radial_999_6240)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M201.52 60.88L228.669 60.77C228.669 60.77 227.829 74.33 207.969 80.71L201.52 60.88Z" fill="url(#paint13_radial_999_6240)"/>
    <path d="M181.99 0.759766L178.74 7.03976L185.05 26.0797L188.5 20.5398L181.99 0.759766Z" fill="#D8A342"/>
    <path d="M191.76 30.8198L188.5 37.0398L194.77 56.1498L198.2 50.6498L191.76 30.8198Z" fill="#D8A342"/>
    <path d="M201.529 60.8896L198.279 67.1096L204.549 86.2096L207.969 80.7096L201.529 60.8896Z" fill="#D8A342"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M66.1794 0.759644L0.189453 0.639648C0.189453 0.639648 1.80943 20.5296 40.3494 20.4796C51.2294 20.4696 52.4194 20.4996 59.6694 20.5396L66.1794 0.759644Z" fill="url(#paint14_radial_999_6240)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M5.29883 30.6997C5.29883 30.6997 5.57881 33.9297 8.40881 37.7797C12.6188 35.3997 17.3088 32.9697 22.3088 30.7397L5.29883 30.6997Z" fill="url(#paint15_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M46.65 60.88L19.5 60.77C19.5 60.77 20.34 74.33 40.2 80.71L46.65 60.88Z" fill="url(#paint16_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M49.9797 50.65L53.7798 38.96C48.6098 40.65 43.7098 42.87 39.1998 45.63C37.3398 46.77 35.1398 48.18 32.7598 49.75C36.4898 50.29 40.7298 50.59 45.5298 50.59C56.4098 50.58 42.7297 50.61 49.9797 50.65Z" fill="url(#paint17_radial_999_6240)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M56.0586 55.4998L72.9586 7.03986C74.7086 2.11986 75.9786 0.829862 81.1886 0.639862L124.069 0.0898438H74.0586L56.0586 55.4998Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M139.19 7.65973C135.69 5.11973 131.48 4.95972 129.78 7.29972C128.56 8.97972 128.97 11.4797 130.58 13.7097H143.12C142.92 11.6597 141.51 9.34973 139.19 7.65973Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M196.93 19.03C192.54 18.59 189.47 17 187.47 13.06L181.99 0.75L188.5 20.53C195.75 20.49 196.94 20.46 207.82 20.47C213.96 20.47 219.16 19.98 223.57 19.13C214.02 20.28 204.21 19.74 196.93 19.02V19.03Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M202.64 50.5901C211.96 50.6001 219.12 49.4401 224.63 47.6801C216.71 49.8801 210.06 49.7301 205 49.4201C201.52 49.2101 198.69 46.6501 197.21 43.4901L191.76 30.8301L198.2 50.6501C205.45 50.6101 191.76 50.5801 202.65 50.5901H202.64Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M207.969 80.7099C212.269 79.3299 215.679 77.6099 218.379 75.7599C215.179 76.9799 209.179 77.8499 209.179 77.8499L201.529 60.8799V60.8999L207.969 80.6999V80.7099Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.3492 20.4796C51.2292 20.4696 52.4192 20.4996 59.6692 20.5396L64.8292 4.86963L60.9992 13.4196C59.1592 17.9296 58.0692 19.4096 53.5692 19.4496C48.7192 19.4896 39.5792 19.3896 34.9292 19.2096C14.6992 18.4196 2.77922 9.66965 0.199219 0.639648C0.199219 0.639648 1.81919 20.5296 40.3592 20.4796H40.3492Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M50.8387 44.8C49.6187 47.37 48.2987 49.52 45.4587 49.42C42.5187 49.32 34.2686 49.26 29.5486 48.19C18.6086 45.72 12.0687 41.65 7.63867 36.65C11.3587 42.52 20.9487 50.62 45.5287 50.59C56.4087 50.58 42.7286 50.61 49.9786 50.65L55.3087 34.25L50.8387 44.81V44.8Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.1998 80.71L45.8298 63.42C44.7698 65.62 42.6798 70.72 40.5598 75.45C39.1098 78.66 37.7298 78.26 35.4998 77.33C24.4098 72.72 21.8598 67.22 19.5098 60.77C19.5098 60.77 20.3498 74.33 40.2098 80.71H40.1998Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M181.99 0.759644L242.34 1.78964C244.77 1.82964 245.89 3.64963 244.84 5.83963C243.75 8.11963 241.98 10.8496 239.09 13.1996C247.42 7.53965 247.98 0.639648 247.98 0.639648L181.99 0.759644Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M191.76 30.8199L236.28 31.8699C241.48 31.9799 240.64 34.19 239.28 36.89C238.44 38.56 237.3 40.3799 235.79 41.8999C242.37 36.5499 242.87 30.6899 242.87 30.6899L191.75 30.8099L191.76 30.8199Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M224.009 70.7298C228.379 65.5198 228.679 60.7598 228.679 60.7598L201.569 60.8698H201.539L223.949 61.8198C227.259 62.0198 226.659 63.2698 226.279 64.8698C225.829 66.7198 225.099 68.8998 224.019 70.7198L224.009 70.7298Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.7402 0.639648L66.1902 0.759644L64.5602 5.70963C64.5602 3.16963 63.3502 1.88962 60.4302 1.85962L17.7402 0.639648Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.7402 30.8198H56.4202L54.6403 36.2998L54.8303 34.4798C54.9303 32.7598 54.3202 31.8598 51.1802 31.7098L17.7402 30.8198Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M24.7285 60.79L46.6585 60.88L44.9885 65.99L44.8885 63.62C44.8286 62.29 44.4685 61.84 42.6585 61.72L24.7285 60.79Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M158.419 89.8399L164.329 79.5999V74.6999L152.509 79.5999L146.599 69.3699L140.689 79.5999L128.869 74.6999V79.5999L134.779 89.8399L128.869 95.1699V100.07H140.689L146.599 110.31L152.509 100.07H164.329V95.1699L158.419 89.8399Z" fill="#DA7D2B"/>
    </g>
    <path d="M158.419 84.9397L164.329 74.6997V70.8697L152.509 74.6997L146.599 64.4697L140.689 74.6997L128.869 70.8697V74.6997L134.779 84.9397L128.869 91.3497V95.1697H140.689L146.599 105.41L152.509 95.1697H164.329V91.3497L158.419 84.9397Z" fill="#D8A342"/>
    <path d="M158.419 81.1096L164.329 70.8696H152.509L146.599 60.6396L140.689 70.8696H128.869L134.779 81.1096L128.869 91.3496H140.689L146.599 101.58L152.509 91.3496H164.329L158.419 81.1096Z" fill="url(#paint18_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M143.889 65.3398L140.699 70.8698H128.879L134.789 81.1098L128.879 91.3499H140.699L146.609 101.59L152.519 91.3499H159.449C155.519 81.2999 150.189 72.6499 143.899 65.3499L143.889 65.3398Z" fill="url(#paint19_radial_999_6240)"/>
    <path d="M164.33 91.3499H152.51V95.1798H164.33V91.3499Z" fill="#D4992C"/>
    <path d="M140.689 95.1699L146.599 105.41V101.58L140.689 91.3499H128.869V95.1699H140.689Z" fill="#D4992C"/>
    <path d="M128.869 70.8699L134.779 81.1099L133.679 83.0199L128.869 74.6999V70.8699Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M128.869 70.8699L136.949 81.1099L128.869 91.3499L134.779 81.1099L128.869 70.8699Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.8">
        <path d="M146.6 60.6396L152.3 71.8796L164.33 70.8696H152.51L146.6 60.6396Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M128.869 91.35L141.799 90.23L146.599 101.58L140.689 91.35H128.869Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M146.6 101.58L151.75 88.98L164.33 91.35H152.51L146.6 101.58Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M113.39 89.8399L119.3 79.5999V74.6999L107.48 79.5999L101.57 69.3699L95.6596 79.5999L83.8496 74.6999V79.5999L89.7596 89.8399L83.8496 95.1699V100.07H95.6596L101.57 110.31L107.48 100.07H119.3V95.1699L113.39 89.8399Z" fill="#DA7D2B"/>
    </g>
    <path d="M113.39 84.9397L119.3 74.6997V70.8697L107.48 74.6997L101.57 64.4697L95.6596 74.6997L83.8496 70.8697V74.6997L89.7596 84.9397L83.8496 91.3497V95.1697H95.6596L101.57 105.41L107.48 95.1697H119.3V91.3497L113.39 84.9397Z" fill="#D8A342"/>
    <path d="M113.39 81.1096L119.3 70.8696H107.48L101.57 60.6396L95.6596 70.8696H83.8496L89.7596 81.1096L83.8496 91.3496H95.6596L101.57 101.58L107.48 91.3496H119.3L113.39 81.1096Z" fill="url(#paint20_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M113.39 81.1096L119.3 70.8696H107.48L101.57 60.6396L95.6596 70.8696H83.8496L89.7596 81.1096L83.8496 91.3496H95.6596L101.57 101.58L107.48 91.3496H119.3L113.39 81.1096Z" fill="url(#paint21_radial_999_6240)"/>
    <path d="M119.299 91.3499H107.479V95.1798H119.299V91.3499Z" fill="#D4992C"/>
    <path d="M95.6596 95.1699L101.57 105.41V101.58L95.6596 91.3499H83.8496V95.1699H95.6596Z" fill="#D4992C"/>
    <path d="M83.8496 70.8699L89.7596 81.1099L88.6497 83.0199L83.8496 74.6999V70.8699Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M83.8496 70.8699L91.9196 81.1099L83.8496 91.3499L89.7596 81.1099L83.8496 70.8699Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.8">
        <path d="M101.57 60.6396L107.27 71.8796L119.3 70.8696H107.48L101.57 60.6396Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M83.8496 91.35L96.7797 90.23L101.57 101.58L95.6596 91.35H83.8496Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M101.57 101.58L106.72 88.98L119.3 91.35H107.48L101.57 101.58Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M136.019 50.9798L141.929 40.7397V35.8498L130.109 40.7397L124.199 30.5098L118.289 40.7397L106.479 35.8498V40.7397L112.389 50.9798L106.479 56.3198V61.2198H118.289L124.199 71.4498L130.109 61.2198H141.929V56.3198L136.019 50.9798Z" fill="#DA7D2B"/>
    </g>
    <path d="M136.019 46.0799L141.929 35.8499V32.0199L130.109 35.8499L124.199 25.6099L118.289 35.8499L106.479 32.0199V35.8499L112.389 46.0799L106.479 52.4899V56.3199H118.289L124.199 66.5499L130.109 56.3199H141.929V52.4899L136.019 46.0799Z" fill="#D8A342"/>
    <path d="M136.019 42.2498L141.929 32.0198H130.109L124.199 21.7798L118.289 32.0198H106.479L112.389 42.2498L106.479 52.4898H118.289L124.199 62.7198L130.109 52.4898H141.929L136.019 42.2498Z" fill="url(#paint22_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M124.199 62.7201L130.089 52.5101C124.509 48.3601 118.539 44.9701 112.339 42.3101L106.469 52.4801H118.289L124.199 62.7201Z" fill="url(#paint23_radial_999_6240)"/>
    <path style={{mixBlendMode: 'screen'}} d="M130.109 32.0198L124.2 21.7798L118.289 32.0198H109.02C120.09 36.7398 130.869 42.9798 140.799 50.5398L136.02 42.2598L141.93 32.0198H130.109Z" fill="url(#paint24_radial_999_6240)"/>
    <path d="M141.929 52.4897H130.109V56.3198H141.929V52.4897Z" fill="#D4992C"/>
    <path d="M118.289 56.3198L124.199 66.5497V62.7198L118.289 52.4897H106.479V56.3198H118.289Z" fill="#D4992C"/>
    <path d="M106.479 32.02L112.389 42.25L111.279 44.17L106.479 35.85V32.02Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M106.479 32.02L114.549 42.25L106.479 52.49L112.389 42.25L106.479 32.02Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.8">
        <path d="M124.199 21.7798L129.899 33.0198L141.929 32.0198H130.109L124.199 21.7798Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M106.479 52.4896L119.409 51.3696L124.199 62.7196L118.289 52.4896H106.479Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.199 62.7196L129.349 50.1196L141.929 52.4896H130.109L124.199 62.7196Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M110.03 82.4198C108.58 84.9298 104.28 85.1698 100.44 82.9498C96.5998 80.7298 94.6598 76.8898 96.1098 74.3798C97.5598 71.8698 101.86 71.6298 105.7 73.8498C109.54 76.0698 111.48 79.9098 110.03 82.4198Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M103.49 88.8399C103.03 89.6399 101.5 89.6299 100.07 88.8099C98.65 87.9899 97.87 86.6699 98.34 85.8699C98.8 85.0699 100.33 85.0799 101.76 85.8999C103.18 86.7199 103.96 88.0399 103.49 88.8399Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M130.059 36.7496C129.279 38.1096 126.699 38.0797 124.299 36.6997C121.899 35.3197 120.589 33.0896 121.379 31.7396C122.169 30.3896 124.739 30.4097 127.139 31.7897C129.539 33.1697 130.849 35.3996 130.059 36.7496Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M188.5 20.5397C193.75 20.5097 195.82 20.4897 200.69 20.4797C207.52 16.1997 212.07 9.83969 212.71 2.42969C212.76 1.84969 212.78 1.27971 212.78 0.699707L181.99 0.759705L188.5 20.5397Z" fill="white"/>
    </g>
    <path d="M150.01 119.24C150.01 119.24 153.53 112.68 159 108.91C162.27 106.65 168.58 104.88 168.58 104.88C168.58 104.88 162.7 112.27 158.36 115.73C154.02 119.19 150.02 119.23 150.02 119.23L150.01 119.24Z" fill="url(#paint25_linear_999_6240)"/>
    <path d="M164.78 107.35C159.95 107.63 156.08 111.49 153.98 114.21C151.88 116.92 153.07 117.83 155.73 116.65C158.38 115.47 166.82 107.24 164.78 107.36V107.35Z" fill="#D9A648"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M164.779 107.35C161.509 107.32 157.399 110.41 156.119 111.79C154.829 113.17 157.399 115.29 159.149 114.17C160.899 113.05 166.269 107.37 164.779 107.35Z" fill="url(#paint26_linear_999_6240)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.459847) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.459847) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.31 -7.01015) scale(195.11 195.11)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8802 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_6240" x1="124.08" y1="77.3298" x2="124.08" y2="104.15" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#E6C485"/>
            <stop offset="1" stop-color="#F3E2C4"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.679 0.459648) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.679 0.459913) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.460002) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.459648) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint9_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.459913) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint10_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.460002) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.31 -7.01035) scale(195.11 195.11)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.31 -7.01008) scale(195.11 195.11)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.3 -7.00999) scale(195.11 195.11)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.299 -7.01035) scale(195.11 195.11)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.299 -7.01022) scale(195.1 195.1)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8801 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8798 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint18_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.679 0.459685) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint19_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8789 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint20_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.68 0.459685) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint21_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8796 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint22_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.679 0.459793) scale(212.67 212.67)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint23_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8787 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint24_radial_999_6240" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.8795 136.94) scale(137.89 137.89)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <linearGradient id="paint25_linear_999_6240" x1="152.56" y1="115.93" x2="165.36" y2="108.57" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8EEDB"/>
            <stop offset="0.99" stop-color="#E4C07C"/>
        </linearGradient>
        <linearGradient id="paint26_linear_999_6240" x1="161.038" y1="113.864" x2="160.082" y2="107.848" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#DEB361"/>
            <stop offset="1" stop-color="#D7A03B"/>
        </linearGradient>
    </defs>
</svg>
)