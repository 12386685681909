import {NumericFormat} from 'react-number-format';

export default ({
    value,
    prefix = '$',
    fixedDecimalScale = 2
}) => {
    return <NumericFormat 
                displayType="text" 
                value={value}
                prefix={prefix} 
                decimalSeparator="." 
                decimalScale={2} 
                fixedDecimalScale={fixedDecimalScale}
                thousandSeparator=" " />
}