import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.modals.modal,
    (modal) => {
        return {
            modal
        }
    }
)