import types from './types';

const InitialState = {
    mode: "tabs",
    tab: "deposit",
    method: null,
    methods: {
        crypto_deposit: {
            loaded: false,
            coin: null,
            network: null
        },
        cashapp_deposit: {
            stage: "create",
            create: {
                error: "",
                amount: 50,
                cashtag: '',
                button: false
            },
            confirm: {
                error: "",
                order: {
                    sender: "$zusunit",
                    recipient: {
                        name: "Carmona",
                        cashtag: "$Carmona2526"
                    },
                    amount: 25,
                    paymentId: "#VP2WBTG41"
                },
                button: false
            }
        }
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        // Crypto deposit
        case types.crypto_deposit.loaded:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    crypto_deposit: {
                        ...state.methods.crypto_deposit,
                        ...payload,
                        loaded: true
                    }
                }
            }
        case types.crypto_deposit.change:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    crypto_deposit: (state.methods.crypto_deposit.coin !== payload.coin && state.methods.crypto_deposit.network !== payload.network) ? {
                        loaded: false,
                        coin: payload.coin,
                        network: payload.network
                    } : state.methods.crypto_deposit
                }
            }
        // Cashapp Deposit
        case types.cashapp_deposit.setStage:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        stage: payload
                    }
                }
            }

        case types.cashapp_deposit.toggleStageButton:
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        [state.methods.cashapp_deposit.stage]: {
                            ...state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage],
                            button: !state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage].button
                        }
                    }
                }
            }

        case types.cashapp_deposit.updateStageData:
            console.log({
                ...state.methods,
                cashapp_deposit: {
                    ...state.methods.cashapp_deposit,
                    [state.methods.cashapp_deposit.stage]: {
                        ...state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage],
                        ...payload
                    }
                }
            });
            return {
                ...state,
                methods: {
                    ...state.methods,
                    cashapp_deposit: {
                        ...state.methods.cashapp_deposit,
                        [state.methods.cashapp_deposit.stage]: {
                            ...state.methods.cashapp_deposit[state.methods.cashapp_deposit.stage],
                            ...payload
                        }
                    }
                }
            }
        // 
        case types.preset:
            return {
                ...state,
                mode: payload.mode || state.mode,
                tab: payload.tab || state.tab,
                method: payload.method || state.method
            }
        case types.tab:
            return {
                ...state,
                tab: payload
            }
        default:
            return state;
    }
}