export const getCashoutsRange = ({
    type,
    amount,
    loginBalance,
    sessions
}) => {
    const session_amount = amount + loginBalance,
          minimal_cashout = session_amount * 4;

    if(type === 'cash') {
        const rules = sessions.filter(s => s.type === 'cash').filter(s => s.amounts.from <= session_amount && s.amounts.to >= session_amount)[0] || false;

        if(!rules)
            return 'from $50 to $50';

        if(minimal_cashout < 50)
            return `from $50 to $${rules.maximal_cashout}`;

        return `from $${session_amount * 4} to $${rules.maximal_cashout}`;
    }

    return `CALC`;
}

