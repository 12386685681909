import app from './app/types';
import user from './user/types';
import games from './games/types';
import modals from './modals/types';

export default {
    app,
    user,
    games,
    modals
}