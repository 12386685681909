import Full from './full';
import Page from './page';

const close = () => {
    const element = document.querySelector('.loader-overlay');

    if(element) {
        element.classList.add('animate__fadeOut');

        setTimeout(() => {
            element.remove();
        }, 1000);
    }
}

const closePage = () => {
    const element = document.querySelector('.loader-page');

    if(element) {
        element.classList.add('animate__fadeOut');

        setTimeout(() => {
            element.remove();
        }, 1000);
    }
}

export default {
    Full,
    Page,
    close,
    closePage
}