import {useEffect} from 'react';
import PageContainer from '../page-container';
import Components from '../../components';
import FAQ from './faq';

export default () => {
    useEffect(() => {
        Components.Loader.close();
    }, []);

    return (
        <PageContainer>
            <div className="tyl-page">
                <Components.Winners />

                <div className="tyl-container">
                    <div className="tyl-top">
                        <div className="layer">
                            <div className="dot"><div></div></div>
                            <div className="dot big"><div></div></div>
                        </div>
                        <div className="layer">
                            <div className="money"></div>
                            <div className="money"></div>
                        </div>
                        <div className="layer">
                            <div className="clover"></div>
                        </div>
                        <div className="layer">
                            <div className="content">
                                <div className="title">Test your luck</div>
                                <div className="description">If you are unlucky several times in a row - we give you the opportunity to try your luck with real money</div>
                            </div>
                        </div>
                    </div>

                    <div className="hiw">
                        <div className="title">How it works?</div>
                        <div className="text">Since you have not taken this bonus within 24 hours and have not made a withdrawal, the number of your deposits is calculated within 24 hours.</div>
                        <div className="text">Starting from <b>11 Feb, 8:47:46 AM LA</b></div>
                    </div>

                    <div className="progress">
                        <div className="point active">
                            <div className="amount">$0</div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="num">1</div>
                        </div>

                        <div className="line-block">
                            <div className="lore">Need more deposits</div>
                            <div className="line">
                                <div className="line-progress" style={{width: '100%'}}></div>
                            </div>
                        </div>

                        <div className="point active">
                            <div className="amount won">$5</div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="num">3</div>
                        </div>

                        <div className="line-block">
                            <div className="lore"></div>
                            <div className="line">
                                <div className="line-progress" style={{width: "0%"}}></div>
                            </div>
                        </div>

                        <div className="point">
                            <div className="amount won">$10</div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="num">4</div>
                        </div>

                        <div className="line-block">
                            <div className="lore"></div>
                            <div className="line">
                                <div className="line-progress" style={{width: "0%"}}></div>
                            </div>
                        </div>

                        <div className="point">
                            <div className="amount won">$15</div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2797 10.0333L8.93306 5.68664C8.41973 5.1733 7.57973 5.1733 7.06639 5.68664L2.71973 10.0333" fill="none" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className="num">5</div>
                        </div>
                    </div>

                    <div className="button-block">
                        <div className="button">Claim $5</div>

                        <div className="status">If you lose the next game, your bonus will be <span>$15</span></div>
                    </div>

                    <FAQ />
                </div>

                <Components.DepositBonus />
                <Components.Footer />
            </div>
        </PageContainer> 
    )
}