import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../../selectors/modals/wallet/methods/crypto_deposit';
import {setPreset} from '../../../../../reducers/wallet/actions';
import ModalsLib from '../../../../../lib/modals';
import BlueButton from '../../../../components/blue-button';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';

import QRCode from './qrCode';

export default () => {
    const {props} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-left">
                    <div className="title">{props.coin}</div>
                    <div className="descriptions">Hello world</div>
                </div>
                <div className="close" onClick={e => ModalsLib.close()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 18L18 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 18L6 6" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="modal-body">
                <QRCode address={props.address} />

                <div className="modal-text">Only <b>{props.coin}</b> in the <b>{props.network}</b> network can be transferred to the deposit address. <u>Do not transfer other coins!</u></div>
                <div className="modal-text">An amount less than the minimum amount will <b>NOT</b> be credited and is <b>NON</b>-refundable!</div>
            
                <div className="crypto-details">
                    <div className="detail">
                        <div className="label">
                            Your <b>eternal</b> wallet
                        </div>
                        <div className="value">{props.address}</div>
                    </div>
                    <hr />
                    <div className="detail">
                        <div className="label">
                            Minimal deposit amount
                        </div>
                        <div className="value">{props.minimal} {props.coin}</div>
                    </div>
                </div>

                <div className="modal-message info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 12.5L11.5 14L14.75 11" stroke="#0C76DA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>The money will be credited after <b>1 confirmation</b> in the blockchain!</span>
                </div>
            </div>
            <div className="modal-footer">
                <CopyToClipboard text={props.address} onCopy={e => {
                    toast.dismiss();
                    toast.info(`The ${props.coin} wallet on the ${props.network} network has been copied to the clipboard!`);
                }}>
                    <BlueButton>
                        Copy address
                    </BlueButton>
                </CopyToClipboard>
                <div className="button back" onClick={e => dispatch(setPreset({
                    mode: 'tabs',
                    tab: 'deposit'
                }))}>
                    Back
                </div>
            </div>
        </>
    )
}