import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.games,
    props.games.buttons.requestDownloadCode,
    props.modals.data.download,
    (games, button, data) => {
        const game = games.filter(g => g.slug === data.game)[0] || false

        return {
            game,
            button,
            data
        }
    }
)