import {useEffect} from 'react';
import PageContainer from '../page-container';
import Components from '../../components';

import Telegram from './telegram';
import Profile from './profile';

export default () => {
    useEffect(() => {
        Components.Loader.close();
    }, []);

    return (
        <PageContainer>
            <div className="fc-page">
                <Components.Winners />

                <div className="fc-container">
                    <Telegram />
                    <Profile />
                </div>
                <Components.DepositBonus />
                <Components.Footer />
            </div>

        </PageContainer>
    )
}