import types from './types';

const InitialState = {
    modal: null,
    data: {
        methods: {
            
        },
        addcash: {
            amount: 50,
            game: 'gameroom',
            button: false
        },
        wallet: {
            mode: "tabs",
            method: null,
            tab: "deposit"
        },
        cashout: {game: null},
        login_ready: {game: null},
        login_remove: {game: null},
        login_reset: {game: null},
        login_reset_done: {game: null},
        cashout_failed: {transactionId: null, reason: 'unknown'},
        deposit_failed: {transactionId: null, reason: 'unknown'},
        deposit_success: {transactionId: null},
        game: {game: null},
        download: {game: null, code: false}
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.wallet.preset:
            return {
                ...state,
                data: {
                    ...state.data,
                    wallet: {
                        ...state.data.wallet,
                        mode: payload.mode || state.data.wallet.mode,
                        tab: payload.tab || state.data.wallet.tab,
                        method: payload.method || state.data.wallet.method
                    }
                }
            }
        case types.wallet.tab:
            return {
                ...state,
                data: {
                    ...state.data,
                    wallet: {
                        ...state.data.wallet,
                        tab: payload
                    }
                }
            }
        case types.reset:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload]: InitialState.data[payload]
                }
            }
        case types.downloadCode:
            return {
                ...state,
                data: {
                    ...state.data,
                    download: state.data.download.game === payload.game ? {
                        ...state.data.download,
                        code: payload.code
                    } : state.data.download
                }
            }
        case types.update:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.slug]: {
                        ...state.data[payload.slug],
                        ...payload.data
                    }
                }
            }
        case types.set:
            return {
                ...state,
                modal: payload
            }
        default:
            return state;
    }
}