import types from './types';
import API from '../../lib/api';
import WS from '../../lib/websocket';

export const getUser = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/get'
    });

    if(!response.success)
        return toast.error(response.error);

    WS.authorize(response.user.userId, response.user.userStringId);

    dispatch({
        type: types.loaded,
        payload: {
            user: response.user,
            logins: response.logins
        }
    });
}

export const getTelegramUser = query => async dispatch => {
    const response = await API.call({
        path: '/auth/user/telegram',
        body: {
            query
        }
    });

    if(!response.success)
        return toast.error(response.error);

    const userResponse = await API.call({
        path: '/auth/user/get'
    });

    if(!userResponse.success)
        return toast.error(userResponse.error);

    dispatch({
        type: types.loaded,
        payload: {
            user: userResponse.user,
            logins: userResponse.logins
        }
    });

    WS.authorize(userResponse.user.userId, userResponse.user.userStringId);
}