import Slides from './slides';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from 'swiper/modules'
import 'swiper/css';

export default ({scale}) => {
    return (
        <div className="slider" style={{transform: `scale(${scale})`}}>
            <div className="slider-carousel">
                <Swiper 
                    // modules={[Pagination, Autoplay]}
                    modules={[Pagination]}
                    pagination={{
                        el: '.pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<div class="dot ${className}" data-index="${index}"></div>`
                        }
                    }}
                    // autoplay={{
                    //     delay: 7e3
                    // }}
                    centeredSlides={true}
                    noSwiping={true}
                    speed={1e3}
                    slidesPerGroup={1}
                    slidesPerView={1}>
                    <SwiperSlide><Slides.WelcomeBonus /></SwiperSlide>
                    <SwiperSlide><Slides.TestYourLuck /></SwiperSlide>
                </Swiper>
            </div>
            <div className="pagination-space">
                <div className="pagination">
                    <div className="dot active"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </div>
    )
}