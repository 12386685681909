import types from '../types';

export const setTab = tab => {
    return {
        type: types.tab,
        payload: tab
    }
}

export const setPreset = preset => {
    return {
        type: types.preset,
        payload: preset
    }
}