import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/vip';
import {loadVip} from '../../../reducers/vip/actions';
import {navigate} from '../../../utils/navigation';

import PageContainer from '../page-container';
import Components from '../../components';
import History from './history';
import FAQ from './faq';
import ListLevels from './list_levels';
import Invite from './invite';
import Top from './top';

export default () => {
    const {loaded, isAuth} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!isAuth)
            return navigate('/');

        if(!loaded) {
            dispatch(loadVip());
        } else {
            Components.Loader.close();
            Components.Loader.closePage();
        }
    }, [loaded]);

    return (
        <PageContainer>
            <Components.Loader.Page />
            {loaded && (
                <div className="vip-page">
                    <Components.Winners />

                    <div className="vip-container">
                        <Top />
                        <History />
                        <ListLevels />
                        <Invite />
                        <FAQ />
                    </div>

                    <Components.DepositBonus />
                    <Components.Footer />
                </div>
            )}
        </PageContainer>
    )
}