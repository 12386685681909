export default () => (
<svg width="249" height="155" viewBox="0 0 249 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M124.32 149.87L200.82 94.2899L171.6 4.35986H77.0403L47.8203 94.2899L124.32 149.87Z" fill="#D59D35"/>
    <path d="M174.34 0.600098H74.3104L43.4004 95.7301L124.32 154.52L205.24 95.7301L174.33 0.600098H174.34ZM59.4104 90.5301L84.2104 14.2101H164.45L189.25 90.5301L124.33 137.7L59.4104 90.5301Z" fill="url(#paint0_radial_999_6136)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.3">
        <path d="M84.1992 14.21L124.319 137.69L164.449 14.21H84.1992Z" fill="#D26200"/>
    </g>
    <path d="M174.34 0.600098H74.3102L67.1602 22.6001C71.6002 22.8101 76.2101 23.3701 80.9301 24.2601L84.2002 14.2101H164.44L189.24 90.5301L179.83 97.3701C181.95 101.49 183.83 105.74 185.43 110.13L205.24 95.7301L174.33 0.600098H174.34Z" fill="url(#paint1_radial_999_6136)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M174.34 0.600098H74.3102L67.1602 22.6001C71.6002 22.8101 76.2101 23.3701 80.9301 24.2601L84.2002 14.2101H164.44L189.24 90.5301L179.83 97.3701C181.95 101.49 183.83 105.74 185.43 110.13L205.24 95.7301L174.33 0.600098H174.34Z" fill="url(#paint2_radial_999_6136)"/>
    <path style={{mixBlendMode: 'screen'}} d="M168.85 122.17C168.37 116.87 167.59 111.83 166.52 107.03L124.32 137.69L59.4004 90.5202L77.2104 35.7202C72.1504 35.8402 67.1704 36.4102 62.3504 37.4002L43.4004 95.7202L124.32 154.51L168.85 122.16V122.17Z" fill="url(#paint3_radial_999_6136)"/>
    <path d="M59.4004 90.53L65.8204 89.09L88.1704 20.31H160.48L182.83 89.09L189.24 90.53L164.45 14.21H84.2004L59.4004 90.53Z" fill="#D8A342"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.2">
        <path d="M65.8186 89.0903L78.3186 57.0103C85.7186 38.0103 104.019 29.7703 124.409 29.7703C144.889 29.7703 163.249 38.1203 170.589 57.2403L182.829 89.1003L160.479 20.3203H88.1586L65.8086 89.1003L65.8186 89.0903Z" fill="#DA7D2B"/>
    </g>
    <path d="M59.4004 90.5301L65.8204 89.0901L124.32 131.59L182.83 89.0901L189.24 90.5301L124.32 137.69L59.4004 90.5301Z" fill="url(#paint4_linear_999_6136)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M65.8188 89.0903L88.7589 30.2203C89.8689 27.3803 92.5988 25.5103 95.6488 25.5103H153.309C156.369 25.5103 159.119 27.4003 160.219 30.2603L182.819 89.1003L160.469 20.3203H88.1488L65.7988 89.1003L65.8188 89.0903Z" fill="#DA7D2B"/>
    </g>
    <path d="M84.1992 14.21L88.1692 20.31H160.479L164.449 14.21H84.1992Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M54.4901 61.6101L46.7401 90.6301C46.1301 93.1301 47.3302 96.4501 49.3902 97.9901L124.33 154.52L43.4102 95.7301L54.4901 61.6101Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M65.8203 89.0901L121.63 126.68C123.11 127.68 125.53 127.68 127.01 126.68L182.82 89.0901L124.32 131.6L65.8203 89.0901Z" fill="#DA7D2B"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M124.32 154.52L196.38 98.8603C201.42 95.1203 202.51 94.3103 200.24 87.1603L185.2 34.0503L205.24 95.7303L124.32 154.52Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M88.5586 111.71L124.319 137.69L172.609 102.61L124.319 139.9L88.5586 111.71Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M121.609 0.600098H174.339L185.209 34.0501L172.319 2.4501L121.609 0.600098Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M142.59 0.600098C142.15 4.9401 144.04 9.79008 147.69 14.2101H164.44L168.58 26.9601C173.83 28.2501 178.85 28.2901 182.96 27.1601L174.33 0.600098H142.59Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M84.2194 108.55C82.9494 111.81 85.2494 116.67 89.8494 120.01C94.4594 123.36 99.7794 124.04 102.489 121.82L84.2194 108.54V108.55Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M102.609 130.72C101.839 131.9 99.6992 131.88 97.8392 130.67C95.9792 129.46 95.0892 127.51 95.8592 126.33C96.6292 125.15 98.7692 125.17 100.629 126.38C102.489 127.59 103.379 129.54 102.609 130.72Z" fill="white"/>
    </g>
    <path d="M66.4294 1.26013L63.3694 26.5801C56.1194 26.5401 50.0694 26.5101 39.1794 26.5201C0.639442 26.5701 0.439453 1.14014 0.439453 1.14014L66.4294 1.26013Z" fill="#D4992C"/>
    <path d="M66.4294 1.26013L59.9195 21.0401C52.6695 21.0001 51.4795 20.9701 40.5995 20.9801C2.05946 21.0301 0.439453 1.14014 0.439453 1.14014L66.4294 1.26013Z" fill="url(#paint5_radial_999_6136)"/>
    <path d="M56.6591 31.33L53.6391 56.66C46.3891 56.62 55.2091 56.66 44.3191 56.66C5.77906 56.71 5.53906 31.21 5.53906 31.21L56.6591 31.33Z" fill="#D4992C"/>
    <path d="M56.6591 31.3302L50.2191 51.1502C42.9691 51.1102 56.659 51.0802 45.769 51.0902C7.22904 51.1402 5.53906 31.2002 5.53906 31.2002L56.6591 31.3202V31.3302Z" fill="url(#paint6_radial_999_6136)"/>
    <path d="M46.9 61.39L19.75 61.28C19.75 61.28 24.01 80.34 43.87 86.72L46.9 61.39Z" fill="#D4992C"/>
    <path d="M46.9 61.39L19.75 61.28C19.75 61.28 20.59 74.84 40.45 81.22L46.9 61.39Z" fill="url(#paint7_radial_999_6136)"/>
    <path d="M66.4299 1.26025L69.6799 7.54025L63.3699 26.5802L59.9199 21.0403L66.4299 1.26025Z" fill="#D8A342"/>
    <path d="M56.6588 31.3301L59.9188 37.5501L53.6487 56.6501L50.2188 51.1501L56.6588 31.3301Z" fill="#D8A342"/>
    <path d="M46.8892 61.3901L50.1392 67.6101L43.8692 86.7201L40.4492 81.2101L46.8892 61.3901Z" fill="#D8A342"/>
    <path d="M182.24 1.26013L185.3 26.5801C192.55 26.5401 198.6 26.5101 209.49 26.5201C248.03 26.5701 248.23 1.14014 248.23 1.14014L182.24 1.26013Z" fill="#D4992C"/>
    <path d="M182.24 1.26013L188.75 21.0401C196 21.0001 197.19 20.9701 208.07 20.9801C246.61 21.0301 248.23 1.14014 248.23 1.14014L182.24 1.26013Z" fill="url(#paint8_radial_999_6136)"/>
    <path d="M192 31.33L195.02 56.66C202.27 56.62 193.45 56.66 204.34 56.66C242.88 56.71 243.12 31.21 243.12 31.21L192 31.33Z" fill="#D4992C"/>
    <path d="M192 31.3302L198.44 51.1502C205.69 51.1102 192 51.0802 202.89 51.0902C241.43 51.1402 243.12 31.2002 243.12 31.2002L192 31.3202V31.3302Z" fill="url(#paint9_radial_999_6136)"/>
    <path d="M201.77 61.39L228.919 61.28C228.919 61.28 224.66 80.34 204.799 86.72L201.77 61.39Z" fill="#D4992C"/>
    <path d="M201.77 61.39L228.919 61.28C228.919 61.28 228.079 74.84 208.219 81.22L201.77 61.39Z" fill="url(#paint10_radial_999_6136)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M182.24 1.26013L188.75 21.0401C196 21.0001 197.19 20.9701 208.07 20.9801C246.61 21.0301 248.23 1.14014 248.23 1.14014L182.24 1.26013Z" fill="url(#paint11_radial_999_6136)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M192 31.3302L198.44 51.1502C205.69 51.1102 192 51.0802 202.89 51.0902C241.43 51.1402 243.12 31.2002 243.12 31.2002L192 31.3202V31.3302Z" fill="url(#paint12_radial_999_6136)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M201.77 61.39L228.919 61.28C228.919 61.28 228.079 74.84 208.219 81.22L201.77 61.39Z" fill="url(#paint13_radial_999_6136)"/>
    <path d="M182.239 1.26025L178.979 7.54025L185.299 26.5802L188.749 21.0403L182.239 1.26025Z" fill="#D8A342"/>
    <path d="M192 31.3301L188.75 37.5501L195.02 56.6501L198.44 51.1501L192 31.3301Z" fill="#D8A342"/>
    <path d="M201.78 61.3901L198.52 67.6101L204.789 86.7201L208.219 81.2101L201.78 61.3901Z" fill="#D8A342"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M66.4294 1.26013L0.439453 1.14014C0.439453 1.14014 2.05946 21.0301 40.5995 20.9801C51.4795 20.9701 52.6695 21.0001 59.9195 21.0401L66.4294 1.26013Z" fill="url(#paint14_radial_999_6136)"/>
    <path style={{mixBlendMode: 'screen'}} d="M46.9 61.39L19.75 61.28C19.75 61.28 20.59 74.84 40.45 81.22L46.9 61.39Z" fill="url(#paint15_radial_999_6136)"/>
    <path style={{mixBlendMode: 'screen'}} d="M50.22 51.15L54.02 39.46C48.85 41.15 43.95 43.37 39.44 46.13C37.58 47.27 35.38 48.68 33 50.25C36.73 50.79 40.97 51.09 45.77 51.09C56.66 51.08 42.97 51.11 50.22 51.15Z" fill="url(#paint16_radial_999_6136)"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M56.3086 56.0101L73.2086 7.55008C74.9586 2.63008 76.2286 1.34009 81.4386 1.15009L124.319 0.600098H74.3086L56.3086 56.0101Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M139.44 8.16999C135.94 5.62999 131.73 5.46997 130.03 7.80997C128.81 9.48997 129.22 11.99 130.83 14.22H143.37C143.17 12.17 141.76 9.85999 139.44 8.16999Z" fill="white"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M197.169 19.54C192.779 19.1 189.709 17.51 187.709 13.58L182.229 1.27002L188.739 21.05C195.989 21.01 197.179 20.98 208.059 20.99C214.199 20.99 219.399 20.5 223.809 19.65C214.259 20.8 204.449 20.26 197.169 19.54Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M202.89 51.0999C212.21 51.1099 219.37 49.9499 224.88 48.1898C216.96 50.3899 210.31 50.2398 205.25 49.9298C201.77 49.7198 198.94 47.1598 197.46 43.9998L192.01 31.3398L198.45 51.1599C205.7 51.1199 192.01 51.0899 202.9 51.0999H202.89Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M208.219 81.2101C212.519 79.8301 215.929 78.1101 218.629 76.2601C215.429 77.4801 209.429 78.3501 209.429 78.3501L201.779 61.3801V61.4001L208.219 81.2001V81.2101Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.5992 20.9899C51.4792 20.9799 52.6692 21.0099 59.9192 21.0499L65.0792 5.37991L61.2492 13.9299C59.4092 18.4399 58.3192 19.9199 53.8192 19.9599C48.9692 19.9999 39.8292 19.8999 35.1792 19.7199C14.9492 18.9299 3.02922 10.1799 0.449219 1.1499C0.449219 1.1499 2.06919 21.0399 40.6092 20.9899H40.5992Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M51.0789 45.3102C49.8589 47.8802 48.5389 50.0302 45.6989 49.9302C42.7589 49.8302 34.5089 49.7703 29.7889 48.7003C18.8489 46.2303 12.3089 42.1602 7.87891 37.1602C11.5989 43.0302 21.1889 51.1303 45.7689 51.1003C56.6589 51.0903 42.9689 51.1202 50.2189 51.1602L55.5489 34.7603L51.0789 45.3203V45.3102Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M40.45 81.21L46.07 63.92C45.01 66.12 42.92 71.22 40.8 75.95C39.35 79.16 37.97 78.76 35.74 77.83C24.65 73.22 22.1 67.72 19.75 61.27C19.75 61.27 20.59 74.83 40.45 81.21Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M182.24 1.26013L242.59 2.29013C245.02 2.33013 246.14 4.15012 245.09 6.34012C244 8.62012 242.23 11.3501 239.34 13.7001C247.67 8.04013 248.23 1.14014 248.23 1.14014L182.24 1.26013Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M192 31.3302L236.52 32.3802C241.72 32.4902 240.88 34.7002 239.52 37.4002C238.68 39.0702 237.54 40.8902 236.03 42.4102C242.61 37.0602 243.11 31.2002 243.11 31.2002L191.99 31.3202L192 31.3302Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M224.249 71.24C228.619 66.03 228.919 61.27 228.919 61.27L201.809 61.38H201.779L224.189 62.33C227.499 62.53 226.899 63.78 226.519 65.38C226.069 67.23 225.339 69.41 224.259 71.23L224.249 71.24Z" fill="#F5E7CC"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.9785 1.14014L66.4285 1.26013L64.7985 6.21011C64.7985 3.67011 63.5985 2.39011 60.6685 2.36011L17.9785 1.14014Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M17.9785 31.3301H56.6585L54.8785 36.8101L55.0685 34.9901C55.1685 33.2701 54.5585 32.3701 51.4185 32.2201L17.9785 31.3301Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M24.9688 61.29L46.8987 61.38L45.2288 66.49L45.1288 64.12C45.0688 62.79 44.7087 62.34 42.8987 62.22L24.9688 61.29Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M112.949 76.2899L119.069 65.6999V60.6299L106.839 65.6999L100.719 55.1099L94.6089 65.6999L82.3789 60.6299V65.6999L88.4989 76.2899L82.3789 81.8099V86.8799H94.6089L100.719 97.4699L106.839 86.8799H119.069V81.8099L112.949 76.2899Z" fill="#DA7D2B"/>
    </g>
    <path d="M112.949 71.2203L119.069 60.6303V56.6703L106.839 60.6303L100.719 50.0503L94.6089 60.6303L82.3789 56.6703V60.6303L88.4989 71.2203L82.3789 77.8503V81.8103H94.6089L100.719 92.4003L106.839 81.8103H119.069V77.8503L112.949 71.2203Z" fill="#D8A342"/>
    <path d="M112.949 67.2601L119.069 56.6701H106.839L100.719 46.0801L94.6089 56.6701H82.3789L88.4989 67.2601L82.3789 77.8501H94.6089L100.719 88.4401L106.839 77.8501H119.069L112.949 67.2601Z" fill="url(#paint17_radial_999_6136)"/>
    <path style={{mixBlendMode: 'screen'}} d="M112.949 67.2601L119.069 56.6701H106.839L100.719 46.0801L94.6089 56.6701H82.3789L88.4989 67.2601L82.3789 77.8501H94.6089L100.719 88.4401L106.839 77.8501H119.069L112.949 67.2601Z" fill="url(#paint18_radial_999_6136)"/>
    <path d="M119.07 77.8501H106.84V81.8101H119.07V77.8501Z" fill="#D4992C"/>
    <path d="M94.6089 81.8101L100.719 92.4001V88.4401L94.6089 77.8501H82.3789V81.8101H94.6089Z" fill="#D4992C"/>
    <path d="M82.3789 56.6699L88.4989 67.2599L87.3489 69.2399L82.3789 60.6299V56.6699Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M82.3789 56.6699L90.7389 67.2599L82.3789 77.8499L88.4989 67.2599L82.3789 56.6699Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.8">
        <path d="M100.719 46.0801L106.619 57.7201L119.069 56.6701H106.839L100.719 46.0801Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M82.3789 77.8502L95.7589 76.7002L100.719 88.4402L94.6089 77.8502H82.3789Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M100.719 88.4402L106.049 75.4001L119.069 77.8502H106.839L100.719 88.4402Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M112.74 66.83C111.24 69.43 106.8 69.68 102.82 67.38C98.8401 65.08 96.84 61.11 98.34 58.51C99.84 55.91 104.28 55.66 108.26 57.96C112.24 60.26 114.24 64.23 112.74 66.83Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M98.3695 68.4202C97.8895 69.2502 96.3095 69.2402 94.8395 68.3902C93.3695 67.5402 92.5695 66.1802 93.0495 65.3402C93.5295 64.5102 95.1095 64.5202 96.5795 65.3702C98.0495 66.2202 98.8495 67.5802 98.3695 68.4202Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M159.689 76.2899L165.809 65.6999V60.6299L153.579 65.6999L147.459 55.1099L141.349 65.6999L129.119 60.6299V65.6999L135.239 76.2899L129.119 81.8099V86.8799H141.349L147.459 97.4699L153.579 86.8799H165.809V81.8099L159.689 76.2899Z" fill="#DA7D2B"/>
    </g>
    <path d="M159.689 71.2203L165.809 60.6303V56.6703L153.579 60.6303L147.459 50.0503L141.349 60.6303L129.119 56.6703V60.6303L135.239 71.2203L129.119 77.8503V81.8103H141.349L147.459 92.4003L153.579 81.8103H165.809V77.8503L159.689 71.2203Z" fill="#D8A342"/>
    <path d="M159.689 67.2601L165.809 56.6701H153.579L147.459 46.0801L141.349 56.6701H129.119L135.239 67.2601L129.119 77.8501H141.349L147.459 88.4401L153.579 77.8501H165.809L159.689 67.2601Z" fill="url(#paint19_radial_999_6136)"/>
    <path style={{mixBlendMode: 'screen'}} d="M137.469 56.6699H129.119L135.229 67.2599L129.119 77.8499H141.349L147.459 88.4399L153.569 77.8499H155.349C150.349 69.5399 144.269 62.4899 137.459 56.6699H137.469Z" fill="url(#paint20_radial_999_6136)"/>
    <path style={{mixBlendMode: 'soft-light'}} d="M153.58 56.6701L147.47 46.0801L143.51 52.9401C149.65 57.8201 155.44 63.2101 160.74 69.0701L159.7 67.2601L165.81 56.6701H153.58Z" fill="url(#paint21_radial_999_6136)"/>
    <path d="M165.81 77.8501H153.58V81.8101H165.81V77.8501Z" fill="#D4992C"/>
    <path d="M141.349 81.8101L147.459 92.4001V88.4401L141.349 77.8501H129.119V81.8101H141.349Z" fill="#D4992C"/>
    <path d="M129.119 56.6699L135.239 67.2599L134.089 69.2399L129.119 60.6299V56.6699Z" fill="#D4992C"/>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M129.119 56.6699L137.479 67.2599L129.119 77.8499L135.239 67.2599L129.119 56.6699Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.8">
        <path d="M147.459 46.0801L153.359 57.7201L165.809 56.6701H153.579L147.459 46.0801Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M129.119 77.8502L142.499 76.7002L147.459 88.4402L141.349 77.8502H129.119Z" fill="#FEFAF7"/>
    </g>
    <g style={{mixBlendMode: 'multiply'}} opacity="0.6">
        <path d="M147.459 88.4402L152.789 75.4001L165.809 77.8502H153.579L147.459 88.4402Z" fill="#DDAF59"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.5">
        <path d="M148.259 76.8801C147.239 78.6501 144.229 78.8101 141.529 77.2601C138.829 75.7101 137.469 73.0101 138.489 71.2401C139.509 69.4701 142.519 69.3101 145.219 70.8601C147.919 72.4201 149.279 75.1101 148.259 76.8801Z" fill="#FC6500"/>
    </g>
    <g style={{mixBlendMode: 'screen'}} opacity="0.85">
        <path d="M188.75 21.0402C193.9 21.0102 196 20.9902 200.69 20.9802C207.52 16.7002 212.07 10.3402 212.71 2.93018C212.76 2.35018 212.78 1.7802 212.78 1.2002L182.24 1.26019L188.75 21.0402Z" fill="white"/>
    </g>
    <path style={{mixBlendMode: 'soft-light'}} d="M5.54883 31.21C5.54883 31.21 5.82884 34.44 8.65884 38.29C12.8688 35.91 17.5588 33.48 22.5588 31.25L5.54883 31.21Z" fill="url(#paint22_radial_999_6136)"/>
    <path d="M150.359 119.75C150.359 119.75 153.879 113.19 159.349 109.42C162.619 107.16 168.929 105.39 168.929 105.39C168.929 105.39 163.049 112.78 158.709 116.24C154.369 119.7 150.369 119.74 150.369 119.74L150.359 119.75Z" fill="url(#paint23_linear_999_6136)"/>
    <path d="M165.13 107.85C160.3 108.13 156.43 111.99 154.33 114.71C152.23 117.42 153.42 118.33 156.08 117.15C158.73 115.97 167.17 107.74 165.13 107.86V107.85Z" fill="#D9A648"/>
    <path style={{mixBlendMode: 'multiply'}} opacity="0.5" d="M165.129 107.85C161.859 107.82 157.749 110.91 156.469 112.29C155.179 113.67 157.749 115.79 159.499 114.67C161.249 113.55 166.619 107.87 165.129 107.85Z" fill="url(#paint24_linear_999_6136)"/>
    <defs>
        <radialGradient id="paint0_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.7899) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint1_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.7899) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint2_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.62 -7.28991) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint3_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.5604 132.73) scale(113.66 113.66)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <linearGradient id="paint4_linear_999_6136" x1="124.32" y1="77.8401" x2="124.32" y2="104.66" gradientUnits="userSpaceOnUse">
            <stop offset="0.63" stop-color="#E6C485"/>
            <stop offset="1" stop-color="#F3E2C4"/>
        </linearGradient>
        <radialGradient id="paint5_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.239 -1.78986) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint6_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.239 -1.78979) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint7_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.78997) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint8_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.78986) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint9_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.78979) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint10_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.24 -1.78997) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint11_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.62 -7.28987) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint12_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.62 -7.28977) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint13_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.62 -7.28998) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint14_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.619 -7.28987) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint15_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.56 132.73) scale(113.66 113.66)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint16_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.56 132.73) scale(113.66 113.66)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint17_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.239 -1.78995) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint18_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.5589 132.73) scale(113.66 113.66)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint19_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.239 -1.78995) scale(204.51 204.51)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.51" stop-color="#DEB15D"/>
            <stop offset="0.92" stop-color="#ECD3A4"/>
        </radialGradient>
        <radialGradient id="paint20_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(69.5591 132.73) scale(113.66 113.66)">
            <stop offset="0.32" stop-color="#E5C281"/>
            <stop offset="0.76" stop-color="#D59C33"/>
        </radialGradient>
        <radialGradient id="paint21_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(183.62 -7.28998) scale(173.79 173.79)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <radialGradient id="paint22_radial_999_6136" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.949 -1.29011) scale(198.97 198.97)">
            <stop stop-color="#EAB587"/>
            <stop offset="0.3" stop-color="#E8B481"/>
            <stop offset="0.67" stop-color="#E3B372"/>
            <stop offset="1" stop-color="#DEB25F"/>
        </radialGradient>
        <linearGradient id="paint23_linear_999_6136" x1="152.909" y1="116.44" x2="165.709" y2="109.08" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8EEDB"/>
            <stop offset="0.99" stop-color="#E4C07C"/>
        </linearGradient>
        <linearGradient id="paint24_linear_999_6136" x1="161.397" y1="114.387" x2="160.436" y2="108.363" gradientUnits="userSpaceOnUse">
            <stop stop-color="white"/>
            <stop offset="0.44" stop-color="#DEB361"/>
            <stop offset="1" stop-color="#D7A03B"/>
        </linearGradient>
    </defs>
</svg>
)