import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.user.user,
    props.wallet.tab,
    (user, tab) => {
        return {
            user,
            tab
        }
    }
)